import { User } from 'app/entities';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectUser } from 'app/state/user/user.select';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from 'app/main/pages/unsubscribe.component';

@Component({
    selector: 'app-referral-dialog',
    templateUrl: './referral-dialog.component.html',
    styleUrls: ['./referral-dialog.component.scss']
})
export class ReferralDialogComponent extends UnsubscribeComponent implements OnInit {
    user?: User | null
    title = 'Copie o link abaixo e compartilhe com seus amigos'
    copied: boolean = false
    imgSrc = 'assets/images/share_referral.png'
    referralLink: any = ''
    constructor(
        private store: Store,
        public dialogRef: MatDialogRef<ReferralDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
        super()
    }

    ngOnInit(): void {
        this.store.select(selectUser)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this.user = user
                this.referralLink = this.user?.referralLink
            })
    }


   
    close() {
        this.dialogRef.close();
    }

    copyToClipboard() {
        if (this.user?.referralLink) {
            navigator.clipboard.writeText(this.user.referralLink)
                .then(() => {
                    this.copied = true
                    setTimeout(() => {
                        this.copied = false
                    }, 3000);
                })
        }    
    }
}
