<div fxLayout="row" fxLayoutAlign="center center" fxHide.xs>
  <div class="card">
    <div class="card__front card__part">
      <!--
              <img class="card__front-logo card__logo" src="/assets/svg/flag-credit-card/visa.svg">
        -->
      <img class="card__front-square card__square" src="assets/svg/f-orca.svg">
      <p class="card_numer">{{cardNumber | mask: 'credit_card'}}</p>
      <div class="card__space-75">
        <span class="card__label">Titular do cartão</span>
        <p class="card__info">{{cardHolder}}</p>
      </div>
      <div class="card__space-25">
        <span class="card__label">Expira</span>
        <p class="card__info">{{expiresMonth}}/{{expiresYear}}</p>
      </div>
    </div>

    <div class="card__back card__part">
      <div class="card__black-line"></div>
      <div class="card__back-content">
        <div class="card__secret">
          <p class="card__secret--last">{{cardCCV}}</p>
        </div>
        <img class="card__back-square card__square" src="/assets/svg/f-orca.svg">
        <!--
              <img class="card__back-logo card__logo" src="/assets/svg/flag-credit-card/visa.svg">
          -->

      </div>
    </div>

  </div>

</div>
<div class="container-form-credit-card">
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="25px">
      <div fxLayout="column wrap" fxFlex="auto">
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>Número do cartão</mat-label>
          <input name="card_number" formControlName="card_number" matInput required mask="0000 0000 0000 0000">
        </mat-form-field>

        <div fxLayout="row wrap" fxFlex="auto" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="auto">
            <mat-label>Mês</mat-label>
            <input name="card_expires_month" formControlName="card_expires_month" mask="00" matInput required
              maxlength="2" minlength="2">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="auto">
            <mat-label>Ano</mat-label>
            <input name="card_expires_year" formControlName="card_expires_year" matInput required mask="00"
              maxlength="2" minlength="2">
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="auto">
            <mat-label>CCV</mat-label>
            <input name="card_ccv" formControlName="card_ccv" matInput required maxlength="4" minlength="3">
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>Nome completo</mat-label>
          <input name="card_holder" formControlName="card_holder" matInput required>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>CPF/CNPJ</mat-label>
          <input name="card_holder_cpf_cnpj" formControlName="card_holder_cpf_cnpj" matInput required
            appMask="cpf_cnpj">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div fxLayout="row" fxLayoutAlign="end center">
    <button class="btn btn-create" (click)="save()" [disabled]="!form.valid && disable">Adicionar cartão </button>
  </div>
</div>