import { createReducer, on } from "@ngrx/store";
import { Account } from "app/entities/account";
import { loadAccount, loadAccountFailure, loadAccountSuccess, loadNotificationsSuccess, loadProposalFirstStepsSuccess, loadSettingsFirstStepsSuccess, loadTagsSuccess } from "./account.actions";
import { logoutUserSuccess } from "../user/user.actions";
import { Notification } from "app/entities";
import { AccountTag } from "app/entities/account-tag";

export interface AccountState {
    account: Account | null,
    settingsFirstSteps: any,
    proposalFirstSteps:any,
    notifications: Notification[],
    tags: AccountTag[],
    error: string | null;
    status: string | null;
}

export const initialState: AccountState = {
    account: null,
    settingsFirstSteps: null,
    proposalFirstSteps: null,
    notifications: [],
    tags: [],
    error: null,
    status: 'pending',
};

export const accountReducer = createReducer(
    initialState, 
    on(loadAccount, (state) => ({
        ...state,
        status: 'loading'
    })),
    on(logoutUserSuccess, (state) => ({
        ...state,
        account: null,
        error: null,
        status: 'pending',
    })),
    on(loadAccountSuccess, (state, {account}) => ({
        ...state,
        status: 'success',
        account
    })),
    on(loadNotificationsSuccess, (state, {notifications}) => ({
        ...state,
        notifications
    })),
    on(loadTagsSuccess, (state, {tags}) => ({
        ...state,
        tags
    })),
    on(loadSettingsFirstStepsSuccess, (state, {steps}) => ({
        ...state,
        settingsFirstSteps:steps
    })),
    on(loadProposalFirstStepsSuccess, (state, {steps}) => ({
        ...state,
        proposalFirstSteps:steps
    })),
    on(loadAccountFailure, (state, {error}) => ({
        ...state,
        status: 'error',
        error
    })),
)
