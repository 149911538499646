import { PlanPayment } from "./plan-payment";
import { Decodable } from "./_decodable";

class PlanPaymentInstallment extends Decodable {
    
    id: number;
    planPaymentId: number
    installment: number;
    paymentMethod: number
    interest?: number
    discount?: number
    planPayment?: PlanPayment;
    createdAt: Date;
    updatedAt: Date;

    valuePerMonth:number
    total:number
    
    protected _map = {
        planPayment: e => PlanPayment.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export { PlanPaymentInstallment };
