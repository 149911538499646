<div [formGroup]="form" *ngIf="form" (keydown.tab)="$event.stopPropagation()">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">

        <label class="label-input" for="input-name">Nome/Razão Social <span class="required">*</span></label>

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input type="text" #nomeInput placeholder="Nome/Razão Social" matInput formControlName="name" required>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">
        <label class="label-input" for="cpf_cnpj">CPF/CNPJ</label>

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>CPF/CNPJ</mat-label>
            <input type="text" inputmode="numeric" (blur)="changeAddressByCep()" matInput formControlName="cpf_cnpj" mask="000.000.000-00||00.000.000/0000-00">
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">
        <label class="label-input" for="input-phone">Contato</label>
        

        <mat-form-field appearance="outline" fxFlex>
            <mat-icon matSuffix class="secondary-text">
                account_box
            </mat-icon>
            <mat-label>Nome do Responsável</mat-label>
            <input type="text" matInput formControlName="responsible">
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">

        <label class="label-input" for="input-phone">
            <!-- Contato -->
        </label>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>E-mail</mat-label>
                <input type="email" autocapitalize="off" placeholder="E-mail" matInput formControlName="email">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Telefone</mat-label>
                <input type="tel" placeholder="Telefone" matInput formControlName="phone" autocomplete="off"
                    name="phone" mask="(00) 00009-0000">
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">

        <label class="label-input" for="input-phone">Endereço</label>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>CEP</mat-label>
                <input type="text" inputmode="numeric" placeholder="CEP" matInput formControlName="cep" mask="00000-000"
                    (blur)="changeAddressByCep()">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Logradouro</mat-label>
                <input type="text" placeholder="Logradouro" matInput formControlName="address">
            </mat-form-field>
        </div>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">
        <label class="label-input" for="input-phone"></label>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex>
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Número</mat-label>
                <input type="text" placeholder="Número" matInput formControlName="address_number">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Complemento</mat-label>
                <input type="text" placeholder="Complemento" matInput formControlName="address_complement">
            </mat-form-field>
        </div>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">
        <label class="label-input" for="input-phone"></label>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxFlex>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Bairro</mat-label>
                <input type="text" placeholder="Bairro" matInput formControlName="address_neighborhood">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Cidade</mat-label>
                <input type="text" placeholder="Cidade" matInput formControlName="address_city">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Estado</mat-label>
                <input type="text" placeholder="Estado" matInput formControlName="address_state">
            </mat-form-field>
        </div>

    </div>

    <div class="form-menu-footer" fxLayout="row" fxLayoutAlign="end">

        <button *ngIf="!client" class="btn btn-gray" (click)="close()">Descartar</button>
        <button *ngIf="!client" class="btn btn-blue" [disabled]="loading || !this.form.valid"
            (click)="store()">Criar Cliente</button>
        <button *ngIf="client" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            fxLayout="row" fxLayoutAlign="start center" (click)="update()">
            <mat-spinner *ngIf="loading" class="spinner" diameter="20" color="accent"></mat-spinner>
            Salvar Alterações
        </button>


    </div>
</div>