
import { createReducer, on } from "@ngrx/store";
import { Payment } from "app/entities";
import { Error, Status } from "../AppState";

import { loadPayments, loadPaymentsFailure, loadPaymentsSuccess } from "./payment.actions";
import { logoutUserSuccess } from "../user/user.actions";

export interface PaymentState {
    payments: Payment[] | null,
    error: Error | null;
    status: Status | null;
}


export const initialState: PaymentState = {
    payments: null,
    error: null,
    status: {
        statusName: 'pending',
        isError: false,
        isFinished: false
    },
};

export const paymentReducer = createReducer(
    initialState, 
    on(loadPayments, (state) => ({
        ...state,
        status: {
            statusName: 'loading',
            isError: false,
            isFinished: false
        },
    })),
    on(logoutUserSuccess, (state) => ({
        ...state,
        payments: null,
        error: null,
        status: {
            statusName: 'pending',
            isError: false,
            isFinished: false
        },
    })),
    on(loadPaymentsSuccess, (state, {payments}) => ({
        ...state,
        status: {
            statusName: 'success',
            isError: false,
            isFinished: true
        },
        payments: Object.keys(payments ?? {})?.length > 0 ? payments : null, 
        
    })),
    on(loadPaymentsFailure, (state, {error}) => ({
        ...state,
        status: {
            statusName: 'error',
            isError: true,
            isFinished: true
        },
        error
    })),
)