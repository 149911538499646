import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { AccountTag } from 'app/entities/account-tag';
import { AlertService } from 'app/services/alert.service';
import { TagsService } from 'app/services/http/tags.service';
import { loadTags } from 'app/state/account/account.actions';
import { AccountState } from 'app/state/account/account.reducer';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-form-tag-create-or-edit',
    templateUrl: './form-tag-create-or-edit.component.html',
    styleUrls: ['./form-tag-create-or-edit.component.scss']
})
export class FormTagCreateOrEditComponent implements OnInit {

    @Input('manuTrigger') manuTrigger?: MatMenuTrigger
    @Input('tag') tag?: AccountTag;
    @ViewChild('nomeInput') nomeInput?: any;
    @Output() onCreatedTag = new Subject<AccountTag>();


    loading = false;
    form?: FormGroup

    constructor(
        private _formBuilder: FormBuilder,
        private _alertService: AlertService,
        private _tagService: TagsService,
        private _storeAccount: Store<AccountState>
    ) { }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this._formBuilder.group({
            name: [this.tag?.name, [Validators.required]],
            hexadecimal_color: [this.tag?.hexadecimal_color ?? '#559DFF', []],
        });

        setTimeout(() => {
            this.nomeInput.nativeElement.focus();
        }, 100);
    }

    store() {
        if (this.form?.valid) {
            this.loading = true
            this._tagService.store(this.form?.value).subscribe((res) => {
                this._storeAccount.dispatch(loadTags())
                this._alertService.success('Tag criado');
                this.onCreatedTag.next(res)
                this.loading = false
                
                this.close()
            }, (error) => {
                this._alertService.error(error)
                this.loading = false
            })
        }
    }

    update() {
        if (this.form?.valid && this.tag) {
            this.loading = true
            this._tagService.update(this.tag!.id!, this.form?.value).subscribe((res) => {
                this._alertService.success('Tag atualizada');
                this.form?.markAsPristine()
                this.loading = false
                this._storeAccount.dispatch(loadTags())
            }, (error) => {
                this.loading = false
                this._alertService.error(error)
            })
        }
    }

    close() {
        this.form?.reset()
        this.manuTrigger?.closeMenu()
    }

    onColorChanged(color) {
        this.form?.controls.hexadecimal_color.setValue(color);
    }

}
