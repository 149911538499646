import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProductCategory } from 'app/entities';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductCategoriesService } from 'app/main/pages/products/product-category.service';
import { AlertService } from 'app/services/alert.service';

@Component({
  selector: 'app-form-product-category-create-or-edit',
  templateUrl: './form-product-category-create-or-edit.component.html',
  styleUrls: ['./form-product-category-create-or-edit.component.scss']
})
export class FormProductCategoryCreateOrEditComponent implements OnInit {

  @Input('manuTrigger') manuTrigger?: MatMenuTrigger
  @Input('productCategory') productCategory: ProductCategory = new ProductCategory()
  @ViewChild('nomeInput') nomeInput?: any;
  @Output() onCreatedProduct = new Subject<ProductCategory>();
  @Output() onUpdatedProduct = new Subject<ProductCategory>();

  public bankFilterCtrl: FormControl = new FormControl();
  loading = false;
  form?: FormGroup

  constructor(
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _productCategoriesService: ProductCategoriesService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    if (!this.productCategory) {
      this.productCategory = new ProductCategory()
      this.productCategory.status = 1
    }
    console.log('form-client-create-or-edit create form');

    this.form = this._formBuilder.group({
      id: [this.productCategory?.id, []],
      title: [this.productCategory?.title, [Validators.required]],
      status: [this.productCategory?.status ?? 1, []],
    });

    setTimeout(() => {
      this.nomeInput.nativeElement.focus();
    }, 100);
  }

  store() {
    if (this.form?.valid) {
      this.loading = true
      this._productCategoriesService.store(this.form?.value).subscribe((res) => {
        this._alertService.success('Categoria de Produto criada');
        this.onCreatedProduct.next(res)
        this.loading = false
        this.form?.reset()
      }, (error) => {
        this._alertService.error(error)
        this.loading = false
      })
    }
  }

  update() {
    if (this.form?.valid && this.productCategory !== undefined) {
      let productCategoryId: number = this.productCategory?.id as number
      this.loading = true
      this._productCategoriesService.update(productCategoryId, this.form?.value).subscribe((res) => {
        this._alertService.success('Categoria de Produto atualizada');
        this.onUpdatedProduct.next(res)
        this.loading = false
      }, (error) => {
        this.loading = false
        this._alertService.error(error)
      })
    }
  }

  close() {
    this.form?.reset()
    this.manuTrigger?.closeMenu()
  }

  toggleStatus(active: boolean) {
    this.productCategory.status = active ? 1 : 2
    this.form?.controls.status.setValue(this.productCategory.status)
    this.form?.markAsDirty()
  }
}
