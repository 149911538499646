import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-logs',
  templateUrl: './list-logs.component.html',
  styleUrls: ['./list-logs.component.scss']
})
export class ListLogsComponent implements OnInit {

  showMore: boolean = false;
  @Input("logs") logs$;

  constructor() { }

  ngOnInit(): void {
  }

}
