
<div fxFlex fxFill fxLayout="column">
  
    <div class="spinner-container" *ngIf="dataSource?.isLoading | async">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  
    <div class="mat-white-bg">
        <table mat-table #table [dataSource]="dataSource"
        [@animateStagger]="{value:'50'}">
            <ng-content></ng-content>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" matRipple (click)="onSelectedRowInternal(row)" ></tr>
          </table>

      <div *ngIf="dataSource?.isEmpty | async" class="no-records">
          {{emptyLabel}}
      </div>
    </div>
  
  
    <mat-paginator [length]="dataSource?.totalItems | async" [pageSizeOptions]="pageSizeOptions" (page)="handlePageEvent($event)" [pageSize]="pageSize"></mat-paginator>


  </div>