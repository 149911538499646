export const environment = {
    local       : false,
    production  : false,
    hmr         : false,
    staging     : true,

    appName: `ORCA APP [[ DEV ]]`,

    apiHost:     'https://apidev.orcaapp.com.br',
    baseUrl:     'https://appdev.orcaapp.com.br',
    apiUrl:     'https://apidev.orcaapp.com.br/api',
    storageUrl:  'https://apidev.orcaapp.com.br/storage',
    baseUrlHotsite : 'https://dev.orcaapp.com.br',

    //websocket config
    websocketHost: "dev.orcaapp.com.br",
    broadcastingAuthUrl: "https://dev.orcaapp.com.br/api/broadcasting/auth",
    wsEncrypted: true,
    wsForceTLS: true,
    wsAppKey:'5003000535',
    wsCluster:'mt1',
    wsPort:6002
};