import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PlanSubscription } from 'app/entities/plan-subscription';
import { AuthService } from 'app/services/auth.service';

export interface SubscriptionRestrictionDialogData {
    dilogID: string;
  subscription: PlanSubscription;
  title: string;
  subtitle: string;
  description: string;
  canSolveLater: boolean;
  canRenew: boolean;
  canPurchase: boolean;
  showImage?: boolean;
  showHappyImage?: boolean;
  showExpiration?: boolean;
  showClose?: boolean;
  forceOpen?: boolean;
}
export interface SubscriptionRestrictionDialogResponse {
  solveLater: boolean;
}

@Component({
  selector: 'app-subscription-restriction-dialog',
  templateUrl: './subscription-restriction-dialog.component.html',
  styleUrls: ['./subscription-restriction-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionRestrictionDialogComponent implements OnInit {

  finalDate
  daysToFinish = 0
  whatsappLink = ''

  user = this.authService.currentUser;
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: SubscriptionRestrictionDialogData, 
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private _router: Router, public dialogRef: MatDialogRef<SubscriptionRestrictionDialogComponent,SubscriptionRestrictionDialogResponse>, 
    ) {
        this.dialogRef.addPanelClass('subscription-restriction-dialog');
        
        if(this.dialogData.showExpiration){
            this.finalDate = new Date(this.dialogData.subscription.endsAt!.getTime());
            this.finalDate.setDate(this.dialogData.subscription.endsAt!.getDate()+15);
            this.daysToFinish = this.getDifferenceInDays(Date.now(),  this.finalDate);
        }

        this.whatsappLink = `https://api.whatsapp.com/send?phone=556238775962&text=${encodeURI(this.dialogData.subscription.accountId + ' - ' + this.user?.email + ' Preciso de ajuda com a minha assinatura.')}`;

    }

  ngOnInit(): void {}

  getDifferenceInDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  getSanitizedHtml(string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(string);
  }

  renew() {
    this.dialogRef.close();
    this._router.navigate(['/planos-de-assinatura/checkout/retry']);
  }

  purchase()
    {
        this.dialogRef.close()
        this._router.navigate(['/checkout/planos']);
    }

    solveLater() {
        this.dialogRef.close({solveLater:true});
      }

  close() {
    this.dialogRef.close();
  }
}
