import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'app/entities';
import { Account } from 'app/entities/account';
import { loadNotifications } from 'app/state/account/account.actions';
import { selectAccount } from 'app/state/account/account.selectors';
import { loadSubscription } from 'app/state/subscription/subscription.actions';
import { selectUser } from 'app/state/user/user.select';
import { environment } from 'environments/environment';
import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js';
import { Subject } from 'rxjs';
import { filter, take, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { LocalStorageService } from './local-storage.service';
import * as ProductsImportActions from "../state/products-import/products-import.actions";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  echo:any

  user$ =  this.store.select(selectUser)

  user?:User
  account?:Account

  public paymentPixEventEmitter = new Subject<any>();

  constructor(
    private store:Store, 
    private alertService:AlertService,
    private localStorageService:LocalStorageService
  ) {

    console.log("Websocket service init");

    this.user$.pipe(
      filter((user)=>user!=null && user.account!=null),
      take(1)
      ).subscribe((user)=>{
        this.user = user!
        this.account = user!.account!
        this.initializeEcho()
        this.subscribeToChannels()
      }
    )
  }

  private subscribeToChannels(){

    // A api dispara este evento sempre que houver uma operação na subscription através do subscription service
    this.echo.private('account.'+this.account!.id).listen('SubscriptionUpdatedEvent', (e:any) => {
        // console.log('SubscriptionUpdated')
        this.store.dispatch(loadSubscription())
    });

    // Eventos vindos de Notifications com channel 'broadcast' atribuido
    this.echo.private('account.'+this.account!.id).notification((e:any) => {
        console.log("notificação para a acount",e);
        this.alertService.notification(e.title, e.message)
        this.store.dispatch(loadNotifications())
    });

    // A api dispara este evento quando houver o recebimento de um PIX
    this.echo.private('account.'+this.account!.id).listen('PaymentPixConfirmedEvent', (e:any) => {
        // console.log('PaymentSuccess')
        this.paymentPixEventEmitter.next(e)
    });

    this.echo.private('customer.' + this.user!.id).notification((e: any) => {
        this.alertService.notification(e.title, e.message);
        if (e.type.includes('roductsImportFinishNotification')) {
            const storageKey = e.data.key;
            this.localStorageService.removeItem(storageKey);
            this.localStorageService.setItem(storageKey, e.data, 60);
            this.localStorageService.setItem('products.import-key', storageKey, 60);
            this.store.dispatch(ProductsImportActions.setStep({ step: 4 }));
        }
    
        this.store.dispatch(loadNotifications());
    });
  }

  private initializeEcho(){
    this.echo = new Echo({
      Pusher:Pusher,
      broadcaster: 'pusher',
      wsHost: environment.websocketHost,
      wsPort: environment.wsPort,
      key: environment.wsAppKey,
      cluster:environment.wsCluster,
      encrypted: environment.wsEncrypted,
      forceTLS: environment.wsForceTLS,
      disableStats: true,
      authEndpoint:environment.broadcastingAuthUrl,
      auth: {
        headers: {
            Authorization: 'Bearer ' + this.user!.accessToken
        },
      },
      enabledTransports: ['ws','wss'],
    });

  }
}
