
import { createReducer, on } from "@ngrx/store";
import { CreditCard } from "app/entities/creditcard";
import { Error, Status } from "../AppState";
import { addCreditCard, addCreditCardFailure, addCreditCardSuccess, loadCreditCard, loadCreditCardFailure, loadCreditCardSuccess } from "./credit-card.actions";
import { logoutUserSuccess } from "../user/user.actions";

export interface CreditCardState {
    creditCard: CreditCard | null,
    error: Error | null;
    status: Status | null;
}


export const initialState: CreditCardState = {
    creditCard: null,
    error: null,
    status: {
        isError: false,
        isFinished: false,
        statusName: 'pending'
    },
};

export const creditCardReducer = createReducer(
    initialState, 
    on(loadCreditCard, (state) => ({
        ...state,
        status: {
            isError: false,
            isFinished: false,
            statusName: 'loading'
        },
    })),
    on(logoutUserSuccess, (state) => ({
        ...state,
        creditCard: null,
        error: null,
        status: {
            isError: false,
            isFinished: false,
            statusName: 'pending'
        },
    })),
    on(loadCreditCardSuccess, (state, {creditCard}) => ({
        ...state,
        creditCard: Object.keys(creditCard ?? {})?.length > 0 ? creditCard : null, 
        status: {
            isError: false,
            isFinished: true,
            statusName: 'success'
        },
    })),
    on(loadCreditCardFailure, (state, {error}) => ({
        ...state,
        status: {
            isError: true,
            isFinished: true,
            statusName: 'error'
        },
        error
    })),



    on(addCreditCard, (state) => ({
        ...state,
        status: {
            isError: false,
            isFinished: false,
            statusName: 'loading'
        },
    })),
    on(addCreditCardSuccess, (state, {creditCard}) => ({
        ...state,
        creditCard,
        status: {
            isError: false,
            isFinished: true,
            statusName: 'success'
        },
    })),
    on(addCreditCardFailure, (state, {error}) => ({
        ...state,
        error,
        status: {
            isError: true,
            isFinished: true,
            statusName: 'error'
        },
    })),
)