<div class="card-plan">
    <div class="card-header">
        <div class="plan-name">
            {{planPayment?.plan?.name}} <span class="recommended-plan" *ngIf="planPayment.plan?.slug == 'profissional'">Recomendado</span>
        </div>
    </div>
    <div class="card-body">
        <div class="card-description" [innerHtml]="planPayment?.description">
            
        </div>
        <div class="card-prices">
            <div class="detail-value">
                Valor equivalente mensal
            </div>
            <div class="card-primary-price">
                <strong>{{planPayment?.pricePerMonth | currency:'BRL'}}</strong><span>/mês</span>
            </div>
            <div class="card-secundary-price">
                <strong>{{planPayment?.price | currency:'BRL'}}</strong> por {{planPayment?.periodFormatted}}
            </div>

        </div>

        <button class="plan-btn" style="margin-top: 16px;" *ngIf="planPayment.currentPlanPaymentId!=planPayment.id ; else actual" (click)="planRedirect()">Assinar</button>
        <div  style="margin-top: 16px;" class="features-container">
            <ng-container *ngFor="let feature of planPayment?.plan?.features">
                <div class="feature-block" [ngSwitch]="feature.label">
                    <img *ngSwitchCase="'true'" src="/assets/svg/icons/circle_green_checkmark.svg" alt="">
                    <img *ngSwitchCase="'*'" src="/assets/svg/icons/circle_green_checkmark.svg" alt="">
                    <img *ngSwitchCase="'false'" src="/assets/svg/icons/error_mark.svg" alt="">
                    <span class="value" *ngSwitchDefault>{{feature.label}}</span>
                    <span class="nowrap"> {{feature.description}} 
                        <ng-container *ngSwitchCase="'*'"> Ilimitados</ng-container>
                        <ng-container *ngIf="feature.resettablePeriod==1"> por mês</ng-container>
                        <div class="tooltip-container" [ngClass]="{ 'show': isTooltipVisible }" (mouseenter)="showTooltip()" (mouseleave)="hideTooltip()" *ngIf="feature.information">
                            <mat-icon class="tooltip-icon">info</mat-icon>
                            <span class="tooltip-content">{{ feature.information }}</span>
                        </div>
                    </span>
                    
                    
                </div>
            </ng-container>
        </div>
        
    </div>
    <div class="card-footer">
        <button class="plan-btn" *ngIf="planPayment.currentPlanPaymentId!=planPayment.id ; else actual" (click)="planRedirect()">Assinar</button>
        <ng-template #actual>
            <button  style="margin-top: 13px;" class="current-btn" disabled>Plano atual</button>
        </ng-template>
    </div>
</div>