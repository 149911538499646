<div class="orca-dialog">
    <form [formGroup]="form">
        <div class="dialog-header">
            <h1 class="dialog-title">{{confirmTitle||'Confirmar ação'}}</h1>
        </div>

        <div class="dialog-content" fxLayout="column">
            <h4>{{confirmMessage}}</h4>
            <div  class="form-container" fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
                <mat-label class="bold-input mr-16">Senha</mat-label>
                <mat-form-field appearance="outline" fxFlex>
                    <mat-icon matSuffix class="secondary-text">lock</mat-icon>
                    <input name="password" formControlName="password" matInput type="password" placeholder="Informe sua senha">
                </mat-form-field>
            </div>
        </div>

        <div class="dialog-footer dialog-actions">
            <button type="button" class="btn btn-gray" [disabled]="loading" (click)="dialogRef.close(false)">Cancelar</button>
            <button type="submit" class="btn btn-blue" [disabled]="form?.invalid||loading" (click)="confirm()" fxLayout="row" fxLayoutAlign="start center">
                <mat-spinner *ngIf="loading" class="mr-16" diameter="20"></mat-spinner>
                Confirmar
            </button>
        </div>
</form>
</div>