import { Decodable } from './_decodable';
import { environment } from 'environments/environment';
import {Account} from './account';
import { List } from 'lodash';
import { Contact } from './contact';
import { Proposal } from './proposal';
declare var window: any;

class Client extends Decodable {
    id: number
    hashId: string
    name: string
    image: string
    email: string
    password: string
    phone: string
    responsible: string
    gender: string
    birthdate: string
    cpfCnpj: string
    acceptTerms: string
    emailVerifiedAt: Date
    contacts: Contact[]
    proposals: Proposal[]

    status: string
    statusFormatted: string
    statusClass: string

    account:Account

    address: string
    addressNumber: string
    addressState: string
    addressCity: string
    addressComplement: string
    addressNeighborhood: string
    cep: string

    createdAt: Date
    updatedAt: Date

    protected _map = {
        account: e => Account.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        emailVerifiedAt: e => this.parseDate(e),
    }
    

}

export {
    Client
}