import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { FeatureUsage, PlanFeatureSlugs } from 'app/entities';
import { PlanSubscription } from 'app/entities/plan-subscription';
import { SubscriptionRestrictionDialogComponent, SubscriptionRestrictionDialogData, SubscriptionRestrictionDialogResponse } from 'app/main/components/subscription-restriction-dialog/subscription-restriction-dialog.component';
import { loadSubscription, loadSubscriptionUsages } from 'app/state/subscription/subscription.actions';
import { selectFeatureUsages, selectSubscription } from 'app/state/subscription/subscription.selectors';
import { isEqual } from 'lodash';
import { distinctUntilChanged, filter } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class SubscriptionRestrictionService {


  subscription?: PlanSubscription|null;

  featureUsages?: FeatureUsage[]|null;

  currentRoute?: string|null;

  checkFeatureRestrictionInNextNavigation = false

  solveLaterDelayInHours = 6;

  dialogRef?:MatDialogRef<any,any>|null

  constructor(
    private _matDialog: MatDialog,
    private _router: Router,
    private store:Store
    ) {

    this.store.select(selectSubscription).pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr))).subscribe(subscription => {
        if(subscription){
            this.subscription = subscription;
            this.checkSubscriptionHasBlockingRestriction()
            this.checkSubscriptionHasSoftRestriction()
        }
    })

    this.store.select(selectFeatureUsages).pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr))).subscribe(usages => {
        if(usages){
            this.featureUsages = usages;
            this.checkSubscriptionHasFeatureLimit()
        }
    })

    this._router.events.pipe(
        filter(event => event instanceof NavigationEnd),
    ).subscribe((event: any) => {
        this.currentRoute = event.urlAfterRedirects;
        if (this.checkFeatureRestrictionInNextNavigation) {
            this.checkFeatureRestrictionInNextNavigation = false
            this.checkSubscriptionHasFeatureLimit()
        }
    })
        
  }

  reloadSubscriptionUsages(){
    this.store.dispatch(loadSubscriptionUsages())
  }

  checkSubscriptionHasSoftRestriction():boolean{
    // renew failed, status 1 = active, 5 = payment_failed
    if (this.subscription?.status == 5) {
        this.openPaymentFailedDialog()
        return true
    }
    return false
  }

  checkSubscriptionHasFeatureLimit(){
     let featureLimited = this.getSubscriptionFeatureLimited(null)
     if(featureLimited && featureLimited.showAlertOnLimitHit){
        // Não mostrar o dialog se o usuário estiver na página de proposta
        if (!this.currentRoute?.includes('propostas/')) {
            this.openFeatureLimitDialog(featureLimited.name)
        }else{
            // Agendar outra verificação para quando o usuário sair da página de proposta
            this.checkFeatureRestrictionInNextNavigation = true
        }
     }
  }

  checkFeatureLimitAndShowDialog(featureSlug: string): boolean {
    this.store.dispatch(loadSubscriptionUsages())
    let featureLimited = this.getSubscriptionFeatureLimited(featureSlug)
    let featureAbsent = this.getSubscriptionFeatureAbsent(featureSlug)
     if(featureLimited){
        if(featureAbsent){
            this.openFeatureAbsentDialog(featureLimited.name)
        }else{
            this.openFeatureLimitDialog(featureLimited.name, true)
        }
        return true
      }
     return false
  }

  getFeatureUsageBySlug(featureSlug: string){
    return this.featureUsages?.find(usage => usage.slug == featureSlug)
  }

  getSubscriptionFeatureLimited(featureSlug: string|null): FeatureUsage|undefined {
    return this.featureUsages?.find(usage => {
        if (featureSlug) {
            return usage.slug == featureSlug && usage.isLimited
        } else {
            return usage.isLimited === true
        }
    })
  }

  getSubscriptionFeatureAbsent(featureSlug: string): FeatureUsage|undefined {
    return this.featureUsages?.find(usage => {
        return usage.slug == featureSlug && usage.limit==='0'
    })
  }

  checkSubscriptionHasBlockingRestriction(openDialog: boolean = false): boolean {
    var restriction = false
    if (this.subscription) {
      if (this.subscription.trialEndsAt && this.subscription.trialEnds) {
        // trial ended
        this.openSubscriptionTrialExpiredDialog();
        restriction = true;
      } else if (this.subscription.status == 0 || this.subscription.status == 9) {
        // 0 = no subscription
        this.openPlanPurchaseDialog();
        restriction = true;
      } else if (this.subscription.status == 3) {
        // 3 = expired
        this.openSubscriptionExpiredDialog();
        restriction = true;
      } else if (this.subscription.status == 4) {
        // 4 = cancelled
        this.openSubscriptionCancelledDialog();
        restriction = true;
      }
    }
    if(restriction){
        this.store.dispatch(loadSubscription())
    }else{
        if(!openDialog)this.closeDialog()
    }
    return restriction;
  }

  public openCourtesyDialog(){
    this.openDialog({
        dilogID: 'courtesy-applied',
      subscription: this.subscription!,
      title: `Você ganhou uma cortesia!`,
      subtitle: `Você ganhou uma cortesia de 30 dias grátis para testar a nossa plataforma por completo`,
      description: "Agora é só aproveitar todas as funcionalidades da Orca.",
      canSolveLater: false,
      canRenew: false,
      canPurchase: false,
      showHappyImage: true,
      showExpiration: false,
    });
  }

  private openPaymentFailedDialog(){
    this.openDialog({
        dilogID: 'subscription-payment-failed',
      subscription: this.subscription!,
      title: `Você está prestes a <b>perder</b> acesso aos benefícios do seu Plano`,
      subtitle: `Não deixe a organização dos seus orçamentos de lado.`,
      description: "Renove sua assinatura e mantenha todos os benefícios do seu plano.",
      canSolveLater: true,
      canRenew: true,
      canPurchase: false,
      showExpiration: true,
    });
  }

  private openFeatureAbsentDialog(resourceName: string) {
    this.openDialog({
        dilogID: 'subscription-feature-absent-'+resourceName.toLowerCase(),
      subscription: this.subscription!,
      title: `Seu plano atual não possui <b>${resourceName.toLowerCase()}</b>`,
      subtitle: `Faça um upgrade para ter acesso a ${resourceName.toLowerCase()} e muitas outras vantagens!`,
      description: "A renovação é automática e você pode cancelar a qualquer momento.",
      canSolveLater: false,
      canRenew: false,
      canPurchase: true,
      showImage: true,
      showClose: true,
      forceOpen: true
    });
  }


  openFeatureLimitDialog(resourceName: string, _forceOpen=false) {
    this.openDialog({
        dilogID: 'subscription-feature-limit-'+resourceName.toLowerCase(),
      subscription: this.subscription!,
      title: `Você atingiu <b>o limite de ${resourceName.toLowerCase()}</b> para o seu plano.`,
      subtitle: `Faça upgrade do seu plano para ter acesso a mais ${resourceName.toLowerCase()} e muitas outras vantagens!`,
      description: "A renovação é automática e você pode cancelar a qualquer momento.",
      canSolveLater: true,
      canRenew: false,
      canPurchase: true,
      showImage: true,
      showClose: false,
      forceOpen: _forceOpen
    });
  }

  openSubscriptionTrialExpiredDialog() {
    this.openDialog({
        dilogID: 'subscription-trial-expired',
      subscription: this.subscription!,
      title: 'Sua cortesia expirou',
      subtitle: 'A cortesia do seu plano chegou ao fim. Para continuar utilizando todas as funcionalidades da Orca, contrate um de nossos planos.',
      description: '',
      canSolveLater: false,
      canRenew: true,
      canPurchase: true,
      showImage: true,
    });
  }

  openSubscriptionCancelledDialog() {
    this.openDialog({
        dilogID: 'subscription-canceled',
      subscription: this.subscription!,
      title: 'Seu plano foi cancelado.',
      subtitle: 'Para continuar utilizando todas as funcionalidades da Orca, renove ou assine um novo plano.',
      description: '',
      canSolveLater: false,
      canRenew: true,
      canPurchase: true,
      showImage: true,
    });
  }

  openPlanPurchaseDialog() {
    this.openDialog({
        dilogID: 'subscription-inactive',
      subscription: this.subscription!,
      title: 'Houve um problema ao ativar o seu plano.',
      subtitle: 'Para continuar usando a Orca, clique no botão abaixo e assine um de nossos planos.',
      description: '',
      canSolveLater: false,
      canRenew: true,
      canPurchase: true,
      showImage: true,
    });
  }

  openSubscriptionExpiredDialog() {
    this.openDialog({
        dilogID: 'subscription-canceled',
      subscription: this.subscription!,
      title: 'O seu plano expirou e você não pode mais acessar todas as funcionalidades da Orca.',
      subtitle: 'Não deixe a organização dos seus orçamentos de lado.',
      description: 'Renove sua assinatura e mantenha todos os benefícios do seu plano.',
      canSolveLater: false,
      canRenew: this.subscription!.canRenew,
      canPurchase: true,
      showImage: true,
    });
  }

  closeDialog(){
    if(this.dialogRef!=null){
        this.dialogRef.close()
        this.dialogRef = null
    }
  }
 
  openDialog(data: SubscriptionRestrictionDialogData) {
    this.closeDialog()
    if (data.canSolveLater && data.forceOpen == false) {
        if(!this.checkIfCanShowDialogAgain(data.dilogID)) {
            return;
        }
    }
    this.dialogRef = this._matDialog.open<SubscriptionRestrictionDialogComponent,SubscriptionRestrictionDialogData,SubscriptionRestrictionDialogResponse>(SubscriptionRestrictionDialogComponent, {
      data: data,
    })
    this.dialogRef.afterClosed().subscribe((response) => {
        if (data.canSolveLater) {
            localStorage.setItem(data.dilogID, new Date().toString());
        }
    })
  }

    checkIfCanShowDialogAgain(dialogID: string) {
        // Check if date stored in local storage with key 'dialogID' exists, and if it is 6 hours old
        let dialogDate = localStorage.getItem(dialogID);
        if (dialogDate) {
            let dialogDateObj = new Date(dialogDate);
            let now = new Date();
            let diff = now.getTime() - dialogDateObj.getTime();
            let hours = diff / (1000 * 60 * 60);
            if (hours < this.solveLaterDelayInHours) {
                return false;
            }
        }
        return true;
    }

    isFreePlan(): boolean {
        return this.subscription?.planPayment?.price == 0;
    }

    hasCourtesy(): boolean {
        return this.subscription?.trialEndsAt != null && this.subscription?.trialEndsAt != undefined;
    }
}
