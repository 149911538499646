import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContatoFeedback } from 'app/entities';
import { AlertService } from 'app/services/alert.service';
import { AuthService } from 'app/services/auth.service';
import { ContatoFeedbackService } from './contato-feedback.service';

@Component({
    selector: 'app-contato-feedback',
    templateUrl: './contato-feedback.component.html',
    styleUrls: ['./contato-feedback.component.scss']
})
export class ContatoFeedbackDialogComponent implements OnInit {

    @ViewChild("contentIframe") contentIframe!: ElementRef

    title = "Suporte via E-mail"
    form!: FormGroup;
    
    data: any;
    loading = false;
    options: any;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        public _matDialog: MatDialog,
        private _authService: AuthService,
        private _alertService: AlertService,
        private _feedbackService: ContatoFeedbackService,
        public matDialogRef: MatDialogRef<ContatoFeedbackDialogComponent>
    ) {
        this.data = _data

    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            assunto: [null, [Validators.required]],
            descricao: [null, [Validators.required]],
        });
    }
    ngOnInit() {

        this.options = [{ name: "Técnico" }, { name: "Finaceiro" }, { name: "Outros" }];
        this.form = this.createForm();
    }

    cancelar() {
        this.matDialogRef.close()
    }

    enviar() {
        if (this.form.valid) {

            let contatoFeedback = new ContatoFeedback();
            contatoFeedback.email = this._authService.currentUser?.email ?? "";
            contatoFeedback.about = this.form.value.assunto.name;
            contatoFeedback.message = this.form.value.descricao;
          
            this._feedbackService.store(contatoFeedback).subscribe((msg) => {
                this._alertService.success('Obrigado por entrar em contato. Sua opinião é muito importante para nós. Retornaremos em breve pelo seu email.')
                this.matDialogRef.close()
            }, err => {
                this._alertService.error("Erro ao enviar a mensagem.")
            })
        }
    }



}
