import { Injectable } from '@angular/core';
import { ApiResponse, Product } from "app/entities";
import { ApiService } from "app/services/api.service";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductsService extends ApiService {

    findAll(params: any) {
        params = this.paramsFromObject(params);
        params.summed_up = false
        return this.httpService.get(`/v1/clients/products`, params)
            .pipe(
                map(res => ApiResponse.parseWith(new Product, res) as ApiResponse<Product[]>)
            )
    }

    simpleProductsAll() {
        return this.httpService.get(`/v1/clients/products/simple-products`, {}).pipe(
            map(res => ApiResponse.parseWith(new Product, res) as ApiResponse<Product[]>)
        )
    }

    find(hashId: any) {
        return this.httpService.get(`/v1/clients/products/${hashId}`)
            .pipe(
                map(res => Product.parse(res.data) as Product)
            )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/products`, formData)
            .pipe(
                map(res => Product.parse(res.data) as Product)
            )
    }

    update(productId: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/products/${productId}`, formData)
            .pipe(
                map(res => Product.parse(res.data) as Product)
            )
    }

    updateStatus(productId: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/products/${productId}/status`, formData)
            .pipe(
                map(res => Product.parse(res.data) as Product)
            )
    }

    destroy(id: number) {
        return this.httpService.delete(`/v1/clients/products/${id}/delete`)
    }

    hasLimit() {
        return this.httpService.get(`/v1/clients/products/limit`);
    }

    changePhoto(productId, image: File){
        let formData = this.formDataFromObject({image})
        return this.httpService.post(`/v1/clients/products/${productId}/photo`, formData)
    }

    import(data: any) {
        return this.httpService.post(`/v1/clients/products/import`, data)
    }
    
    processImporData(data: any) {
        return this.httpService.post(`/v1/clients/products/process-import-data`, data)
    }
}
 