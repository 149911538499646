<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left'">



</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
    <!-- NAVBAR: Top -->

    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->


    <!-- <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container> -->
    
    <!-- / NAVBAR: Top -->

    <!-- TOOLBAR: Below -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Below -->



    <div id="container-1" class="container">

        <!-- NAVBAR: Left -->
        <ng-container>
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">

            <div id="container-3" class="container" fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

              
            </div>

        </div>

    </div>

</div>

<!-- SIDE PANEL -->
<ng-container *ngIf="!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right'">

</ng-container>
<!-- / SIDE PANEL -->


<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="fuseConfig.layout.toolbar.customBackgroundColor === true ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background : fuseConfig.layout.toolbar.position">
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->


<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar *ngIf="!fuseConfig.layout.navbar.hidden" variant="horizontal-style-1" class="top-navbar" fxHide fxShow.gt-md >
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
        *ngIf="!fuseConfig.layout.navbar.hidden">
        <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->