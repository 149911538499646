import { Decodable } from "./_decodable";

class Unit extends Decodable {
    static TYPE_DEFAULT = 1;
    static TYPE_PERCENT = 2;

    id: number;
    name: string;
    abbr: string;
    type: number;
    accountId: number;
    isFixed: boolean;
    status: number;
    createdAt: Date;


    public static defaultUnitKey = "default_unit";

}

export { Unit };
