import { NgModule } from "@angular/core";
import { HorizontalLayout1Module } from "app/layout/horizontal/layout-1/layout-1.module";
import { DialogModule } from "./components/dialog/dialog.module";
import { TableModule } from "./components/table/table.module";
import { ImageFieldComponent } from "./components/image-field/image-field.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { CommonModule } from "@angular/common";
import { ImageFieldDefaultComponent } from "./components/image-field-default/image-field-default.component";
import { AvatarModule } from "ngx-avatar";
import { ImageFieldAvatarComponent } from "./components/image-field-avatar/image-field-avatar.component";

@NgModule({
    imports: [
        CommonModule,

        HorizontalLayout1Module,

        DialogModule,

        TableModule,

        MatIconModule,
        MatButtonModule,
        AvatarModule,
    ],
    exports: [
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        HorizontalLayout1Module,
        DialogModule,
        TableModule,
        ImageFieldComponent,
        ImageFieldAvatarComponent,
        ImageFieldDefaultComponent,
    ],
    declarations: [
        ImageFieldAvatarComponent,
        ImageFieldComponent,
        ImageFieldDefaultComponent,
    ],
})
export class LayoutModule {}
