import { createAction, props } from "@ngrx/store";
import { Payment } from "app/entities";

export enum PaymentActionTypes {
    loadPayments = '[Payment] Load Payment',
    loadPaymentsSuccess = '[Payment] Load Payment Success',
    loadPaymentsFailure = '[Payment] Load Payment Failure',
}

export const loadPayments = createAction(PaymentActionTypes.loadPayments);
export const loadPaymentsSuccess = createAction(
    PaymentActionTypes.loadPaymentsSuccess,
    props<{payments: Payment[] | null}>()
);
export const loadPaymentsFailure = createAction(
    PaymentActionTypes.loadPaymentsFailure,
    props<{ error: any }>()
);