import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GtagService } from 'app/services/gtag.service';
import { PlanPayment } from 'app/entities';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {


  isTooltipVisible: boolean = false;


  @Input("planPayment") planPayment!: PlanPayment;
  constructor(
    private _router: Router,
    private _gtag: GtagService
  ) { }

  ngOnInit(): void {
  }


  planRedirect(){
    this._gtag.event('select_plan', {
      event_category: 'engagement',
      event_label: `Visualização do plano ${this.planPayment?.slug}`
    });
    this._router.navigate(['/planos-de-assinatura/checkout/'+ this.planPayment?.plan?.slug+'/'+this.planPayment?.slug])

  }

   

  showTooltip() {
    this.isTooltipVisible = true;
  }

  hideTooltip() {
    this.isTooltipVisible = false;
  }
}
