import { User } from "./user";
import { Decodable } from "./_decodable";

class Meta extends Decodable {
    
    id: number;
    valueMeta: number;
    totalApproved?: number
    classColor:string
    startAt?: Date;
    endAt?: Date;
    score?: number
    isActiva?: boolean
    status: number
    statusName?: string
    statusClass?: string
    createdAt: Date;
    updatedAt: Date;
    customerId?: number
    customer?: User
    protected _map = {
        startAt: (e) => this.parseDate(e),
        endAt: (e) => this.parseDate(e),
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
    };
}

export { Meta };
