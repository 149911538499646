import { Injectable } from '@angular/core';
import { ApiService } from "app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class ChartsService extends ApiService {

  getTotalProposalsChart(params: any = {}) {
    params = this.paramsFromObject(params);
    return this.httpService.get(`/v1/clients/charts/status-sum-chart`, params)
  }

  getLastProposalsChart(params: any = {}) {
    params = this.paramsFromObject(params);
    return this.httpService.get(`/v1/clients/charts/last-proposals-chart`, params)
  }

  

}
