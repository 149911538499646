import { createReducer, on } from '@ngrx/store';
import * as ProductsImportActions from './products-import.actions';
import { ProductsImportState } from './products-import.state';

export const initialState: ProductsImportState = {
    step: 1,
    isImportLoading: false,
};

export const productsImportReducer = createReducer(
  initialState,
  on(ProductsImportActions.incrementStep, (state) => ({ ...state, step: state.step + 1 })),
  on(ProductsImportActions.decrementStep, (state) => ({ ...state, step: state.step - 1 })),
  on(ProductsImportActions.setStep, (state, { step }) => ({ ...state, step })),
  on(ProductsImportActions.setIsImportLoading, (state, { isImportLoading }) => ({ ...state, isImportLoading }))
);
