<div class="orca-dialog">
    <div class="dialog-header">
        <h1 class="dialog-title">{{title}}</h1>

        <div class="dialog-btn-close">
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog" style="z-index: 2;">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="dialog-content">
        <div fxLayout="column">
            <div class="container-upload-files" *ngIf="!disbled" (dragover)="allowDrop($event);"
                [ngClass]="borderBlue ? 'border-blue' : 'border-black'">
                <img src="/assets/svg/orca-32.svg" alt="Image de uma Orca">
                <label for="">
                    Selecione a imagem desejada clicanco aqui, 
                    ou arrastando o arquivo para este espaço. 
                    Resolução e formato ideal ideal: {{resizeToWidth}}x{{resizeToHeight}}, JPEG ou PNG.
                    Tamanho máximo 5 MB.
                </label>
                <input type="file" (change)="fileChangeEvent($event)"
                    accept="image/png, image/jpg, image/jpeg, image/gif, image/svg, image/webp, image/ico" />
                <!-- <img [src]="croppedImage" /> -->
            </div>

            <div *ngIf="disbled">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="resizeToWidth/resizeToHeight" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed($event)" [imageQuality]="100" [onlyScaleDown]="true"></image-cropper>
            </div>
        </div>
    </div>

    <div class="dialog-footer dialog-actions">
        <button [disabled]="loading" class="btn btn-red" aria-label="Fechar" (click)="dialogRef.close()">
            Fechar
        </button>

        <button [disabled]="loading" (click)="save()" class="btn btn-blue" aria-label="SALVAR" fxLayout="row" fxLayoutAlign="start center">
            <mat-spinner *ngIf="loading" class="mr-8" diameter="15" ></mat-spinner>
            Salvar
        </button>


    </div>
</div>