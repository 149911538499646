import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar-notification',
  templateUrl: './custom-snackbar-notification.component.html',
  styleUrls: ['./custom-snackbar-notification.component.scss']
})
export class CustomSnackbarNotificationComponent implements OnInit {

  constructor(public snackBarRef:MatSnackBarRef<CustomSnackbarNotificationComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit(): void {
    MatSnackBarRef
  }

}
