import { Component, Input, ElementRef, AfterViewInit, ContentChild } from '@angular/core';
@Component({
  selector: 'orca-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements AfterViewInit {

  @Input('type') type = '';
  @Input('name') name;

  constructor(
  ) {

  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
   
    
  }
}
