import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'orca-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input("isActive") isActive:boolean = false
  @Input("disabled") disabled:boolean = false
  @Output("toggle") outputToggle = new EventEmitter<boolean>()
  
  ngOnInit(): void {
  }

  toggleSwitch() {
    console.log("toggleSwitch")
    if (this.disabled) {
      return;
    }
    console.log("toggleSwitch disabled false")
  
    // this.isActive = !this.isActive;
    this.outputToggle.emit(!this.isActive);
  }
  

}
