import { Component, Input, OnInit } from '@angular/core';
import { PlanPayment } from 'app/entities';

@Component({
  // selector: 'app-label-feature-table-plans',
  selector: "[featuresTds]",
  templateUrl: './label-feature-table-plans.component.html',
  styleUrls: ['./label-feature-table-plans.component.scss'],
})
export class LabelFeatureTablePlansComponent implements OnInit {

  @Input('planPayments') planPayments!: PlanPayment[]
  @Input('slug') slug!: String
  @Input('title') title!: String
  constructor() { }

  ngOnInit(): void {
  }

  isNumber(value) {
    return typeof value === 'number' || value instanceof Number;
  }

}
