import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Account } from 'app/entities/account';
import { Store } from '@ngrx/store';
import { selectSettingsFirstSteps } from 'app/state/account/account.selectors';
import { isEqual } from 'lodash';
import { loadSettingsFirstSteps } from 'app/state/account/account.actions';

@Injectable({
    providedIn: 'root'
})
export class AccountService {

    constructor(
        private httpService: HttpService,
        private store: Store
    ) { 
        
    }

    load(): Observable<Account> {
        return this.httpService.get('/v1/clients/settings/account').pipe(
            map(res => Account.parse(res.data) as Account)
        );
    }

    getStepsForGroup(group){
        return this.httpService.get('/v1/clients/first-step-settings/'+group).pipe(
            map(res => res.data as any)
        );
    }
}