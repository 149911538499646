import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-usummernote',
  templateUrl: './usummernote.component.html',
  styleUrls: ['./usummernote.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsummernoteComponent),
      multi: true,
    },
  ],
})
export class UsummernoteComponent implements OnInit, ControlValueAccessor {

  constructor() { }
  megabyte = 1024*1024
  smSettings = {
    toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['fontsize', ['fontsize']],
        ['color', []],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['insert', ['picture']],
        ['view', ['fullscreen', 'codeview']],
    ],
    popover: {
        image: [
            ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
            ['float', ['floatLeft', 'floatRight', 'floatNone']],
            ['remove', ['removeMedia']]
        ]
    },
    tabDisable: true,
    lang: 'pt-BR',
    dialogsInBody: true,
    maximumImageFileSize: this.megabyte * 5,
    uploadImagePath: environment.apiHost + '/api/v1/clients/ckeditor/upload',
    callbacks: {
        onInit: (edt) => {
            edt.note.on('summernote.keyup', function (we, e) {
                if (e.keyCode == 9) {
                    e.stopPropagation()
                    if (e.shiftKey) {
                        edt.note.data('summernote').invoke('editor.outdent')
                    } else {
                        edt.note.data('summernote').invoke('editor.indent')
                    }
                }
            });
        },
        onChange:(edt)=>{
            this.onChange(edt)
        }
    }
}

  ngOnInit(): void {
  }
  @Output("onLoading") onLoading = new EventEmitter<boolean>()
  @Input() placeholder?: string;
  disabled?: boolean;
  value?: string;

  // Implement ControlValueAccessor methods
  writeValue(value: string): void {
    this.value = value;
  }

  onImageUpload(event){
      this.onChange(this.value)
    if(event.uploading){
        this.disabled = true
        this.onLoading.emit(true)
    }else{
        this.disabled = false
        this.onLoading.emit(false)
    }
    // console.log("summernote onImageUpload");
    // console.log(event);
    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (value: any) => {}
  onTouched() {}

}
