import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Client } from 'app/entities';
import { SettingsService } from 'app/main/pages/settings/settings.service';
import { AuthService } from 'app/services/auth.service';
import { AccountService } from 'app/services/http/account.service';
import { loadSettingsFirstSteps, loadSettingsFirstStepsSuccess } from 'app/state/account/account.actions';
import { selectSettingsFirstSteps } from 'app/state/account/account.selectors';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-card-account-settings',
  templateUrl: './card-account-settings.component.html',
})

export class CardAccountSettingsComponent implements OnInit, OnDestroy {

  @Output() onCreated = new Subject<Client>();

  private _unsubscribeAll = new Subject();

  steps:any = null
  

  constructor(
    private _router: Router,
    private _settingsService: SettingsService,
    private store: Store
  ) { }
    

  ngOnInit(): void {
    this.store.select(selectSettingsFirstSteps).pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntil(this._unsubscribeAll)
    ).subscribe(steps=>{
        this.steps = steps
    })

    this.store.dispatch(loadSettingsFirstSteps());
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete();
  }

  
  redirectTo(navigation) {
    this._router.navigate(navigation);
  }

  skipSteps(key: string): void {
    console.log("skip",key);
    
    if (this.steps[key] !== undefined) {
        this._settingsService.skipStep(key).subscribe(steps=>{
            this.store.dispatch(loadSettingsFirstStepsSuccess({steps}))
        });
    } else {
        console.error("Invalid data or key provided.");
    }
   
  }

  onItemClick(_key:string){
    this._router.navigate(['configuracao/proposta'],{queryParams:{key:_key}});
    console.log("act",_key);
  }
}
