import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountLayoutProposal, ApiResponse } from 'app/entities';
import { Account } from 'app/entities/account';
import { ApiService } from 'app/services/api.service';
import { parse } from 'path';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends ApiService {

    getSettings(){
        return this.httpService.get(`/v1/clients/settings`, {})
    }

    getAccount(){
        return this.httpService.get(`/v1/clients/settings-account`, {}).pipe(
            map(res => Account.parse(res.data) as Account)
        )
    }

    updateAccount(account){
        return this.httpService.post(`/v1/clients/settings-account`, account).pipe(
            map(res => Account.parse(res.data) as Account)
        )
    }

    updateProposal(data){
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/settings-proposal`, formData).pipe(
            map(res => AccountLayoutProposal.parse(res.data) as AccountLayoutProposal)
        )
    }

    deleteImageProposal(params){
        return this.httpService.post(`/v1/clients/settings-proposal/image/delete`, params)
    }

    // Notification

    getNotificationPermissions(){
        return this.httpService.get(`/v1/clients/settings/notification`, {})
    }

    updateNotificationPermissions(permissions){
        return this.httpService.post(`/v1/clients/settings/notification`, permissions)
    }

    updateCpfCnpj(cpfCnpj: string) {
        return this.httpService.post(`/v1/clients/settings/account/cpfOrCnpj`, {cpfCnpj})
    }

    skipStep(step){
        return this.httpService.post(`/v1/clients/settings-skip-step`, {step_key:step}).pipe(
            map(res => res.data as any)
        )
    }

}
