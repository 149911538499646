import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { loadAccountSuccess } from '../account/account.actions';
import { loginUserFailure, loginUserSuccess } from './user.actions';



@Injectable()
export class UserEffects {

    loginUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loginUserSuccess),
            concatMap(({user}) => [
                loadAccountSuccess({ account: user.account! }),
                
            ]),
            catchError((error) => of(loginUserFailure({ error })))
        )
    )

    constructor(
        private actions$: Actions
    ) { }


}