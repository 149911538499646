import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-config-step',
  templateUrl: './config-step.component.html',
  styleUrls: ['./config-step.component.scss']
})
export class ConfigStepComponent implements OnInit {

    @Input("color") color = "#5ba5fe"
    @Input("key") key 
    @Input("title") title
    @Input("isComplete") isComplete = false
    @Input("skipTitle") skipTitle
    @Output("onSkip") onSkip = new EventEmitter<string>()
    @Output("onAction") onAction = new EventEmitter<string>()

    incompleteColor='#e8ecec'

  constructor() { }

  ngOnInit(): void {
  }

  skip(event){
    event.stopPropagation()
    this.onSkip.emit(this.key)
  }

  act(event){
    event.stopPropagation()
    this.onAction.emit(this.key)
  }

}
