import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { Unit } from 'app/entities/unit';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingletonsService {

  constructor(
    private httpService: HttpService, 
    private localStorageService: LocalStorageService
  ) { }

  private currentUnitsSubject$ = new BehaviorSubject<Unit[]>(this.localStorageService.getUnits());
  private currentUnits$ = this.currentUnitsSubject$.asObservable().pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));

  

  getUnits():Observable<Unit[]>{
    this.fetchUnits().subscribe((units) => {
        this.currentUnitsSubject$.next(units)
    });
    return this.currentUnits$
  }

  fetchUnits(): Observable<Unit[]> {
    return this.httpService.get('/v1/clients/units/simple-units')
      .pipe(
        map(response => {
            var parsed: Unit[] = Unit.parse(response.data)
            this.localStorageService.saveUnits(parsed)
            return parsed;
        }));
  }

}
