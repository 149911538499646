import { Plan } from "./plan";
import { Decodable } from "./_decodable";

class PlanFeatureSlugs{
    static USUARIOS = 'usuarios'
    static PROPOSTAS = 'propostas'
    static CLIENTES = 'clientes'
    static METAS = 'metas'
    static NOTIFICACAO_VISUALIZACAO = 'notificacao-de-proposta-visualizada'
    static PRODUTOS_E_SERVICOS = 'produtos-e-servicos'
    static PDF_PROPOSTAS = 'pdf-propostas'
}

class FeatureUsage extends Decodable {
    limit: number|string;
    name: string;
    slug: string;
    used: number|string;
    isLimited: boolean;
    showAlertOnLimitHit: boolean;
    showFeatureUsage: boolean;
    isFreePlan: boolean;
    percentage: number;
    resettablePeriod: number;
    resettableInterval: string;
    type: string;
}

class PlanFeature extends Decodable {
    id: number;
    planId: number;
    slug: string
    name: string
    description: string
    value: string
    resettablePeriod: number
    resettableInterval: string

    createdAt: Date;
    updatedAt: Date;


    plan?: Plan;



    protected _map = {
        plan: e => Plan.parse(e),
        
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export { PlanFeature, FeatureUsage, PlanFeatureSlugs };
