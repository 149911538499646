import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AlertService } from 'app/services/alert.service';
import { Subject } from 'rxjs';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss']
})
export class ImageFieldComponent implements OnInit {

  @Input() accept = ['image/png', 'image/jpeg']
  @Input() editable = true
  @Input() excludable = false
  @Input() itemCreated = false
  @Output() imageSelected = new EventEmitter();
  @ViewChild('fileInput') imageInput!: ElementRef;
  _src;
  @Input() set src(value: any) {
    if(value) {
      this._src = value
    }
  }
  _clientName = "SEM NOME"; 
  
  @Input() set clientName(value: any) {
    if(value){
      this._clientName = value
    }
  }
  constructor(
    private _alertService: AlertService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Limpando imagem apos salvar
    if (changes.itemCreated) {
      if (changes.itemCreated.currentValue === true) {
        this.imageInput.nativeElement.value = "";
        this._src = null;
      }
    }
  }

  fileChange(event: any) {
    if (!this.editable) return;
    
    let file, img;

    if ((file = event.target.files[0]) && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = (_event) => { 
        this.src = reader.result
      }

      this.imageSelected.emit(file)
      
    } else {
      this.imageSelected.emit()
      this._alertService.error("Formato de imagem não suportado. Somente 'jpg' e 'png' são permitidos.")
    }
  }

  deleteImage(){  
    if(confirm("Você tem certeza que quer excluir essa imagem?")) {

      this.imageSelected.emit()
    }
  }
}
