<div class="avatar-wrapper">
    <img (click)="fileInput.click()" [src]="_src" class="avatar m-0" />
    <button *ngIf="excludable" (click)="deleteImage()" mat-icon-button class="mat-icon-button-excludable">
        <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="editable" (click)="fileInput.click()" mat-icon-button class="mat-accent mat-icon-button-editable">
        <mat-icon>edit</mat-icon>
    </button>
</div>

<input #fileInput (change)="fileChange($event)" hidden type="file" name="image" [accept]="accept">