<div class="orca-dialog">
    <form [formGroup]="form"  *ngIf="initialized">
        <div class="dialog-header">
            <h1 class="dialog-title">{{feedbackSettings?.action}}</h1>
        </div>

        <div class="dialog-content" fxLayout="column">
            <h4>{{feedbackSettings?.description}}</h4>
            <div *ngIf="feedbackSettings?.formSettings?.hasReason">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Motivo</mat-label>
                    <mat-select formControlName="reason">
                        <mat-option *ngFor="let reason of feedbackSettings?.reasons" [value]="reason?.actionReason">
                            <span>{{reason.label}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="feedbackSettings?.formSettings?.hasMessage">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Detalhes</mat-label>
                    <textarea formControlName="message" matInput></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="dialog-footer dialog-actions">
            <button type="submit" class="btn btn-blue" [disabled]="form?.invalid||loading" (click)="confirm()" fxLayout="row" fxLayoutAlign="start center">
                <mat-spinner *ngIf="loading" class="mr-16" diameter="20"></mat-spinner>
                {{feedbackSettings?.actionButton?feedbackSettings?.actionButton:'Enviar'}}
            </button>
        </div>
    </form>
    <ng-container *ngIf="!initialized">
        <div fxLayout="column" fxLayoutAlign="center center" class="p-40">
            <mat-spinner class="center-spinner" diameter="40"></mat-spinner>
        </div>
    </ng-container>
</div>