import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-info-steps',
  templateUrl: './card-info-steps.component.html',
  styleUrls: ['./card-info-steps.component.scss']
})

export class CardInfoStepsComponent implements OnInit {
    
    @Input('color') color = '#5BA5FE'
    @Input('showChart') showChart = true
    @Input('infoTitle') infoTitle = "Título"
    @Input('infoDescription') infoDescription = "Título"
    @Input('steps') steps!: any;

    loading = false;
    colorScheme = {
      domain: [
        this.color,
        '#E4E6EF',
      ]
    }
  
    counts:any =  {
      trueCount: 0,
      falseCount: 0,
    }
  
    series = [
      {
        "name": "Concluído",
        "value": this.counts.trueCount,
      },
      {
        "name": "Pendente",
        "value": this.counts.falseCount,
      },
    ];
  
    constructor(
      private _changeDetector: ChangeDetectorRef,
    ) { }
  
    ngOnInit(): void {
        this.colorScheme = {
            domain: [
                this.color,
                '#E4E6EF',
            ]
        }
        this.updateCounts();
    }
  
    updateCounts() {
      this.counts = Object.values(this.steps).reduce(
        (accumulator:any, currentValue:any) => {
            accumulator[currentValue ? 'trueCount' : 'falseCount']++;
            return accumulator;
          },
          { trueCount: 0, falseCount: 0 }
      );
      this.series = [
        {
          "name": "Concluído",
          "value": this.counts.trueCount,
        },
        {
          "name": "Pendente",
          "value": this.counts.falseCount,
        },
      ];
      this._changeDetector.detectChanges();
    }
  
  }
  