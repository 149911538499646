import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { AlertService } from 'app/services/alert.service';

@Component({
  selector: 'app-confirm-password-dialog',
  templateUrl: './confirm-password-dialog.component.html',
  styleUrls: ['./confirm-password-dialog.component.scss']
})
export class ConfirmPasswordDialogComponent implements OnInit {

  public confirmTitle: any;
  public confirmMessage: string = "Para confirmar a realização dessa ação, digite sua senha de administrador.";
  public onSuccess: EventEmitter<any> = new EventEmitter<any>();
  public successCallback?: () => any;

  form: FormGroup

  loading = false
  
  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _alertService: AlertService,
    public dialogRef: MatDialogRef<ConfirmPasswordDialogComponent>
  ) {
    // apply no padding panel class
    this.dialogRef.addPanelClass('no-padding-dialog-container'); 
    this.form = this._formBuilder.group({
      password: [ null, [Validators.required]],
    })
  }

  ngOnInit() {
  }

  async confirm() {
    if(this.form.valid) {
        this.loading = true
        this.dialogRef.disableClose = true
        try {
            await this._authService.validatePassword(this.form.value.password).toPromise()
            if(this.successCallback) {
                await this.successCallback()
                this.onSuccess.emit(true);
            }else{
                this.onSuccess.emit(true);
                this.dialogRef.close(true)
            }
        } catch (error) {
            this.loading = false
            this.dialogRef.disableClose = false
            this._alertService.error(error)
        }
       
    }
  }

}
