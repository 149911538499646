<div class="switch-container">
    <button 
        class="switch" 
        [ngClass]="{
            'active': isActive && !disabled, 
            'inactive': !isActive && !disabled,
            'disabled': disabled
        }" 
        (click)="!disabled && toggleSwitch()">
        <span >
            {{ disabled ? 'Fixo  ' : (isActive ? 'Ativo' : 'Inativo') }}
        </span>
    </button>
</div>
