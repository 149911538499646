
<button  class="btn btn-green" fxLayout="row" fxLayoutAlign="center center" (click)="open()"> 
    <ng-content ></ng-content>
    <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="dropDown" style="position: absolute; visibility: hidden;"></div>
</button>

<mat-menu class="client-create-menu" #dropDown="matMenu">
    <div class="card-filter" id="card-filter" (click)="$event.stopPropagation()">

        <div class="card-client-create-header form-menu-header">
            <div class="card-client-create-header-title form-menu-header-title">
                Adicionar Cliente

                <div class="form-menu-close-btn">
                    <button mat-icon-button (click)="menuTrigger.closeMenu()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-client-create-content form-menu-content" *ngIf="menuOpen">
            <app-form-client-create-or-edit [manuTrigger]="menuTrigger"
                (onCreatedClient)="created($event)"></app-form-client-create-or-edit>
        </div>

    </div>
</mat-menu>