<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>

<!-- / HORIZONTAL LAYOUT 1 -->


<!-- Tawk chat component disabled -->
<!-- <app-tawk></app-tawk> -->