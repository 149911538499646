<button class="btn btn-blue" fxLayout="row" fxLayoutAlign="center center"  (click)="open()">
    Adicionar Usuário
    <div #filterMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="proposalFilter" style="position: absolute; visibility: hidden;"></div>
</button>
<mat-menu class="user-create-menu" #proposalFilter="matMenu">
    <div class="card-filter" id="card-filter" (click)="$event.stopPropagation()">

        <div class="card-user-create-header form-menu-header">
            <div class="card-user-create-header-title form-menu-header-title">
                Novo Usuário
                <div class="form-menu-close-btn">
                    <button mat-icon-button (click)="filterMenuTrigger.closeMenu()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-user-create-content form-menu-content" *ngIf="menuOpen">
            <form [formGroup]="form" (keydown.tab)="$event.stopPropagation()">

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="stretch">

                    <label class="label-input" for="input-name">Nome Completo</label>
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Nome</mat-label>
                        <input type="text" placeholder="Nome Completo" matInput formControlName="name"
                            autocomplete="off">
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="stretch">

                    <label class="label-input" for="input-name">E-mail</label>
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>E-mail</mat-label>
                        <input type="email" autocapitalize="off" autocomplete="off" matInput formControlName="email">
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="stretch">

                    <label class="label-input" for="input-name">Telefone</label>
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Telefone</mat-label>
                        <input type="phone" placeholder="(xx) 9 xxxx-xxxx" mask="(00) 00009-0000" autocomplete="off"
                            matInput formControlName="phone">
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="stretch">

                    <label class="label-input" for="input-name">Função</label>

                    <mat-form-field appearance="outline" fxFlex>
                        <!-- <mat-label>Função</mat-label> -->
                        <mat-icon matPrefix class="role-prefix-icon">admin_panel_settings</mat-icon>
                        <mat-select matInput formControlName="roleName" required>
                            <mat-option value="admin">Administrador</mat-option>
                            <mat-option value="commercial">Comercial</mat-option>
                            <mat-option value="seller">Vendedor</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="stretch"
                    fxLayoutAlign="start center">
                    <label class="label-input">Senha</label>


                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Senha</mat-label>
                        <input type="password" matInput formControlName="password">
                    </mat-form-field>

                </div>

                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="stretch"
                    fxLayoutAlign="start center">
                    <label class="label-input"></label>


                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Confirmar senha</mat-label>
                        <input type="password" matInput formControlName="password_confirmation">
                    </mat-form-field>

                </div>

                <div class="card-btns-container form-menu-footer mt-8">
                    <button class="btn btn-gray" (click)="close()">Descartar</button>
                    <button class="btn btn-blue" (click)="createCustomer()" [disabled]="!form?.valid || loading">Criar
                        Usuário</button>
                </div>
            </form>
        </div>

    </div>
</mat-menu>