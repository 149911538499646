import { Decodable } from './_decodable';

enum AnswerType {
    DETRACTOR = 1,
    NEUTRAL = 2,
    PROMOTER = 3
}

class Survey extends Decodable {
    id!: number
    name!: string

    detractorQuestion!: string
    neutralQuestion!: string
    promoterQuestion!: string
    
    messageQuestion!: string
    description: string | undefined
    
    detractorAnswers!: SurveyAnswer[]
    neutralAnswers!: SurveyAnswer[]
    promoterAnswers!: SurveyAnswer[]

    protected _map = {
        detractorAnswers: e => SurveyAnswer.parse(e),
        neutralAnswers: e => SurveyAnswer.parse(e),
        promoterAnswers: e => SurveyAnswer.parse(e),
    }
}

class SurveyAnswer extends Decodable {
    id!: number
    answer!: string
    type!: AnswerType
}

export {
    Survey,
    SurveyAnswer,
    AnswerType,
}