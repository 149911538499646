import { User } from './user'
import { Decodable } from './_decodable'

class Log extends Decodable {

    id?: number
    ip?: string
    action?: string
    class?: null
    icon?: null
    proposalId: any
    customerId: any
    customer?: User
    status: any
    createdAt?: Date
    customerName?:string

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }

   
}

export {
    Log
}