import { User } from './user'
import { Decodable } from './_decodable'
import { ProductCategory } from './product-category'
import { Unit } from './unit'

class Product extends Decodable {

    id?: number
    productCategoryId?: number
    unitId?: number
    code?: string
    title?: string
    image?: string
    description?: string
    type?: string
    valor?: number
    status?: number

    productCategory?: ProductCategory
    unit?: Unit
    createdAt?: Date
    updatedAt?: Date

    protected _map = {
        productCategory: e => ProductCategory.parse(e),
        unit: e => Unit.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Product
}