<button #menuTrigger="matMenuTrigger"
[matMenuTriggerFor]="dropDown" 
[class.btn]="!responsiveBtn && appearance !== 'mat-menu-item'"
[class.btn-green]="!responsiveBtn && appearance !== 'mat-menu-item'"
[class.mat-menu-item]="!responsiveBtn && appearance === 'mat-menu-item'"
[class.mat-focus-indicator]="!responsiveBtn && appearance === 'mat-menu-item'"
[class.custom-rounded-icon-button]="responsiveBtn"
[class.custom-rounded-icon-button-green]="responsiveBtn"
[style.border]="responsiveBtn ? 'none' : null"
fxLayout="row" 
(click)="$event.stopPropagation()"
fxLayoutAlign="center center"> 
    <ng-content ></ng-content>
</button>
<mat-menu class="client-create-menu" #dropDown="matMenu">
    <div class="card-filter" id="card-filter" (click)="$event.stopPropagation()">

        <div class="card-client-create-header form-menu-header">
            <div class="card-client-create-header-title form-menu-header-title">
                Adicionar Unidade

                <div class="form-menu-close-btn">
                    <button mat-icon-button 
                    (click)="(menuTrigger.closeMenu())">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-client-create-content form-menu-content">
            <app-form-unit-create-or-edit 
            [unit]="unit"
            [manuTrigger]="menuTrigger"
            (onCreatedUnit)="created($event)"
            (onUpdatedUnit)="updated($event)"></app-form-unit-create-or-edit>
        </div>

    </div>
</mat-menu>