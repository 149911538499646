import { createSelector } from '@ngrx/store';
import { ProductsImportState } from './products-import.state';

export const selectProductsImportState = (state) => state.productsImport;

export const selectCurrentStep = createSelector(
  selectProductsImportState,
  (state: ProductsImportState) => state.step
);

export const selectIsImportLoading = createSelector(
  selectProductsImportState,
  (state: ProductsImportState) => state.isImportLoading
);