import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationsComponent } from './notifications.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { AvatarModule } from 'ngx-avatar';
import { DirectivesModule } from 'app/directives/directives.module';


@NgModule({
    declarations: [
        NotificationsComponent
    ],
    imports     : [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        FuseSharedModule,
        AvatarModule,
        DirectivesModule
    ],
    exports     : [
        NotificationsComponent
    ],
})
export class NotificationsModule
{
}
