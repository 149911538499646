import { Decodable } from './_decodable';

class FaqCategory extends Decodable {
    id: number
    name: string
    slug: string
    createdAt: Date
    updatedAt: Date
    status: number
    statusFormatted: string
    statusClass: string

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    FaqCategory
}