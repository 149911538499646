import { createFeatureSelector, createSelector } from "@ngrx/store"
import { SubscriptionState } from "./subscription.reducer"

export const getSubscription = createFeatureSelector<SubscriptionState>('subscription')

export const selectSubscription = createSelector(
    getSubscription,
    (state) => state.subscription
)

export const selectFeatureUsages = createSelector(
    getSubscription,
    (state) => state.featureUsages
)

export const selectSubscriptionError = createSelector(
    getSubscription,
    (state) => state.error
)