import { FaqCategory } from './faq-category'
import { Decodable } from './_decodable'

class Faqs extends Decodable {

    id?: number
    slug?: string
    title?: string
    description?: string
    showApp?: boolean
    showHotsite?: boolean
    faqCategory?: FaqCategory
    createdAt?: Date
    customerName?:string



    protected _map = {
        faqCategory: e => FaqCategory.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }

   
}

export {
    Faqs
}