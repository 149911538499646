import {
    Component,
    OnInit,
    Input,
    HostListener,
    HostBinding,
    Output,
    EventEmitter,
    OnDestroy,
} from "@angular/core";
import { of, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Component({
    selector: "app-color-picker",
    templateUrl: "./color-picker.component.html",
    styleUrls: ["./color-picker.component.scss"],
})
export class ColorPickerComponent implements OnInit, OnDestroy {
    constructor() { }

    @Input("type") type: string = "sketch";
    @Input("colors") colors = [
        '#5E4EAE', '#0056CA', '#226F4D', '#206B82', '#815E04', '#B02E21', '#596772',
        '#9F90EF', '#559DFF', '#4BCE98', '#6AC4DF', '#FFA360', '#F96E67', '#8B9BAB'
    ];

    public isVisible: boolean = false;
    public selectedColor = "#559DFF";
    private readonly changeSubject = new Subject<string | undefined>();
    private changeSubscription?: Subscription;

    ngOnInit(): void {
        this.changeSubscription = this.changeSubject
            .pipe(
                // debounceTime(100),
                distinctUntilChanged(),
                switchMap(() => {
                    this.colorChange.emit(this.selectedColor);
                    return of(null);
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.changeSubscription?.unsubscribe();
    }

    @Output()
    public colorChange: EventEmitter<string> = new EventEmitter();

    @Input()
    public set color(color: string) {
        this.selectedColor = color;
    }

    @HostBinding("style.background-color")
    public get background(): string {
        return this.selectedColor;
    }

    @HostListener("click", ["$event"])
    public showColorPicker(event: MouseEvent) {
        if (this.isVisible === true) {
            return;
        }

        this.isVisible = !this.isVisible;
    }

    public overlayClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isVisible = false;
    }

    onColorChanged(event) {
        this.selectedColor = event.color.hex;
        this.changeSubject.next(this.selectedColor);
    }
}
