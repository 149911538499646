import { Decodable } from "./_decodable";

class PaymentPix extends Decodable {
    txid: string;
    qrCode: string;
    pixCopiaECola: string;
    devedorNome: string;
    cpfCnpj: string;
    chave: string;
    valorOriginal: number;
    planPaymentName: string;
    planName: string;
    planPaymentPrice: number;
    nextRenewSubscription: Date;
    periodFormatted: string;
    invoicePeriod: number;
    discount: number;
    interest: number;
    subtotal: number;
    total: number;       
    
    createdAt: Date;
    updatedAt: Date;
    
    protected _map = {
        nextRenewSubscription: (e) => this.parseDate(e),
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
    };
}

export { PaymentPix };
