<div [formGroup]="form" *ngIf="form"
class="form-responsive"
(keydown.tab)="$event.stopPropagation()"
(click)="$event.stopPropagation()">
    <div class="form-group">
        <div fxLayout="column">
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex class="min-width">
                    <mat-label>Nome</mat-label>
                    <input type="text" #nomeInput placeholder="Nome da unidade" matInput 
                    formControlName="name" required>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex class="min-width">
                    <mat-label>Abreviação</mat-label>
                    <input type="text" #nomeInput placeholder="Abraviatura da unidade" matInput
                    formControlName="abbr">
                </mat-form-field>
            </div>
        </div>
        <!-- <div fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="start start">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <orca-switch [isActive]="unit?.status == 1"
                    (toggle)="toggleStatus(unit, $event)"></orca-switch>
                </div>
            </div>
        </div> -->
    </div>


    <div class="form-menu-footer mt-16" fxLayout="row" fxLayoutAlign="end">

        <button *ngIf="!unit?.id" class="btn btn-gray" (click)="close()">Descartar</button>
        <button *ngIf="!unit?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            (click)="store()">Criar Unidade</button>
        <button *ngIf="unit?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            fxLayout="row" fxLayoutAlign="start center" (click)="update()">
            <mat-spinner *ngIf="loading" class="spinner" diameter="20" color="accent"></mat-spinner>
            Salvar Alterações
        </button>

    </div>
</div>