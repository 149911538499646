import { createFeatureSelector, createSelector } from "@ngrx/store"
import { CustomerState } from "./customer.reducer"


export const getCustomer = createFeatureSelector<CustomerState>('customer')

export const selectCustomers = createSelector(
    getCustomer,
    (state) => state
)

export const selectCustomersError = createSelector(
    getCustomer,
    (state) => state.error
)