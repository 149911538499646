<div class="dialog-content-wrapper tutorial-dialog-responsive">
    <div class="tutorial-dialog-header">
        <div (click)="dismissModalOnStorage();" 
        class="close-btn"
        mat-dialog-close
        aria-label="Fechar">
            <mat-icon>close</mat-icon>
    </div>
    </div>
    
    <div mat-dialog-content>
        <h1 class="text-center title-h1">
            Primeiros passos para um orçamento campeão
        </h1>
        <h2 class="title-h2">
            Personalize seu orçamento com a cara da sua empresa
        </h2>

        <p class="content-p">
            Escolha o modelo de sua preferência, adicione sua logo, ajuste as cores dos textos e fundo, preencha os dados da sua empresa e adicione um texto padrão com a suas condições gerais.
        </p>
        <div style="margin: 0 auto;">
            <app-video-card  [videoUrl]="videoUrl"></app-video-card>
        </div>
    </div>
    
    <div class="tutorial-dialog-footer">
        <button class="btn-gradient-round" 
        (click)="dismissModalOnStorage()">
            Configurar Orçamento
        </button>
    </div>
</div>