<div class="video-wrapper" fxLayout="column" [ngClass]="{'show-backdrop': showBackDrop}">
    <video #videoPlayer *ngIf="videoUrl" [controls]="showControls">
        <source [src]="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <div class="play-button" >
        <button mat-icon-button
        matTooltip="Clique para reproduzir o vídeo"
        (click)="playVideo()" *ngIf="!isPlaying">
            <mat-icon>play_arrow</mat-icon>
        </button>
    </div>
</div>