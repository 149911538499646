import { Decodable } from './_decodable'

class Contact extends Decodable {
    clientId: number | null = null
    id: number | null = null
    name:string | null = null
    email:string | null = null
    phone?:string | null = null
    role?:string | null = null
    createdAt: Date | null = null
    updatedAt: Date | null = null

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Contact
}