import { Injectable } from '@angular/core';
import { ApiResponse, ProductCategory } from "app/entities";
import { ApiService } from "app/services/api.service";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProductCategoriesService extends ApiService {

    findAll(params: any) {
        params = this.paramsFromObject(params);
        params.summed_up = false
        return this.httpService.get(`/v1/clients/product-categories`, params)
            .pipe(
                map(res => ApiResponse.parseWith(new ProductCategory, res) as ApiResponse<ProductCategory[]>)
            )
    }

    simpleProductCategoriesAll() {
        return this.httpService.get(`/v1/clients/product-categories/simple-product-categories`, {}).pipe(
            map(res => ApiResponse.parseWith(new ProductCategory, res) as ApiResponse<ProductCategory[]>)
        )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/product-categories`, formData)
            .pipe(
                map(res => ProductCategory.parse(res.data) as ProductCategory)
            )
    }

    update(clientId: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/product-categories/${clientId}`, formData)
            .pipe(
                map(res => ProductCategory.parse(res.data) as ProductCategory)
            )
    }

    destroy(id: number) {
        return this.httpService.delete(`/v1/clients/product-categories/${id}/delete`)
    }

    find(hashId: any) {
        return this.httpService.get(`/v1/clients/product-categories/${hashId}`)
            .pipe(
                map(res => ProductCategory.parse(res.data) as ProductCategory)
            )
    }
}
