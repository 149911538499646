import { Decodable } from "./_decodable";

class Customer extends Decodable { 
    id: number
    account_id: string
    name: string
    email: string
    phone: string
    cpfCnpj: string
    address: string
    statusClass: string
    status: number
    accountId: number
    firstName: string
    image: string
    statusFormatted: string
    roleName: string
    updatedAt: Date
    createdAt: Date
    emailVerifiedAt: Date

    protected _map = {
        emailVerifiedAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Customer
}