<div fxLayout="column" *ngIf="logs$ | async">
    <div *ngFor="let log of (logs$ | async)?.slice(0, showMore ? (logs$ | async)?.length : 10)" fxLayout="row" class="log-container" fxLayoutGap="10px">
        <div class="log-icon-container {{log?.class}}">
            <div class="log-icon">
                <mat-icon>{{log?.icon}}</mat-icon>
            </div>
        </div>

        <div class="log-action">
            <strong>{{log?.customerName}},</strong> {{log?.action}}
            <div class="log-date">
                {{log?.createdAt | date:'dd/MM/Y H:m'}}
            </div>
        </div>
    </div>

    <div fxLayout="column" *ngIf="(logs$ | async)?.length==0">
        Nenhuma atividade até o momento...
    </div>

</div>

<div fxLayout="column" *ngIf="!(logs$ | async)">
    <div fxLayout="row" *ngFor="let item of [1, 2, 3, 4, 5]" class="log-container" fxLayoutGap="10px">
        <div class="log-icon-container">
            <ngx-skeleton-loader count="1"
                [theme]="{ 'border-radius': '50px', height: '40px', width : '40px' }">
            </ngx-skeleton-loader>
        </div>

        <div class="log-action">
            <ngx-skeleton-loader count="1"
                [theme]="{ 'border-radius': '5px', height: '18px', width : '190px' }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '18px', width : '90px' }">
            </ngx-skeleton-loader>

            <div class="log-date">
                <ngx-skeleton-loader count="1"
                    [theme]="{ 'border-radius': '5px', height: '18px', width : '120px' }">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>

</div>


<div fxLayout="row" fxLayoutAlign="center center">
    <button class="btn btn-blue" *ngIf="(logs$ | async)?.length > 10" (click)="showMore = !showMore">
        {{!showMore ? "Ver Mais" : "Ver Menos"}}
    </button> 

    <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '5px', height: '32px', width : '80px' }"
        *ngIf="!(logs$ | async)">
    </ngx-skeleton-loader>
</div>