import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { AccountTag } from 'app/entities/account-tag';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-drop-down-tag-create',
  templateUrl: './card-drop-down-tag-create.component.html',
  styleUrls: ['./card-drop-down-tag-create.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'form-menu' }
    }
  ]
})
export class CardDropDownTagCreateComponent implements OnInit {

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject<AccountTag>();

  menuOpen = false;
  loading = false

  constructor(
    
  ) { }

  ngOnInit(): void {

  }

  open() {
    this.menuTrigger.openMenu(); // Open the menu
    this.menuOpen = true;
  }

  created(tag) {
    this.onCreated.next(tag)
    this.close();
  }

  close() {
    this.menuOpen = false;
    this.menuTrigger.closeMenu()
  }

}
