<div class="avatar-wrapper">
    
    <ngx-avatar *ngIf="editable" [round]="true" initialsSize="2" (click)="fileInput.click()" [name]="_clientName" [src]="_src"></ngx-avatar>
    <ngx-avatar *ngIf="!editable" [round]="true" initialsSize="2" [name]="_clientName" [src]="_src"></ngx-avatar>
    <button *ngIf="excludable" (click)="deleteImage()" color="primary" class="mat-accent mat-icon-button-excludable">
        <mat-icon>delete</mat-icon>
    </button>

    <button *ngIf="editable" (click)="fileInput.click()"  color="primary" class="mat-accent mat-icon-button-editable">
        <mat-icon>edit</mat-icon>
    </button>
</div>
<input #fileInput (change)="fileChange($event)" hidden type="file" name="image" [accept]="accept">