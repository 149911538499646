
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import { BehaviorSubject, Observable } from 'rxjs';

export class TableDataSource<T> implements DataSource<T> {

    protected itemsSubject = new BehaviorSubject<T[]>([]);
    protected totalItemsSubject = new BehaviorSubject<number>(0);
    protected loadingSubject = new BehaviorSubject<boolean>(false);
    protected emptySubject = new BehaviorSubject<boolean>(false);
    protected noRecordsSubject = new BehaviorSubject<boolean>(false);

    public isLoading = this.loadingSubject.asObservable();
    public isEmpty = this.emptySubject.asObservable();
    public totalItems = this.totalItemsSubject.asObservable();
    public noRecords = this.noRecordsSubject.asObservable();

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.itemsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.itemsSubject.complete();
        this.loadingSubject.complete();
    }
}