<div class="dialog-content-wrapper" id="create-client">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ title }}</span>
            <button (click)="matDialogRef.close()" mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0">
        <form [formGroup]="form">
            <p>
                (<span class="mat-placeholder-required">*</span>) Campos obrigatórios
            </p>
            <div fxLayout="row wrap" fxLayoutGap="25px">
                <div fxLayout="column">
                    formulario de produto
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <span>
            <button *ngIf="product && product.id"
                (click)="deleteUser()"
                mat-button
                class="mr-8 btn btn-delete"
                aria-label="Deletar"
                matTooltip="Deletar">
                DELETAR
            </button>
        </span>
            <button *ngIf="product && !product.id" [disabled]="form.invalid || loading" (click)="insertUser()" class="btn btn-create btn-block" aria-label="Adicionar">
                <span>
                    CRIAR productE
                    <mat-spinner *ngIf="loading"
                        style="display: inline-block; margin-left: 5px"
                        diameter="15">
                    </mat-spinner>
                </span>
            </button>
        <span>
            <button *ngIf="product && product.id"
                [disabled]="form.invalid || loading"
                (click)="updateUser()"
                class="btn btn-edit"
                aria-label="SALVAR">
                <span>
                    SALVAR
                    <mat-spinner *ngIf="loading"
                        style="display: inline-block; margin-left: 5px"
                        diameter="15">
                    </mat-spinner>
                </span>
        </button>
        </span>
    </div>
</div>