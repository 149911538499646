import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { selectFeatureUsages, selectSubscription } from 'app/state/subscription/subscription.selectors';
import { FeatureUsage } from 'app/entities';
import { GtagService } from 'app/services/gtag.service';

@Component({
  selector: 'app-usage-counter',
  templateUrl: './usage-counter.component.html',
  styleUrls: ['./usage-counter.component.scss']
})
export class UsageCounterComponent implements OnInit {
  usages: FeatureUsage[] | null = [];
  planName: string = '';
  subscription$ = this.store.select(selectSubscription);

  constructor(
    private store: Store,
    private router: Router,
    private _gtag: GtagService,
  ) { }

  ngOnInit(): void {
    this.store.pipe(
      select(selectFeatureUsages),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr))
    ).subscribe(usages => {
      if (!usages) {
        return;
      }
      this.usages = usages.filter(usage => usage.showFeatureUsage);
    });

    this.store.pipe(
      select(selectSubscription),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr))
    ).subscribe(subscription => {
      this.planName = subscription?.planPayment?.name || '';
    });
  }

  navigateToUpgrade(): void {
    this._gtag.event('change_plan', {
      event_category: 'ecommerce',
      event_label: 'Dispara ao clicar no botão Trocar Plano dentro da página Plano',
    });

    this.router.navigate(['/checkout/planos']);
  }
  
  navigateToRenew() {
    this._gtag.event('plan_renew', {
      event_category: 'ecommerce',
      event_label: 'Dispara ao clicar no botão Renovar Plano dentro da página Plano',
    });
    this.router.navigate(['/planos-de-assinatura/checkout/retry']);
  }

  showUsage(usage: FeatureUsage): boolean {
    let ifFreePlan = usage.isFreePlan;
    let isFeature = usage.type === 'feature';
    
    if (ifFreePlan && !isFeature) { 
      return true;
    }
    
    if (usage.limit === 0 || usage.limit == '*') {
      return false;  
    }

    return true;
  }

  showUpgradeButton(): boolean | undefined {
    let isFreePlan = this.usages?.some(usage => usage.isFreePlan);
    if (isFreePlan) return true;
    return this.usages?.some(usage => usage.isLimited);
  }
}
