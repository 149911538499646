import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { Unit } from 'app/entities/unit';

@Component({
  selector: 'app-card-drop-down-unit-create',
  templateUrl: './card-drop-down-unit-create.component.html',
  styleUrls: ['./card-drop-down-unit-create.component.scss']
})
export class CardDropDownUnitCreateComponent implements OnInit {

  @Input('appearance') appearance: string = 'standard';
  @Input('unit') unit: Unit | null = null;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject<Unit>();
  @Output() onUpdated = new Subject<Unit>();
  @Input() responsiveBtn = false;

  menuOpen = false;
  loading = false

  constructor(
  ) { }

  ngOnInit(): void {

  }

  open() {
    this.menuTrigger.openMenu()
  }

  created(unit: Unit) {
    this.onCreated.next(unit)
    this.close();
  }

  updated(unit: Unit) {
    this.close();
    this.onUpdated.next(unit)
  }

  close() {
    this.menuTrigger.closeMenu()
  }

  getButtonClass() {
    // TODO: Construir diretiva para tratar as classes
    switch (this.appearance) {
      case 'mat-menu-item':
        return 'mat-menu-item mat-focus-indicator';
      default:
        return 'btn btn-green';
    }
  }

}
