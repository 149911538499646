<div [formGroup]="form" *ngIf="form" (keydown.tab)="$event.stopPropagation()">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">

        <label class="label-input" for="input-name">Nome da Tag <span class="required">*</span></label>

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input type="text" #nomeInput placeholder="Nome da Tag" matInput formControlName="name" required>
        </mat-form-field>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="stretch"
        class="form-group">
        <label class="label-input" for="cpf_cnpj">Cor</label>

        <app-color-picker type="twitter" (colorChange)="onColorChanged($event)">
        </app-color-picker>
    </div>

    <div class="form-menu-footer" fxLayout="row" fxLayoutAlign="end">

        <button *ngIf="!tag" class="btn btn-gray" (click)="close()">Descartar</button>
        <button *ngIf="!tag" class="btn btn-blue" [disabled]="loading || !this.form.valid" (click)="store()">Criar
            Tag</button>
        <button *ngIf="tag" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid" fxLayout="row"
            fxLayoutAlign="start center" (click)="update()">
            <mat-spinner *ngIf="loading" class="spinner" diameter="20" color="accent"></mat-spinner>
            Salvar Alterações
        </button>


    </div>
</div>