export class Decodable {

    static parse(obj: any, casted: any = null) {
        if(!casted)
            casted = new this()
        else
            casted = Object.assign(new casted.constructor, casted);

        try {
            if (typeof obj == "string" && obj.length > 1) {
                obj = JSON.parse(obj)
            }
        } catch(e){
            console.error(e, obj)
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => {
                return this.parse(item, casted)
            })
        } else if (obj != null && typeof obj == "object") {
            
            for (var prop in obj) {
                let camelCased = prop.replace(/_([a-z])/g, g => g[1].toUpperCase());

                if (casted['_map'] && casted['_map'][camelCased]) {
                    casted[camelCased] = casted['_map'][camelCased](obj[prop]);
                } else {
                    casted[camelCased] = obj[prop];
                }
            }

            if (casted['afterDecode']) {
                casted['afterDecode']()
            }

            if(casted['_map'])
                delete casted['_map']

            return casted
        }
    }

    mock(): any {
        Object.keys(this).forEach(prop => {
            this[prop] = prop;
        });

        return this
    }

    parseDate(str): Date | null {
        try {
            if(typeof str == "string") {
                if(str.length == 10) {
                    str = `${str} 00:00:00`
                }
                str = str.replace(/ /g, "T")
                let date = new Date(str);
                return date
            }
        } catch {}
        return null
    }

    toString(): string {
        return JSON.stringify(this)
    }

    protected afterDecode() {
        // delete this['_map'];
        // delete this['afterDecode'];
    }
}