<div class="fuse-card auto-width" style="height: 100%;display: flex;
flex-direction: column;
justify-content: space-around;">

    <div class="p-12 pb-0 card-header" fxLayout="row">

        <div class="pr-16 mt-8" fxFlex="100">
            <div class="dashboard-card-title">Orçamentos dos Últimos 6 Meses</div>
        </div>
    </div>
    <div class="chart-container" >

        <div class="chart-header">

            <div class="chart-currency">
                <span>R$</span><strong>{{valueFormatting(totalAproved)}}</strong>
            </div>

            <div class="chart-description">
                Em orçamentos aprovados
            </div>

        </div>

        <div class="chart" style="height: 100%; max-height: 250px;"> 
            <canvas style="height: 100%;" id="canvas-monthy"></canvas>
        </div>
    </div>
</div>