import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import { ScrollbarService } from './scrollbar.service';
@Injectable({
  providedIn: 'root'
})
export class TourService {

  constructor(private _scrollbarService:ScrollbarService) { }

  scrollToElementDelay = 250

  init(options:any=null){
    let intro = introJs()

    intro.setOption("scrollToElement", false);
    intro.setOption("nextLabel", 'Próximo');
    intro.setOption("prevLabel", 'Anterior');
    intro.setOption("doneLabel", 'Entendi!');
    intro.setOption("disableInteraction", false);

    if(options){
        intro.setOptions(options)
    }

    intro.onbeforechange((element)=>{
        let prevStep = intro._options.steps[intro._currentStep-1]
        let currentStep = intro._options.steps[intro._currentStep]
        let nextStep =  intro._options.steps[intro._currentStep+1]

        if(prevStep?.onNext){
            prevStep.onNext(prevStep, currentStep)
        }

        if(currentStep?.onEnter){
            currentStep.onEnter(prevStep,currentStep)
            return new Promise((resolve) => {
                setInterval(resolve, this.scrollToElementDelay);
            });
        }

        if(currentStep?.scrollToElement===false ){
            return
        }
        
        if(element){
            this._scrollbarService.setFeatureStatus(true)
            this._scrollbarService.scrollTo({selector:`#${element.getAttribute('id')}`,offset:-20})
        }

        return new Promise((resolve) => {
            setInterval(resolve, this.scrollToElementDelay);
        });
    })

    intro.onafterchange((element)=>{
        this._scrollbarService.setFeatureStatus(false)
    })

    intro.oncomplete((element)=>{
        this._scrollbarService.setFeatureStatus(true)
    })

    intro.onexit((element)=>{
        this._scrollbarService.setFeatureStatus(true)
    })

    return intro
  }

}
