<div class="meta-dialog-container">
    <div class="meta-dialog-header">
        <div class="meta-dialog-header-title">
            {{meta?.id ? 'Editar Meta' : 'Criar Meta'}}
        </div>
    </div>

    <div class="meta-dialog-content">

        <div class="meta-dialog-content-description">
            As metas de vendas atuam como um guia para que o seu time comercial alcance os objetivos estipulados.
            Defina o valor e o prazo de início e fim.
        </div>

        <form [formGroup]="form" class="meta-dialog-form-content">
            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="stretch"
                class="form-group" *ngIf="users">

                <label class="label-input" for="input-name"  fxFlex="20">Usuário</label>

                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Selecione um usuário</mat-label>
                    <mat-select formControlName="customer_id" required [value]="customerId"
                        [disabled]="meta?.customerId">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let user of users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="stretch"
                class="form-group">
                <label class="label-input" for="input-email" fxFlex="20">Valor</label>


                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Valor da Meta</mat-label>
                    <input matInput type="text" class="hide-number-spinner" currencyMask required
                        [options]="{ prefix: 'R$', thousands: '.', decimal: ',', align: 'left' }"
                        formControlName="value_meta" appScrollOnFocus>

                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="stretch"
                class="form-group">

                <label class="label-input" for="input-phone" fxFlex="20">Data</label>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
                    <mat-form-field appearance="outline">
                        <mat-label>Data de Início</mat-label>
                        <input matInput (focus)="startAtDatePicker.open()" [matDatepicker]="startAtDatePicker"
                            formControlName="start_at" required>
                        <mat-datepicker-toggle matSuffix [for]="startAtDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #startAtDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Data do Fim</mat-label>
                        <input matInput (focus)="endAtDatePicker.open()" [matDatepicker]="endAtDatePicker"
                            formControlName="end_at" required>
                        <mat-datepicker-toggle matSuffix [for]="endAtDatePicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endAtDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div fxLayout="row" fxLayoutAlign="end">

            <button class="btn btn-gray" (click)="matDialogRef.close()">Descartar</button>

            <ng-container>
                <button class="btn btn-blue" (click)="updateMeta()" [disabled]="form?.invalid||loading"
                    *ngIf="meta && meta.id">
                    Atualizar Meta
                </button>
            </ng-container>

            <ng-container>
                <button class="btn btn-blue" (click)="store()" [disabled]="form?.invalid||loading" *ngIf="!meta?.id">
                    Adicionar Meta
                </button>
            </ng-container>

        </div>

    </div>
</div>