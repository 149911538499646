import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Client } from 'app/entities';
import { cpfOrCnpj } from 'app/helpers/validators';
import { ClientsService } from 'app/main/pages/clients/clients.service';
import { AlertService } from 'app/services/alert.service';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-client-create-or-edit',
  templateUrl: './form-client-create-or-edit.component.html',
  styleUrls: ['./form-client-create-or-edit.component.scss']
})
export class FormClientCreateOrEditComponent implements OnInit {

  @Input('manuTrigger') manuTrigger?: MatMenuTrigger
  @Input('client') client?: Client;
  @ViewChild('nomeInput') nomeInput?: any;
  @Output() onCreatedClient = new Subject<Client>();


  loading = false;
  form?: FormGroup

  constructor(
    private _formBuilder: FormBuilder,
    private _httpService: HttpClient,
    private _alertService: AlertService,
    private _clientService: ClientsService,
    private _subscriptionRestrictionService: SubscriptionRestrictionService
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    console.log('form-client-create-or-edit create form');
    
    this.form = this._formBuilder.group({
      name: [this.client?.name, [Validators.required]],
      cpf_cnpj: [this.client?.cpfCnpj, [cpfOrCnpj()]],
      responsible: [this.client?.responsible, []],
      email: [this.client?.email, []],
      phone: [this.client?.phone, []],
      cep: [this.client?.cep, []],
      address: [this.client?.address, []],
      address_number: [this.client?.addressNumber, []],
      address_city: [this.client?.addressCity, []],
      address_state: [this.client?.addressState, []],
      address_neighborhood: [this.client?.addressNeighborhood, []],
      address_complement: [this.client?.addressComplement, []]
    });

    setTimeout(() => {
        this.nomeInput.nativeElement.focus();
      }, 100);
  }

  store() {
    if (this.form?.valid) {
      this.loading = true
      this._clientService.store(this.form?.value).subscribe((res) => {
        this._alertService.success('Cliente criado');
        this.onCreatedClient.next(res)
        this.loading = false
        this.form?.reset()
        this._subscriptionRestrictionService.reloadSubscriptionUsages()
      }, (error) => {
        this._alertService.error(error)
        this.loading = false
      })
    }
  }

  update() {
    if (this.form?.valid && this.client) {
        this.loading = true
      this._clientService.update(this.client?.id, this.form?.value).subscribe((res) => {
        this._alertService.success('Cliente atualizado');
        this.form?.markAsPristine()
        this.loading = false
      }, (error) => {
        this.loading = false
        this._alertService.error(error)
      })
    }
  }

  close() {
    this.form?.reset()
    this.manuTrigger?.closeMenu()
  }

  changeAddressByCep() {
    if (this.form?.value?.cep && this.form?.value?.cep != this.client?.cep) {
      this._httpService.get(`https://viacep.com.br/ws/${this.form?.value?.cep}/json/`).subscribe((res) => {
        let address: any = res;
        this.form?.patchValue({
          address: address.logradouro,
          address_city: address.localidade,
          address_state: address.uf,
          address_complement: address.complemento,
          address_neighborhood: address.bairro
        });
      }, (error) => {
        // this._alertService.error('Erro ao localizar o endeço atravez do CEP')
      })
    }
  }

}
