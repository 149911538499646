import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CreditCardService } from 'app/services/http/credit-card.service';
import { SubscriptionService } from 'app/services/http/subscription.service';

import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { addCreditCard, addCreditCardFailure, addCreditCardSuccess, loadCreditCard, loadCreditCardFailure, loadCreditCardSuccess } from './credit-card.actions';
import { HttpService } from 'app/services/http.service';

@Injectable()
export class CreditCardEffects {

    loadCreditCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCreditCard),
            switchMap(() =>
                this.creditCardService.get().pipe(
                    map((creditCard) => loadCreditCardSuccess({ creditCard })),
                    catchError((error) => of(loadCreditCardFailure({ error }))))
            )
        )
    )

    addCreditCard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addCreditCard),
            exhaustMap(action =>
                this.creditCardService.add(action.creditCard).pipe(
                    map((creditCard) => addCreditCardSuccess({ creditCard })),
                    catchError((error) => of(addCreditCardFailure({ error: this.httpService.formatErrosMessage(error) }))))
            )
        )
    )

    constructor(
        private actions$: Actions,
        private creditCardService: CreditCardService,
        private httpService: HttpService
    ) {}


}