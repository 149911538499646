<div class="dialog-content-wrapper" id="create-client">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ title }}</span>
            <button (click)="matDialogRef.close()" mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0">
        <form [formGroup]="form">
            <p>
                (
                <span class="mat-placeholder-required">*</span> ) Campos obrigatórios
            </p>
            <div fxLayout="row wrap" fxLayoutGap="25px">
                <div *ngIf="mode == 0" fxLayout="column wrap" fxFlex="auto">
                    <app-image-field-avatar [src]="client?.image" (imageSelected)="onAvatarChanged($event)" fxLayoutAlign="center" style="margin-bottom: 25px"></app-image-field-avatar>
                </div>
                <div fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutGap="10px">
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Nome/Razão Social</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                account_circle
                            </mat-icon>
                            <input #clientName autocomplete="off" name="name" formControlName="name" matInput required>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Telefone</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                phone
                            </mat-icon>
                            <input [dropSpecialCharacters]="false" autocomplete="off" name="phone" mask="(00) 00009-0000" formControlName="phone" matInput type="tel">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="10px">
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Email</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                email
                            </mat-icon>
                            <input [readonly]="client?.id" autocomplete="off" name="email" formControlName="email" matInput type="email">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Cpf/Cnpj</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                ballot
                            </mat-icon>
                            <input [dropSpecialCharacters]="false" [mask]="cpfCnpjMask" (keyup)="checkMask($event)" autocomplete="off" name="cpf_cnpj" formControlName="cpf_cnpj" matInput type="tel" name="cpf_cnpj">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="10px">
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Endereço</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                location_on
                            </mat-icon>
                            <input autocomplete="off" name="address" formControlName="address" matInput type="tel">
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="auto">
                            <mat-label>Nome do Responsável</mat-label>
                            <mat-icon matSuffix class="secondary-text">
                                account_box
                            </mat-icon>
                            <input autocomplete="off" name="responsible" formControlName="responsible" matInput type="tel">
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="mode == 0">
                        <h4>Contatos</h4>
                        <div fxLayout="column" fxLayoutGap="10px">
                            <div fxLayout="column" fxFlex="auto">
                                <div *ngFor="
                                        let creds of contacts().controls;
                                        let i = index
                                    " formArrayName="contacts" fxLayout="row wrap" fxLayoutGap="10px">
                                    <ng-container [formGroupName]="i">
                                        <mat-form-field appearance="outline" fxFlex="auto">
                                            <mat-label>Nome do contato</mat-label>
                                            <mat-icon matSuffix class="secondary-text">
                                                contacts
                                            </mat-icon>
                                            <input autocomplete="off" formControlName="name" matInput>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" fxFlex="auto">
                                            <mat-label>Email do contato</mat-label>
                                            <mat-icon matSuffix class="secondary-text">
                                                contact_mail
                                            </mat-icon>
                                            <input autocomplete="off" formControlName="email" matInput type="email">
                                        </mat-form-field>
                                        <mat-divider></mat-divider>
                                    </ng-container>
                                </div>
                            </div>
                            <div fxHide.lt-md>
                                <div fxLayout="row">
                                    <button (click)="addContact()" type="button" mat-fab color="primary" aria-label="Example icon button with a menu icon">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>Birthday</mat-label>
                    <input matInput (focus)="birthdayDatePicker.open()" [matDatepicker]="birthdayDatePicker" name="birthday" formControlName="birthday">
                    <mat-datepicker-toggle matSuffix [for]="birthdayDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #birthdayDatePicker></mat-datepicker>
                </mat-form-field>
            </div> -->
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <span>
            <button *ngIf="client && client.id"
                (click)="deleteUser()"
                mat-button
                class="mr-8 btn btn-delete"
                aria-label="Deletar"
                matTooltip="Deletar">
                DELETAR
            </button>
        </span>
            <button *ngIf="client && !client.id" [disabled]="form.invalid || loading" (click)="insertUser()" class="btn btn-create btn-block" aria-label="Adicionar">
                <span>
                    CRIAR CLIENTE
                    <mat-spinner *ngIf="loading"
                        style="display: inline-block; margin-left: 5px"
                        diameter="15">
                    </mat-spinner>
                </span>
            </button>
        <span>
            <button *ngIf="client && client.id"
                [disabled]="form.invalid || loading"
                (click)="updateUser()"
                class="btn btn-edit"
                aria-label="SALVAR">
                <span>
                    SALVAR
                    <mat-spinner *ngIf="loading"
                        style="display: inline-block; margin-left: 5px"
                        diameter="15">
                    </mat-spinner>
                </span>
        </button>
        </span>
    </div>
</div>