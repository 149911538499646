import { Injectable } from '@angular/core';
import { CreditCard } from 'app/entities/creditcard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class CreditCardService {

    constructor(
        private httpService: HttpService
    ) { }

    get(): Observable<CreditCard> {
        return this.httpService.get('/v1/clients/credit-card').pipe(
            map(res => CreditCard.parse(res.data) as CreditCard)
        );
    }

    add(creditCard: CreditCard): Observable<CreditCard> {

        const creditCardFormatted = {
            card_holder : creditCard.cardHolder,
            card_holder_cpf_cnpj : creditCard.cardHolderCpfCnpj,
            card_number : creditCard.cardNumber,
            card_cvc : creditCard.cardCvv,
            card_expiration : `${creditCard.cardExpirationMonth}/${creditCard.cardExpirationYear}`,
        };

        return this.httpService.post('/v1/clients/credit-card', creditCardFormatted).pipe(
            map(res => CreditCard.parse(res.data) as CreditCard)
        );
    }
}