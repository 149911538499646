<div class="w-100-p m-h-100-p" >
    <div fxLayout="column" fxFlex="1 1 100%" class="p-8" style="padding-top: 2px;">
        <div class="dropzone"  
        [ngClass]="{'idle': state == 'idle', 'success': state == 'success', 'error': state == 'error', 'loading': state == 'loading'}" 
        fxLayout="column" 
        fxLayoutAlign="center center" 
        (drop)="onFileDropped($event)"
            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (click)="fileInput.click()">
            <mat-icon *ngIf="state == 'idle'" class="mb-8"
            class="mb-8"
            >cloud_upload</mat-icon>
            <mat-icon *ngIf="state == 'success'"
            class="mb-8"
            >check_circle</mat-icon>
            <mat-icon *ngIf="state == 'error'" class="mb-8"
            >error</mat-icon>
            <mat-spinner *ngIf="state == 'loading'" 
            class="mb-8" diameter="50"></mat-spinner>

            <p *ngIf="state == 'idle'">
                Arraste e solte o arquivo aqui ou clique para selecionar
            </p>
            <p *ngIf="state == 'success'">
                Arquivo enviado com sucesso!
            </p>
            <p *ngIf="state == 'error'">
                Erro ao importar arquivo! Clique para enviar novamente.
            </p>
            <p *ngIf="state == 'loading'">
                Enviando o arquivo...
            </p>
    
            <span *ngIf="label && !showDetails" class="text-show-details mt-8 text-error-details"
            (click)="toggleDetails($event)">Ver detalhes</span>
            <span *ngIf="label && showDetails" class="text-show-details mt-8 text-error-details"
            (click)="toggleDetails($event)">Ocultar detalhes</span>
      
            <div [hidden]="!showDetails" class="m-8 text-center">
                <p class="text-error-details text-bold">
                    {{label}}
                </p>
            </div>
        </div>
        <input type="file" #fileInput style="display: none;"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            (change)="onFileSelected($event)">
    </div> 
</div>