import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeedbackSettings } from 'app/entities/system-feedback';
import { AlertService } from 'app/services/alert.service';
import { HttpService } from 'app/services/http.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-system-feedback',
  templateUrl: './system-feedback.component.html',
  styleUrls: ['./system-feedback.component.scss'],
})
export class SystemFeedbackComponent implements OnInit {

    public onSuccess: EventEmitter<any> = new EventEmitter<any>();
    public successCallback?: () => any;
  
    form = this._formBuilder.group({
        action_type: [null],
        rating: [null],
        reason: [null],
        message: [null],
    }); 

    loading = false
    initialized = false

  feedbackSettings?: FeedbackSettings;

  constructor(
    private httpService: HttpService, 
    public dialogRef: MatDialogRef<SystemFeedbackComponent>, 
    private _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    ) {
         // apply no padding panel class
         this.dialogRef.addPanelClass('no-padding-dialog-container'); 
    }

  ngOnInit(): void {
    this.getSystemFeedbackSettings(this._data.action_type).subscribe((res) => {
        this.feedbackSettings = res;
        this.setupForm();
        this.initialized = true;
    });
  }

  setupForm() {
    this.form = this._formBuilder.group({
        action_type: [this.feedbackSettings?.actionType],
        rating: [null, this.feedbackSettings?.formSettings.requiredRating ? [Validators.required] : []],
        reason: [null, this.feedbackSettings?.formSettings.requiredReason ? [Validators.required] : []],
        message: [null, this.feedbackSettings?.formSettings.requiredMessage ? [Validators.required] : []],
    });
  }

  async confirm() {
    this.form.updateValueAndValidity()
    if (this.form.invalid) {
        return;
    }
    
    if(this.form.valid) {
        this.loading = true
        this.dialogRef.disableClose = true
        try {
            await this.postSystemFeedback(this.form.value).toPromise()
            if(this.successCallback) {
                await this.successCallback()
                this.onSuccess.emit(true);
            }else{
                this.onSuccess.emit(true);
                this.dialogRef.close(true)
            }
        } catch (error) {
            this.loading = false
            this.dialogRef.disableClose = false
            this._alertService.error(error)
        }
       
    }
  }

  getSystemFeedbackSettings(action_type: number) {
    return this.httpService.get('/v1/clients/system-feedback-settings/' + action_type).pipe(map((res) => FeedbackSettings.parse(res.data) as FeedbackSettings));
  }

  postSystemFeedback(payload) {
    return this.httpService.post('/v1/clients/system-feedback',payload);
  }
}
