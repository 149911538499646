import { Decodable } from "./_decodable";
import { Unit } from "./unit";

class Item extends Decodable {
    id: number;
    ordem: number;
    name: string;
    type: number;
    unitId: number = 1;
    perUnit: number = 0;
    total: number = 0;
    quantity: number = 0;
    shouldSave: boolean;
    productId: number;

    protected _map = {
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
    };

    public static fromDefaultUnit(unit: Unit):Item {
        var item = new Item();
        item.unitId = unit.id;
        item.perUnit = 0;
        item.type = unit.type;
        return item;
    }
}

export { Item };
