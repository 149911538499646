<div class="orca-dialog">
    <div class="dialog-header">
        <h1 class="dialog-title">{{title}}</h1>

        <div class="dialog-btn-close">
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="dialog-content">
        <h4>{{message}}</h4>
    </div>

    <div class="dialog-footer dialog-actions">
        <button *ngIf="showYes" class="btn btn-red" (click)="confirm()" aria-label="Confirmar" matTooltip="Confirmar">
            {{yesText}}
        </button>
        
        <button *ngIf="showNo" class="btn btn-blue" (click)="cancel()" aria-label="Cancelar">
            {{noText}}
        </button>
    </div>
</div>