import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ensureMinValue]'
})
export class EnsureMinValueDirective implements OnInit {

    @Input() ensureMinValue = 1;

    constructor(
        private elementRef: ElementRef,
        private ngControl: NgControl
        ) { }
    ngOnInit() {
               
    }

    clearValueNotInitialize(target){
        target.value = target.value < this.ensureMinValue ? this.ensureMinValue : target.value;
    }

    setValueNotInitialize(target){
        // console.log(target);
        this.ngControl.control?.setValue(target.value < this.ensureMinValue ? this.ensureMinValue : target.value)
        
    }

    @HostListener("focus", ["$event.target"])
    onFocus(target) {
        this.clearValueNotInitialize(target)
    }

    @HostListener("blur", ["$event.target"])
    onBlur(target) {
        this.setValueNotInitialize(target)
    }
}
