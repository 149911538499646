import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { Meta } from 'app/entities';
import { AlertService } from 'app/services/alert.service';
import { GtagService } from 'app/services/gtag.service';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { UserMetaDialogComponent } from '../../user-meta-dialog/user-meta-dialog.component';
import { UserMetaService } from '../../user-meta-dialog/user-meta.service';

@Component({
    selector: 'app-goal-completion',
    templateUrl: './goal-completion.component.html',
    styleUrls: ['./goal-completion.component.scss'],
    animations: fuseAnimations
})
export class GoalCompletionComponent implements OnInit {

    metas?: Meta[]
    @Input('customerId') customerId?: string
    @Input('layout') layout? = 0
    constructor(
        private _metasService: UserMetaService,
        private _matDialog: MatDialog,
        private _subscriptionRestrictionService: SubscriptionRestrictionService,
        private _alertService: AlertService,
        private _gtag: GtagService
    ) { }

    ngOnInit(): void {
        this.loadMetas()
    }
    
    loadMetas(){
        if(this.customerId){
            this._metasService.findAll(this.customerId).subscribe((metas) => {
                this.metas = metas
            }, (err) => {
                this.metas = []
            });
        } else {
            this._metasService.findAllFirstMeta().subscribe((metas) =>{
                this.metas = metas.data;
            })
        }
    }

    openDialogCreateMeta()
    {
        if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("metas")){
            let dialog = this._matDialog.open(UserMetaDialogComponent, {
                panelClass: 'meta-dialog-container',
                data: {
                    customerId : this.customerId
                }
            });

            dialog.afterClosed().subscribe(() => {
                this.loadMetas();
            })
        } 
    }

    editMeta(meta: Meta) {
        let dialog = this._matDialog.open(UserMetaDialogComponent, {
            panelClass: 'meta-dialog-container',
            data: {
                // customerId: this.customerId,
                meta: meta
            },
        });

        

        dialog.afterClosed().subscribe((res) => {
            if (res) {
                this.loadMetas();
            }
        })
    }

    deleteMeta(meta) {
        this._metasService.destroy(meta.id, meta.customerId).subscribe(() => {
            
            this._gtag.event('meta_delete', {
                event_category: 'engagemnt',
                event_label: 'Dispara ao clicar no botão Deletar Meta'
            })
            this.loadMetas();
            this._alertService.success("Meta excluida com sucesso.")
        }, (error) => {
            this._alertService.error(error)
        })
    }

    formatValue(value) {
        return new Intl.NumberFormat().format(value)
    }

    toFixed(value, fixed = 0){
        return Number(value).toFixed(fixed)
    }
}
