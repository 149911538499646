<ng-container *ngIf="isVisible">
    <color-sketch *ngIf="type == 'sketch'" disableAlpha="true" [color]="selectedColor" class="cpicker"
        style="position: absolute; left: 0;" (onChangeComplete)="onColorChanged($event)"></color-sketch>
    
    <color-twitter 
        *ngIf="type == 'twitter'" 
        [triangle]="false" 
        disableAlpha="true" 
        [color]="selectedColor" 
        [colors]="colors"
        class="cpicker"
        style="position: absolute; left: 0;" 
        (onChangeComplete)="onColorChanged($event)">
    </color-twitter>
</ng-container>



<div *ngIf="isVisible" class="overlay" (click)="overlayClick($event)"></div>