import { Component, ElementRef, OnInit, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'skeleton-loader',
  host: {
    'class': 'pulse'
  },
  template: `
    <div class="pulse">
      <div class="card"></div>
    </div>
  `,
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent {

  width: string;
  height: string;
  className: string;

  constructor(
    private host: ElementRef, 
    private view: ViewContainerRef
    // private host: ElementRef<HTMLElement>
    ) { }

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');
  }

}
