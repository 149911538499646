import { Directive, Input, OnInit, ElementRef, ViewContainerRef, TemplateRef, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { RoleRestrictions } from "app/entities";
import { AuthService } from "app/services/auth.service";
import { selectUser } from "app/state/user/user.select";
import { log } from "console";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Directive({
  selector: "[canShow]"
})
export class CanShowDirective implements OnInit, OnDestroy {

  @Input("canShow") module: string = '';
  private _unsubscribeAll: Subject<any>;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private store:Store
    ) {
        this._unsubscribeAll = new Subject();
    }

  ngOnInit() {
    
    this.store.select(selectUser)
      .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(user => {
            if(!user?.roleName) return;
            this.viewContainer.clear();
            const restrictions: string[] = RoleRestrictions[user.roleName] || [];
            if(restrictions.indexOf(this.module) == -1){
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
      })
    
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
}