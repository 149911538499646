import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SwitchComponent } from './switch/switch.component';
import { NgxMaskModule } from 'ngx-mask';
import { ListLogsComponent } from './list-logs/list-logs.component';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    InputComponent,
    SwitchComponent,
    ListLogsComponent
  ],
  imports: [
    CommonModule,
    
    ReactiveFormsModule,
    FlexLayoutModule,

    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    MatIconModule
  ],
  exports: [
    InputComponent,
    SwitchComponent,
    ListLogsComponent
  ]
})
export class OrcaComponentsModule { }
