<div class="feedback-container">
    <div class="card-feedback">

        <div class="card-feedback-header">
            <div class="card-feedback-header-title">
                Suporte Via E-mail
            </div>
        </div>

        <form class="card-feedback-content" [formGroup]="form">
            <div class="card-feedback-content-description">
                Precisa de ajuda ou tem alguma sugestão? Deixe sua mensagem abaixo. A resposta chegará no seu e-mail
                cadastrado na Orca.
            </div>

            <div class="card-feedback-form">
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="start" class="form-group">
                    <label class="label-input" for="input-phone" fxFlex="30">Assunto</label>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Assunto</mat-label>
                        <mat-select formControlName="assunto" required>
                            <mat-option *ngFor="let opt of options" [value]="opt">
                                {{opt.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center"
                    fxLayoutAlign.lt-sm="start" class="form-group">
                    <label class="label-input" for="input-phone" fxFlex="30">Mensagem</label>

                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>Mensagem</mat-label>

                        <textarea name="details" formControlName="descricao" cdkTextareaAutosize cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="10" matInput></textarea>

                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button class="btn btn-gray" (click)="cancelar()">Descartar</button>
                <button class="btn btn-blue" (click)="enviar()" [disabled]="form?.invalid">Enviar Mensagem</button>
            </div>
        </form>

    </div>
</div>