import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Survey, SurveyAnswer } from 'app/entities/nps-survey';
import { AlertService } from 'app/services/alert.service';
import { LocalStorageService } from 'app/services/local-storage.service';
import { NpsService } from 'app/services/nps.service';
import { ReferralDialogComponent } from '../referral-dialog/referral-dialog.component';

@Component({
  selector: 'app-nps-survey',
  templateUrl: './nps-survey.component.html',
  styleUrls: ['./nps-survey.component.scss']
})
export class NpsSurveyComponent implements OnInit {

    elegibleSurvey?: Survey
    scores = [1,2,3,4,5,6,7,8,9,10]
    npsForm:FormGroup
    expanded = false
    chipsQuestion = ""
    answers: SurveyAnswer[] = []
    answerType : number = 0
    shown = false

    loading = false

    constructor(
        private npsService: NpsService,
        private _formBuilder: FormBuilder,
        private _alertService: AlertService,
        private _localService: LocalStorageService,
        private _matDialog: MatDialog,
    ) {

        this.npsForm = this._formBuilder.group({
            score: [null, [Validators.required]],
            answers: [[], [Validators.required]],
            message: [null],
        });

    }

    otherSelectedValidator(){
        this.npsForm.controls.message.clearValidators()
        const selectedAnswers = this.npsForm?.controls?.answers.value;

        const otherSelected = this.answers.filter(asw=>{
            return selectedAnswers.includes(asw.id) && asw.answer=="Outro"
        })

        console.log(otherSelected);
        
    
        // If the condition is met, the dependentControl is required
        if (otherSelected.length>0) {
            this.npsForm.controls.message.setValidators(Validators.required)
        }

        this.npsForm.controls.message.updateValueAndValidity()
      
    }

  ngOnInit(): void {

    this.npsService.getElegibleSurvey().subscribe(survey=>{
        if(survey){
            this.elegibleSurvey = survey
            this.showExpandedIfWeCan()
        }
    })
  }

  showExpandedIfWeCan(){
    let surveyInCoolDown = this._localService.getItem('nps-survey-cooldown')

    if(!surveyInCoolDown){
        this.expanded = true
    }


  }

  toggleVisibility(){
    this.expanded = !this.expanded
    if(!this.expanded){
        this._localService.setItem('nps-survey-cooldown',true,60*3)
    }
  }

  toggleChip(answer:number) {
    const chipsControl = this.npsForm.controls.answers;
    const value = chipsControl.value;

    if (value.includes(answer)) {
      // If the option is already selected, remove it
      const index = value.indexOf(answer);
      if (index >= 0) {
        value.splice(index, 1);
      }
    } else {
      // If the option is not selected, add it
      value.push(answer);
    }

    chipsControl.setValue(value);

    this.otherSelectedValidator()
    
  }

  getAnswers(score){

    this.shown = true

    // set the score
    this.npsForm.controls.score.setValue(score)

    
    if(!score){
        this.answers = [];
    }

    var newAnswerType = 0
    
    if(score > 6 && score < 9){ // neutral answers
        newAnswerType = 2;
    }else if(score > 8){ // promother answers
        newAnswerType = 3
    }else{// detractor answers
        newAnswerType = 1
    }

    if(this.answerType != newAnswerType){ // type changed!

        // reset the answers
        this.npsForm.controls.answers.setValue([])
        
        this.answerType = newAnswerType
        if(this.answerType == 2){ // neutral answers
            this.answers = this.elegibleSurvey!.neutralAnswers
            this.chipsQuestion = this.elegibleSurvey!.neutralQuestion
        }else if(this.answerType == 3){ // return promother answers
            this.answers = this.elegibleSurvey!.promoterAnswers
            this.chipsQuestion = this.elegibleSurvey!.promoterQuestion
        }else if(this.answerType == 1){// return detractor answers
            this.answers = this.elegibleSurvey!.detractorAnswers
            this.chipsQuestion = this.elegibleSurvey!.detractorQuestion
        }
    }

  }

  enviar(){
    this.npsForm.updateValueAndValidity()

    if(this.npsForm.invalid){
        return
    }

    this.loading = true;

    this.npsService.submitSurvey(this.elegibleSurvey!.id, this.npsForm.value).subscribe(response=>{
        this.chipsQuestion = ""
        this.answers = []
        this.expanded = false
        this.shown = false
        this.elegibleSurvey = undefined
        this._alertService.success("Obrigado por responder nossa pesquisa! Vamos trabalhar para melhorar a Orca de acordo com as suas necessidades.")
        if(this.npsForm.controls.score.value > 8){
            this.openReferralDialog()
        }
    }, error=>{
        this.loading = false;
        this._alertService.error(error)
    })


  }

  openReferralDialog() {
      let dialog = this._matDialog.open(ReferralDialogComponent, {
          panelClass: ['form-dialog'],
      });
  }
}
