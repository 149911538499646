import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadFilesDialogComponent } from 'app/main/components/upload-files-dialog/upload-files-dialog.component';
import { AlertService } from 'app/services/alert.service';

@Component({
    selector: 'app-image-field-default',
    templateUrl: './image-field-default.component.html',
    styleUrls: ['./image-field-default.component.scss']
})
export class ImageFieldDefaultComponent implements OnInit {

    @Input() accept = ['image/png', 'image/jpeg']
    @Input() editable = true
    @Input() excludable = false
    @Input() urlDestroy = 'false'
    @Input() resizeToWidth = 512
    @Input() resizeToHeight = 512

    @Output() imageSelected = new EventEmitter();
    @Output() imageDelete = new EventEmitter();

    _src: string = '/assets/images/default.png';
    @Input() set src(value: any) {
        if (value) {
            this._src = value
        } else {
            this._src = '/assets/images/default.png'
        }
    }

    constructor(
        private _alertService: AlertService,
        private _matDialog: MatDialog
    ) { }

    ngOnInit() {

    }

    fileChange(event: any) {
        let dialog = this._matDialog.open(UploadFilesDialogComponent, {
            panelClass: ['form-dialog'],
            data: {
                // title: 'Teste de titúlo',
                // description: 'Descrição do asdlajs aksdj '
                resizeToWidth: this.resizeToWidth,
                resizeToHeight: this.resizeToHeight
            }
        });

        dialog.componentInstance.onImageSaveCalled.subscribe((image) => {
            if (image) {
                if (!this.editable) return;
                this.imageSelected.emit({image:image, dialog:dialog.componentInstance})
            }
        })

    }

    deleteImage(event) {
        this.imageDelete.emit()
    }
}