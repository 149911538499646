import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-contact-add-dialog',
  templateUrl: './client-contact-add-dialog.component.html',
  styleUrls: ['./client-contact-add-dialog.component.scss']
})
export class ClientContactAddDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
