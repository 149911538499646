import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollbarService {

    private scrollEnabledSubject = new Subject<boolean>();

    private scrollToSubject = new Subject<{selector:string, offset:number, speed:number}>();

  // Observable to subscribe to for feature status changes
  scrollEnabled$ = this.scrollEnabledSubject.asObservable();

  scrollTo$ = this.scrollToSubject.asObservable();

  // Method to update feature status
  setFeatureStatus(status: boolean): void {
    this.scrollEnabledSubject.next(status);
  }

  scrollTo(scrollOptions){
    this.scrollToSubject.next({...scrollOptions, speed:100})
  }

  enable(){
    this.setFeatureStatus(true)
  }

  disable(){
    this.setFeatureStatus(false)
  }

  constructor(private router: Router) {
    // Listen for NavigationEnd events and enable the feature by default
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setFeatureStatus(true); // Enable the feature by default
      });
  }

  
}
