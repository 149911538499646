import { Component, OnInit, Output, ViewChild, Input } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { Product } from 'app/entities';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-drop-down-product-create',
  templateUrl: './card-drop-down-product-create.component.html',
  styleUrls: ['./card-drop-down-product-create.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'form-menu' }
    }
  ]
})
export class CardDropDownProductCreateComponent implements OnInit {

  
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject<Product>();
  @Input() responsiveBtn = false;
  @Input('showStatusInput') showStatusInput: boolean = true;
  menuOpen = false;
  loading = false

  constructor(
    private _subscriptionRestrictionService: SubscriptionRestrictionService
  ) { }

  ngOnInit(): void {

  }

  storeClient(){

  }

  newProduct() {
    var featureLimited = this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("produtos-e-servicos")

    if(featureLimited) {
      this.menuTrigger.closeMenu()
    }
    
  }

  open(){
    this.menuTrigger.openMenu()
  }

  created(productCategory) {
    console.log('created',productCategory);
    this.onCreated.next(productCategory)
    this.close();
  }

  close() {
    this.menuTrigger.closeMenu()
  }

}
