import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProposalLog } from 'app/entities/proposal-log';
import { ProposalService } from 'app/main/pages/proposals/proposals.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-proposal-logs-dialog',
    templateUrl: './proposal-logs-dialog.component.html',
    styleUrls: ['./proposal-logs-dialog.component.scss']
})
export class ProposalLogsDialogComponent implements OnInit {

    logs$?: Observable<ProposalLog>
    constructor(
        public matDialogRef: MatDialogRef<ProposalLogsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private proposalService : ProposalService
    ) { }

    ngOnInit(): void {
        this.logs$ = this.proposalService.findLogs(this._data.token);
    }

}
