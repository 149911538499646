import { Decodable } from "./_decodable";
import { Account } from "./account";

class UserSimple extends Decodable {
    id?: number;
    name?: string;
    image?: string;
    phoneNumber?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

class User extends UserSimple {
    email?: string;
    emailHash?: string
    accessToken?: string;
    status?: string;
    statusFormatted?: string;
    statusClass?: string;
    phone?: string;
    cpfCnpj: any;
    address: any;
    roleName!: string;
    roleNamePt?: string;
    lastAcessAt?: Date
    account: Account | null = null;
    referralLink: string | null = null;

    get isAdmin() {
        return this.roleName === "admin";
    }
    
    protected _map = {
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
        lastAcessAt: (e) => this.parseDate(e),
        account: (e) => Account.parse(e),
    };
}

const RoleRestrictions = {
    commercial: ["settings","subscription", "payment"],
    seller: ["settings","subscription","team", "payment"],
}

export { User, UserSimple, RoleRestrictions };
