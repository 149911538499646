import { Decodable } from "./_decodable";

class Card extends Decodable { 
    component!: string
    background!: string
    
    slug!: string
    title!: string
    subtitle?: string
    
    image?: string
    imageLeft?: number
    imageBottom?: number
   
    buttonTitle!: string
    buttonAction?: string
    buttonUrl?: string
    buttonColor!: string
    buttonBackground?: string
    
    event?: CardEvent

    updatedAt!: Date
    createdAt!: Date

    data?: any

    protected _map = {
        event: e => CardEvent.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

class CardEvent extends Decodable{

    name!: string;
    event_category!: string;
    event_label!: string;

}

export {
    Card, CardEvent
}