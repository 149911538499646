import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { UsersService } from 'app/main/pages/users/users.service';
import { AlertService } from 'app/services/alert.service';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-drop-down-user-create',
  templateUrl: './card-drop-down-user-create.component.html',
  styleUrls: ['./card-drop-down-user-create.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'form-menu' }
    }
  ]
})
export class CardDropDownUserCreateComponent implements OnInit {

  @ViewChild('filterMenuTrigger') filterMenuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject();
 
  form?: FormGroup

  menuOpen = false;
  loading = false

  constructor(
    private _formBuilder: FormBuilder,
    private _userService: UsersService,
    private _subscriptionRestrictionService: SubscriptionRestrictionService,
    private _alertService: AlertService
  ) { }

  ngOnInit(): void {

    this.createForm();
  }

  open() {
    if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("usuarios")){
        this.filterMenuTrigger.openMenu(); // Open the menu
        this.menuOpen = true;
    }
}

  createForm() {
    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required,]],
      password: [null, [Validators.required,]],
      password_confirmation: [null, [this.confirmarSenha.bind(this)]],
      roleName: ['commercial', [Validators.required]],
    });
  }

  createCustomer() {
    this.loading = true;

    this._userService.store(this.form?.value).subscribe((res) => {
      this.onCreated.next();
      this.close()
      this.loading = false;
      this._subscriptionRestrictionService.reloadSubscriptionUsages()
    }, (error) => {
      this.loading = false;
      this._alertService.error(error);
    })
  }

  close() {
    this.filterMenuTrigger.closeMenu()
    this.form?.reset()
  }

  confirmarSenha(control: FormControl) {
    if (!control || !control.parent) {
      return null;
    }

    const senha = control.parent.get('password');
    const confirmacaoSenha = control.parent.get('password_confirmation');

    if (!senha || !confirmacaoSenha) {
      return null;
    }

    if (senha.value !== confirmacaoSenha.value) {
      return { senhasDiferentes: true };
    }

    return null;
  }

}
