import { Injectable } from '@angular/core';
import { ApiResponse, Client } from "app/entities";
import { Contact } from 'app/entities/contact';
import { ApiService } from "app/services/api.service";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ClientsService extends ApiService {

    findAll(params: any) {
        params = this.paramsFromObject(params);
        params.summed_up = false
        return this.httpService.get(`/v1/clients/clients`, params)
            .pipe(
                map(res => ApiResponse.parseWith(new Client, res) as ApiResponse<Client[]>)
            )
    }

    find(hashId: any) {
        return this.httpService.get(`/v1/clients/clients/${hashId}`)
            .pipe(
                map(res => Client.parse(res.data) as Client)
            )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/clients`, formData)
            .pipe(
                map(res => Client.parse(res.data) as Client)
            )
    }

    update(clientId: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/clients/${clientId}`, formData)
            .pipe(
                map(res => Client.parse(res.data) as Client)
            )
    }

    destroy(id: number) {
        return this.httpService.delete(`/v1/clients/clients/${id}`)
    }

    findAllClientWithIdAndName() {
        return this.httpService.get(`/v1/clients/clients`, {
            summed_up: true
        })
            .pipe(
                map(res => ApiResponse.parseWith(new Client, res) as ApiResponse<Client[]>)
            )
    }

    changePhoto(clientId, image: File){
        let formData = this.formDataFromObject({image})
        return this.httpService.post(`/v1/clients/clients/${clientId}/photo`, formData)
    }

    deletePhoto(clientId){
        return this.httpService.delete(`/v1/clients/clients/${clientId}/photo`)
    }

    getAllContacs(clientId){
        return this.httpService.get(`/v1/clients/clients/${clientId}/contacts`)
                    .pipe(
                        map(res => Contact.parse(res.data) as Contact[])
                    )
    }

    addContacs(clientId, data){
        return this.httpService.post(`/v1/clients/clients/${clientId}/contacts`, data);
    }

    updateContact(clientId: number, contactId, data: any){
        return this.httpService.put(`/v1/clients/clients/${clientId}/contacts/${contactId}`, data);
    }

    deleteContact(clientId, contactId){
        return this.httpService.delete(`/v1/clients/clients/${clientId}/contacts/${contactId}`);
    }
}
