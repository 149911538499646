<div class="table-title">
    <div class="config-title">
        Importação
    </div>
    <div class="config-description">
        <span *ngIf="(currentStep$ | async) === 1" >
            Importe uma tabela de produtos e serviços em formato XLS ou CSV.
        </span>
        <span *ngIf="(currentStep$ | async) === 2" >
            Mapeie as colunas do arquivo para os campos do sistema. A coluna produto é obrigatória.
        </span>
        <span *ngIf="(currentStep$ | async) === 3" >
            Aguarde enquanto o sistema processa a importação.
        </span>
        <span *ngIf="(currentStep$ | async) === 4" >
            A importação foi concluída.
        </span>
    </div>
</div>
<div class="container" style="width: 100%; background-color: white; border-radius: 8px;">
    <div class="step-component-container" style="width: 100%; align-items: stretch;">
        <div class="steps-container" style="width: 20%;">
            <div *ngFor="let step of steps; let i = index" 
            class="step" 
            [class.current]="(currentStep$ | async) === i+1" 
            [class.completed]="(currentStep$ | async) > i+1">
                <div class="step-number">{{i+1}}</div>
                <div class="step-line" *ngIf="i !== steps.length - 1"></div>
                <span class="step-text">{{step}}</span>
            </div>
        </div>
        <div class="steps-content" style="width: 80%;">
            <div [hidden]="(currentStep$ | async) !== 1"  style="width: 100%; height: 100%;">
                <app-file-input-component *ngIf="(currentStep$ | async) != steps.length" 
                [state]="currentFileState"
                [label]="currentFileLabel"
                (fileSelected)="onFileSelected($event)"></app-file-input-component>
            </div>
            <div class="step-column-selection-content" [hidden]="(currentStep$ | async) !== 2" style="width: 100%;">
                <form [formGroup]="columnsMatchForm" class="columns-header">
                    <mat-form-field 
                    class="column-control" 
                    class="min-width" 
                    appearance="outline" 
                    fxFlex>
                        <mat-label>Código</mat-label>
                        <mat-icon matSuffix class="secondary-text">
                            qr_code
                        </mat-icon>
                        <mat-select formControlName="codigo"  (selectionChange)="updateColumnData('codigo', $event.value)">
                            <mat-option *ngFor="let column of fileColumns" [value]="column">{{column}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field 
                    class="column-control" 
                    class="min-width" 
                    appearance="outline" 
                    fxFlex>
                        <mat-label>Produto</mat-label>
                        <mat-icon matSuffix class="secondary-text">
                            handyman
                        </mat-icon>
                        <mat-select formControlName="nome" 
                        required
                        (selectionChange)="updateColumnData('nome', $event.value)">
                            <mat-option *ngFor="let column of fileColumns" [value]="column">{{column}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field 
                    class="column-control" 
                    class="min-width" 
                    appearance="outline" 
                    fxFlex>
                        <mat-label>Valor</mat-label>
                        <mat-icon matSuffix class="secondary-text">
                            price_change
                        </mat-icon>
                        <mat-select formControlName="valor" (selectionChange)="updateColumnData('valor', $event.value)">
                            <mat-option *ngFor="let column of fileColumns" [value]="column">{{column}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
                <div class="rows columns-rows mt-16" style="display: flex;">
                    <div class="column-control row-content text-ellipsis" #columnControlCodigo>
                        <div *ngFor="let data of codigo" class=" text-ellipsis">{{data}}</div>
                    </div>
                    <div class="column-control row-content text-ellipsis" #columnControlNome>
                        <div *ngFor="let data of nome" class=" text-ellipsis">{{data}}</div>
                    </div>
                    <div class="column-control row-content text-ellipsis" #columnControlValor>
                        <div *ngFor="let data of valor" class=" text-ellipsis">{{data}}</div>
                    </div>
                </div>
            </div>
            <div [hidden]="(currentStep$ | async) !== 3 " 
            class="dotted-container"
            style="width: 100%;">
                <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                    <div class="spinner-container">
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <mat-spinner diameter="50"></mat-spinner>
                            <h3 style="text-align: center; padding: 0; margin: 0;">A importação está sendo processada. Você pode navegar pelo sistema enquanto isso.</h3>
                            <h3 style="text-align: center; padding: 0; margin: 0;">Te avisaremos ao concluir.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div [hidden]="(currentStep$ | async) !== 4" 
            class="dotted-container dotted-success"
            style="width: 100%;">
                <div style="width: 100%; height: 100%; gap:8px; flex-direction: column; display: flex; justify-content: center; align-items: center;">
                    <mat-icon class="font-size-xlg color-success w-60-px h-60-px">check_circle</mat-icon>
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <span class="color-success"><strong>{{createdCount}} produtos criados</strong></span>
                        <span class="color-updated"><strong>{{updatedCount}} produtos atualizados</strong></span>
                        <span class="color-error"><strong>{{errorCount}} erros</strong></span>
                    </div>

                    <span *ngIf="!showDetails" class="text-show-details mt-8"
                    (click)="showDetails = !showDetails">Ver detalhes</span>
                    <span *ngIf="showDetails" class="text-show-details mt-8"
                    (click)="showDetails = !showDetails">Ocultar detalhes</span> 
                    <!-- Detail of import collapser -->
                    <div [hidden]="!showDetails" class="details mt-8">
                        <div style="width: 100%; height: 100%; gap:8px; flex-direction: column; display: flex; justify-content: center; align-items: center;">
                            <ng-container *ngIf="errorMessages.length > 0">
                                <div *ngFor = "let error of errorMessages" class="color-error text-bold">
                                    <span>Em {{error.count}} </span> 
                                    <span *ngIf="error.count > 1">registros: </span>
                                    <span *ngIf="error.count === 1">registro: </span>
                                    <span>{{error.message}}</span>
                                </div>
                            </ng-container>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-container mt-8">
        <button *ngIf="(currentStep$ | async) != steps.length && (currentStep$ | async) != 3"
        mat-button (click)="prev()" 
        [disabled]="(currentStep$ | async) === 1">Anterior</button>
        <button mat-button (click)="next()" 
        *ngIf="(currentStep$ | async) != steps.length"
        [ngClass]="{'btn-light-gray': disabled || loading, 'btn btn-blue': !disabled && !loading}"
        [disabled]="(currentStep$ | async) === steps.length || disabled" 
        style="min-width: 120px; padding: 8px 16px;">
            
        <div fxFlex fxLayout fxLayoutAlign="center center">
            <span *ngIf="!loading">Próximo</span>
            <span *ngIf="loading">Aguarde</span>
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        </div>
           
        </button>
        <button mat-button 
        class="btn btn-blue"
        (click)="navigateToProducts()" 
        *ngIf="(currentStep$ | async) == steps.length">Finalizar</button>
    </div>
</div>
