import { Decodable } from './_decodable';
import { AccountTag } from './account-tag';
import { CreditCard } from './creditcard';
import { Payment } from './payment';
import { PlanSubscription } from './plan-subscription';

class Account extends Decodable {

    static STATUS_ACTIVE = 1;
    static STATUS_EXPIRED = 3;

    id: number
    name: string
    image: string
    logo: string
    profileIcon: string
    status: number
    statusFormatted: string
    statusClass: string
    plan: any
    purchases: any
    layoutProposalId: number;
    createdAt: Date
    updatedAt: Date
    
    
    cpfCnpj
    responsibleId: number   
    legalName: string
    phone: string
    email: string
    address: string
    addressNumber: string
    complemento: string
    cep: string
    city: string
    state: string
    neighborhood: string

    accountLayoutProposalId: number
    setting: any

    subscription: PlanSubscription | null = null;
    creditCard: CreditCard | any;
    tags?: AccountTag[];

    // geter for hasSubscription boolean
    get hasSubscription(): boolean {
        return this.subscription != null && this.subscription.status==Account.STATUS_ACTIVE;
    }

    // geter for subscriptionExpired boolean
    get subscriptionExpired(): boolean {
        return this.subscription != null && this.subscription.status==Account.STATUS_EXPIRED;
    }
    


    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        subscription: (e) => PlanSubscription.parse(e),
        creditCard: (e) => CreditCard.parse(e), 
        tags: (e) => AccountTag.parse(e),
    }

}

export {
    Account
}