import { Injectable } from '@angular/core';
import { Gtag, GtagEvent, GtagPageview } from 'angular-gtag';
import { environment } from "environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GtagService {

    constructor(
        private _gtag: Gtag
      ) { }

    event(action: string, params?: GtagEvent): void{
        if(!environment.production) return;
        
        this._gtag.event(action, params);
    }

    pageview(params?: GtagPageview): void {
        if(!environment.production) return;

        this._gtag.pageview(params);
    }

    config(params: any): void {
        if(!environment.production) return;

        this._gtag.config(params);
    }

    set(params: any): void {
        if(!environment.production) return;

        this._gtag.set(params);
    }
}