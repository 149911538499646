import { Injectable } from '@angular/core';
import { ApiService } from "app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class ContatoFeedbackService extends ApiService {

  store(data: any){

    let formData = this.formDataFromObject(data);
    return this.httpService.post(`/v1/clients/feedback`, formData);
  }
}
