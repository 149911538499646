
import { Account } from "./account";
import { Customer } from "./customer";
import { Payment } from "./payment";

import { Plan } from "./plan";
import { PlanPayment } from "./plan-payment";
import { Decodable } from "./_decodable";
import { FeatureUsage } from "./plan-feature";

class PlanSubscription extends Decodable {
    id: number;
    name: string;
    description: string;
    accountId: number;
    planId: number;
    status: number;
    slug: string;
    timezone: string;
    statusFormatted: string;
    statusClass: string;
    activeSubscriptions: number;
    active: boolean;
    canRefund: boolean;
    statusTootip: string;
    trialEnds: boolean
    trialEndsAt: Date;
    startsAt: Date;
    endsAt: Date;
    cancelsAt: Date;
    canceledAt: Date;
    paymentRetryAt: Date;
    paymentFailedAt: Date;
    createdAt: Date;
    updatedAt: Date;

    planPaymentId?: number
    plan_PaymentInstallmentId?: number

    payment?: Payment;
    payments?: Payment;
    lastPayment: Payment;
    account?: Account;
    planPayment?: PlanPayment;
    featureUsages: FeatureUsage[];
    canRenew: boolean

    protected _map = {
        responsible: (e) => Customer.parse(e),
        planPayment: (e) => PlanPayment.parse(e),
        featureUsages: e => FeatureUsage.parse(e),
        account: (e) => Account.parse(e),
        payment: (e) => Payment.parse(e),
        payments: (e) => Payment.parse(e),
        customers: (e) => Customer.parse(e),
        createdAt: (e) => this.parseDate(e),
        startsAt: (e) => this.parseDate(e),
        trialEndsAt: (e) => this.parseDate(e),
        endsAt: (e) => this.parseDate(e),
        cancelsAt: (e) => this.parseDate(e),
        canceledAt: (e) => this.parseDate(e),
        paymentFailedAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
    };
   
}

export { PlanSubscription };
