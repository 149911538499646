import { Injectable } from '@angular/core';
import { PlanSubscription } from 'app/entities/plan-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { FeatureUsage } from 'app/entities';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(
        private httpService: HttpService
    ) { }

    load(): Observable<PlanSubscription> {
        return this.httpService.get('/v1/clients/subscription').pipe(
            map(res => PlanSubscription.parse(res.data) as PlanSubscription)
        );
    }

    cancel(): Observable<PlanSubscription> {
        return this.httpService.post('/v1/clients/subscription/cancel').pipe(
            map(res => PlanSubscription.parse(res.data) as PlanSubscription)
        );
    }

    getFeatureUsages(): Observable<FeatureUsage[]> {
        return this.httpService.get('/v1/clients/subscription-feature-usages').pipe(
            map(res => FeatureUsage.parse(res.data) as FeatureUsage[])
        );
    }

    getFeatureLimited($featureSlug): Observable<any> {
        return this.httpService.get('/v1/clients/subscription-feature-limited/'+$featureSlug)
    }

}