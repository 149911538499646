import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ProposalEventService {

    private proposalEventSubject$ = new Subject<any>();
    public proposal$ = this.proposalEventSubject$.asObservable()


    initOnNext() {
        this.proposalEventSubject$.next();
    }
}

