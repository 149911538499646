import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Product, ProductCategory } from 'app/entities';
import { Unit } from 'app/entities/unit';
import { ProductCategoriesService } from 'app/main/pages/products/product-category.service';
import { ProductsService } from 'app/main/pages/products/product.service';
import { UnitService } from 'app/main/pages/products/unit.service';
import { AlertService } from 'app/services/alert.service';
import { GtagService } from 'app/services/gtag.service';
import { SingletonsService } from 'app/services/singletons.service';
import { StringFilterByPipe } from 'app/string-filter-by.pipe';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
@Component({
  selector: 'app-form-product-create-or-update',
  templateUrl: './form-product-create-or-update.component.html',
  styleUrls: ['./form-product-create-or-update.component.scss']
})
export class FormProductCreateOrUpdateComponent implements OnInit {
  @Input('manuTrigger') manuTrigger?: MatMenuTrigger
  @Input('product') product: Product = new Product();
  @Input('showImageInput') showImageInput: boolean = true;
  @Input('showStatusInput') showStatusInput: boolean = true;
  @ViewChild('nomeInput') nomeInput?: any;
  @Output() onCreatedProduct = new Subject<Product>();

  public bankFilterCtrl: FormControl = new FormControl();
  public unitFilterCtrl: FormControl = new FormControl();
  productCategoriesFiltered: ReplaySubject<ProductCategory[]> = new ReplaySubject<ProductCategory[]>(1);
  unitsFiltered: ReplaySubject<Unit[]> = new ReplaySubject<Unit[]>(1);
  loading = false;
  form?: FormGroup
  productCategories?: ProductCategory[] = []
  units: Unit[] | undefined = [];
  itemCreated: boolean = false
  private _onDestroy = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _productService: ProductsService,
    private _productCategoryService: ProductCategoriesService,
    private _uniitService: UnitService,
    private _singletonService: SingletonsService,
    private stringFilterByPipe: StringFilterByPipe,
    private _gtagService: GtagService
  ) {
    this.loadProductCategories()
    this.loadUnits()
  }

  ngOnInit(): void {
    if (!this.product?.id) this.product.status = 1

    this.createForm();

    // listen for search field value changes
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProductCategories();
      });

    this.unitFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUnits();
      })
  }

  createForm() {
    this.form = this._formBuilder.group({
      id: [this.product?.id, []],
      product_category_id: [this.product?.productCategoryId, []],
      title: [this.product?.title, [Validators.required]],
      code: [this.product?.code, []],
      image: [this.product?.image, []],
      description: [this.product?.description, []],
      unit_id: [this.product?.unitId, [Validators.required]],
      valor: [this.product?.valor, [Validators.required]],
      status: [this.product?.status ?? 1, []],
    });

    setTimeout(() => {
      this.nomeInput.nativeElement.focus();
    }, 100);
  }

  store() {
    if (this.form?.valid) {
      this.loading = true
      this._productService.store(this.form?.value).subscribe((res) => {
        this._alertService.success('Produto criado');
        this._gtagService.event('product_created', {
          event_category: 'engagemnt',
          event_label: 'Produto criado'
        });
        this.onCreatedProduct.next(res)
        this.itemCreated = true
        this.loading = false
        this.form?.reset()
      }, (error) => {
        this._alertService.error(error)
        this.loading = false
      })
    }
  }

  update() {
    if (this.form?.valid && this.product && this.product.id) {
      this.loading = true
      this._productService.update(this.product.id, this.form?.value).subscribe((res) => {
        this._alertService.success('Produto atualizado');
        this.itemCreated = true
        this.form?.markAsPristine()
        this.loading = false

        this._gtagService.event('product_updated', {
          event_category: 'engagemnt',
          event_label: 'Produto atualizado'
        });
      }, (error) => {
        this.loading = false
        this._alertService.error(error)
      })
    }
  }

  close() {
    this.form?.reset()
    this.manuTrigger?.closeMenu()
  }

  loadProductCategories() {
    this._productCategoryService.simpleProductCategoriesAll().subscribe((response) => {
      this.productCategories = response.data
      this.productCategoriesFiltered.next(this.productCategories?.slice());
    })
  }

  loadUnits() {
    this._uniitService.simpleUnitsAll().subscribe((response) => {
      this.units = response?.data;
      this.unitsFiltered.next(this.units?.slice());
    });
  }

  onImageChanged(event: File) {
    this.form?.get('image')?.setValue(event)
    
  }

  productCategoryCreated(productCategory, productCategorySelect) {
    if (productCategory) {
      productCategorySelect.close();
      this.bankFilterCtrl.reset();
      this.productCategories?.push(productCategory);
      this.productCategoriesFiltered.next(this.productCategories?.slice());
      this.form?.controls.product_category_id.setValue(productCategory.id);

      this._gtagService.event('product_category_created', {
        event_category: 'engagemnt',
        event_label: 'Categoria de produto criada'
      });
    }
  }

  unitCreated(unit, unitSelect) {
    if (unit) {
      unitSelect.close();
      this.bankFilterCtrl.reset();
      this.units?.push(unit);
      this.unitsFiltered.next(this.units?.slice());
      this.form?.controls.unit_id.setValue(unit.id);

      this._gtagService.event('product_unit_created', {
        event_category: 'engagemnt',
        event_label: 'Unidade de produto criada'
      });
    }
  }

  filterProductCategories() {
    if (!this.productCategories) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.productCategoriesFiltered.next(this.productCategories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.productCategoriesFiltered.next(
      this.stringFilterByPipe.transform(this.productCategories, search, "title")
    );
  }

  filterUnits() {
    if (!this.units) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.unitsFiltered.next(this.units.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.unitsFiltered.next(
      this.stringFilterByPipe.transform(this.units, search, "name")
    );
  }

  toggleStatus(product: Product, active: boolean) {
    product.status = active ? 1 : 2
    this.form?.controls.status.setValue(product.status)
  }

}
