import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";

@Component({
    selector: "app-dismissable-block",
    templateUrl: "./dismissable-block.component.html",
    styleUrls: ["./dismissable-block.component.scss"],
})
export class DismissableBlockComponent implements OnInit, AfterContentInit {
    @ContentChild("dismiss", { static: false }) dismissButton: ElementRef<any>;
    @ContentChild("goto", { static: false }) gotoButton: ElementRef<any>;
    @Input("uniqueKey") uniqueKey?: string;

    dismissClicked: Subscription = new Subscription();

    dismiss = false;

    constructor() {}

    ngOnInit(): void {
        if (this.uniqueKey) {
            if (localStorage.getItem(this.uniqueKey!)) {
                this.dismiss = true;
            }
        }
    }

    ngAfterContentInit(): void {
        fromEvent(
            this.dismissButton.nativeElement,
            "click"
        ).subscribe(() => {
            this.dismissBlock()
        });
        fromEvent(
            this.gotoButton.nativeElement,
            "click"
        ).subscribe(() => {
            this.dismissBlock()
        });
    }

    dismissBlock(){
        if (this.uniqueKey) {
            localStorage.setItem(this.uniqueKey!, "set");
        }
        this.dismiss = true;
    }
}
