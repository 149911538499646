import { createAction, props } from "@ngrx/store";
import { User } from "app/entities";
import { Error } from "../AppState";

export enum UserActionTypes {
    loginUser = '[User] Login User',
    loginUserSuccess = '[User] Login User Success',
    logoutUserSuccess = '[User] User logout Success',
    loginUserFailure = '[User] Login User Failure',
    updateUserSuccess = '[User] Update User Success',
}

export const loginUser = createAction(UserActionTypes.loginUser);
export const logoutUserSuccess = createAction(UserActionTypes.logoutUserSuccess);
export const loginUserSuccess = createAction(
    UserActionTypes.loginUserSuccess,
    props<{user: User}>()
);
export const updateUserSuccess = createAction(
    UserActionTypes.updateUserSuccess,
    props<{user: User}>()
);
export const loginUserFailure = createAction(
    UserActionTypes.loginUserFailure,
    props<{ error: Error }>()
);
