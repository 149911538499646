import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'app/services/alert.service';
import { AuthService } from 'app/services/auth.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { CreditCardService } from './credit-card.service';
import { Store } from '@ngrx/store';
import { loadCreditCardSuccess } from 'app/state/credit-card/credit-card.actions';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

    form!: FormGroup;
    cardNumber = "**** **** **** ****"
    cardHolder = "?"
    expiresMonth = "00"
    expiresYear = "00"
    cardCCV = "000"
    disable=true

    constructor(
        private _formBuilder: FormBuilder,
        private _creditCardService: CreditCardService,
        private _alertService: AlertService,
        private store:Store,
        private dialogRef: MatDialogRef<CreditCardComponent>,
        private _authService: AuthService
    ) { }

    ngOnInit(): void {
        this.form = this.createForm();

        this.form.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe(value => {
            this.editCadValue()
        })
    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            card_number: [null, [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
            card_holder: [null, [Validators.required]],
            card_expires_month: [null, [Validators.required]],
            card_expires_year: [null, [Validators.required]],
            card_ccv: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
            card_holder_cpf_cnpj: [null, [Validators.required, Validators.minLength(11)]],
        });
    }

    editCadValue() {
        this.cardNumber = this.form.value.card_number
        this.cardHolder = this.form.value.card_holder
        this.expiresMonth = this.form.value.card_expires_month
        this.expiresYear = this.form.value.card_expires_year
        this.cardCCV = this.form.value.card_ccv
    }

    save() {
        this.disable = true
        let creditCard = {
            card_number: this.form.value.card_number,
            card_holder: this.form.value.card_holder,
            card_expiration: this.form.value.card_expires_month + "/" + "20" + this.form.value.card_expires_year,
            card_cvc: this.form.value.card_ccv,
            card_holder_cpf_cnpj: this.form.value.card_holder_cpf_cnpj,
        };
        this._creditCardService.save(creditCard).subscribe((res) => {
            this.disable = false
            this._alertService.success("Cartão atualizado com sucesso");
            this.store.dispatch(loadCreditCardSuccess({creditCard:res.data!}))
            this.dialogRef.close(res.data);

        }, (error) => {
            if (error.message) {
                this._alertService.error(error.message);
                this.disable = false
            } else {
                this._alertService.error("Erro ao tentar atualizar o cartão de crédito");
                this.disable = false
            }
        })
    }

}
