import { Component, OnInit, Input, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { ChartsService } from '../charts.service';
import { CurrencyPipe } from '@angular/common';
import { ProposalEventService } from 'app/services/proposal.service';
import { Chart, ChartComponentLike } from 'chart.js';
import { isInteger } from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NONE_TYPE } from '@angular/compiler';
import { summaryFileName } from '@angular/compiler/src/aot/util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-monthy-proposals-chart',
    templateUrl: './monthy-proposals-chart.component.html',
    styleUrls: ['./monthy-proposals-chart.component.scss']
})
export class MontlyProposalsChartComponent implements OnInit, OnDestroy {

    @Input('hashId') hashId?: string
    @Input('customerId') customerId?: string

    chart
    totalAproved
    data = []

    constructor(
        private chartsService: ChartsService,
        private _proposalEventService : ProposalEventService,
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) { }

    // component destroy subject
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    ngOnDestroy(): void {
        if(this.chart){
            this.chart.destroy();
        }
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    ngOnInit() {
   
        Chart.register(ChartDataLabels);

        this._proposalEventService.proposal$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.populate()
        })
    }

    ngAfterViewInit(){
        this.populate()
    }

    populate() {
        let params = {}        

        if(this.hashId) {
            params['hashId'] = this.hashId;
        }

        if(this.customerId) {
            params['customerId'] = this.customerId;
        }
                
        this.chartsService.getLastProposalsChart(params).subscribe(res => {
            this.data = res.data

            if(this.chart){
                this.chart.destroy();
            }
            
            let datasets = res.data.chart
            this.totalAproved = res.data.total
            let htmlRef = this.elementRef.nativeElement.querySelector(`#canvas-monthy`);
            this.ngZone.runOutsideAngular(() => {
                this.chart = new Chart(htmlRef, {
                    type: "bar",
                    
                    data: {
                        // labels: labels,
                        datasets: datasets
                    },
                    options: {
                        
                        maintainAspectRatio: false,
                        responsive: true,
                        
                        interaction: {
                            includeInvisible: false
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                stacked: true,
                            },
                            y: {
                                display: false,
                                stacked: true,
                                grace: '50%',
                                beginAtZero: true
                            },
                          },
                        // rotation: -90,
                        // circumference: 180, 
                        locale: 'pt-BR',
                        plugins: {
                            legend: {
                                // position: 'chartArea',
                                display	: false,
                                position: 'bottom',
                                // align: 'start',
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                    let label = context.dataset.label || '';
                                                                            
                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed !== null) {
                                            label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
                                        }
                                        return label;
                                    },
                                }
                            },
                            datalabels: {
                                color: '#676767',
                                font: {
                                    weight: 'bold'
                                },
                                anchor: 'end',
                                align: 'top',
                                formatter: (value, context) => {
                                    const datasetArray = []
                                    context.chart.data.datasets.forEach((dataset) => {
                                        
                                        if(dataset.data[context.dataIndex] != undefined){
                                            datasetArray.push(dataset.data[context.dataIndex]);
                                        }
                                    });
                    
                    
                                    function totalSum(total, datapoint){
                                        if(!datapoint) total;
    
                                        return total + datapoint.y
                                    }
                    
                                    let sum = datasetArray.reduce(totalSum, 0)
    
                                    // console.log(context.datasetIndex, datasetArray.length - 1, sum);
                                    // console.log(datasetArray, context);
                                    
    
                                    if(context.datasetIndex === datasetArray.length - 1){
                                        let total:any = sum / 1000;
                    
                                        if(sum <= 999999){
                                            if(!isInteger(total)){
                                                total = total.toFixed(2)
                                            }
                                            total = total + 'K'
                                        } else {
                                            total = (total / 1000);
                                            if(!isInteger(total)){
                                                total = total.toFixed(2)
                                            }
                                            total = total + 'M';
                                        }
                    
                                        return total;
                                    }                
                    
                                    return ''
                                }
                            },
                        }
                    }
                }); 
            })
           
        })
    }

    valueFormatting(value: number): string {
        if (!value) value = 0
        return (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })).format(value).replace('R$', '')
    }
}
