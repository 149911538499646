import { AfterViewInit, ContentChildren, ContentChild, Component, QueryList, ElementRef, AfterContentInit, OnInit, ViewChild, EventEmitter, Input, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatColumnDef, MatTable } from "@angular/material/table";
import { TableDataSource } from "./table.datasource";

import { debounceTime, distinctUntilChanged, tap, takeUntil, distinct } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, merge, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { NgModel } from '@angular/forms';
import { SortDirection } from '@swimlane/ngx-datatable';

export interface FilterFieldComponent{
    onFilter:Subject<any>
    filters:any
}
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations,
})

export class TableComponent<T> implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  @ViewChild(MatTable, {static: true}) table?: MatTable<T>;
  
  @Input() sort: MatSort =  new MatSort();
  @Input() dataSource?: TableDataSource<T>;
  @Output() onTableUpdate?= new EventEmitter<any>();
  @Output() onSelectedRow?= new EventEmitter<any>();
  @Input() displayedColumns = [];
  @Input() searchFieldPlaceholder = "";
  @Input() searchFieldName = "q";
  @Input() filterFieldName = "filters";
  @Input() pageSizeOptions = [50, 100, 200];
  @Input() pageSize;

  @Input() searchField?: NgModel;
  @Input() filterFieldComponent?: FilterFieldComponent;
  @Input() emptyLabel = "Nenhum registro encontrado";

  @Output("onFilterChange") onFilterChange = new EventEmitter<any>() 
  @Output("onSortChange") onSortChange = new EventEmitter<MatSort>()


  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;

  private _unsubscribeAll: Subject<any>;
  constructor() {
    this._unsubscribeAll = new Subject();
   }

  ngOnInit() {
    if (!this.dataSource) {
      this.dataSource = new TableDataSource<T>();
    }

    this.dataSource.isLoading.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((loading) => {
      window.dispatchEvent(new Event('resize'));
    })
  }

  handlePageEvent(e: PageEvent) {
    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.loadTable()
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table?.addColumnDef(columnDef));
  } 

  ngAfterViewInit() {

    if(this.searchField) {
             
        this.searchField.update.pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(350),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadTable()
        })
      )
        .subscribe();
    }

    if(this.filterFieldComponent){
        this.filterFieldComponent.onFilter.subscribe((filters)=>{
            this.paginator.pageIndex = 0;

            if (this.sort && filters['sort']) {
                let active = filters['sort'].replace(/^-/, '')
                let direction = filters['sort'].startsWith('-') ? SortDirection.desc : SortDirection.asc
        
                this.sort.active = active
                this.sort.direction = direction
                this.sort.sortChange.emit();
        
                this.onSortChange?.emit(this.sort)
            }

            this.loadTable()
        })
    }

    merge(this.sort?.sortChange, this.paginator.page)
      .pipe(
        
        tap(() =>{
          console.table({"sort":this.sort.active,"direction":this.sort.direction})
          console.log("table sort change or paginator page");
          this.loadTable()
          console.table({"sort":this.sort.active,"direction":this.sort.direction})
        })
      )
      .subscribe();

      this.loadTable();
  }

  loadTable() {
    let params: any = {
        page: this.paginator.pageIndex + 1,
        per_page: this.paginator.pageSize,
        filter : {}
    };

    let sort = this.sort?.direction == 'asc' ? this.sort.active : `-${this.sort?.active}`;
    

    if (this.searchField) {
      params.filter[this.searchFieldName] = this.searchField.viewModel;
    }

    if (this.sort?.active) {
      params.sort = sort;
    }

    if(this.filterFieldComponent?.filters){
        params.filter[this.filterFieldName] = this.filterFieldComponent.filters
    }


    if (this.onTableUpdate) {
      this.onFilterChange.emit(params)
      setTimeout(() => {
        this.onTableUpdate?.emit(params)
      })
    }
  }

  onSelectedRowInternal(row) {
    if(this.onSelectedRow) {
      this.onSelectedRow.emit(row)
    }
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }
}
