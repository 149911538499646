import { ComponentFactoryResolver, Directive, Input, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { SkeletonLoaderComponent } from "app/main/components/skeleton-loader/skeleton-loader.component";
import { random } from "lodash";

@Directive({ selector: '[skeleton]' })
export class SkeletonDirective {
  @Input('skeleton') isLoading = false;
  @Input('skeletonRepeat') size = 1;
  @Input('skeletonWidth') width: string;
  @Input('skeletonHeight') height: string;
  @Input('skeletonClassName') className: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private tpl: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.viewContainer.clear();

      if (changes.isLoading.currentValue) {
        const fooComponentFactory = this.componentFactoryResolver.resolveComponentFactory(SkeletonLoaderComponent);
        Array.from({ length: this.size }).forEach(() => {
          const ref = this.viewContainer.createComponent(fooComponentFactory)

          Object.assign(ref.instance, {
            width: this.width === 'rand' ? `${random(30, 90)}%` : this.width,
            height: this.height,
            className: this.className
          })
        })
      } else {
        this.viewContainer.createEmbeddedView(this.tpl);
      }
    }
  }
}
// Exemplo de uso:
// <ng-container *skeleton="true; 
//                          repeat: 5; 
//                          className: 'mb-2'; 
//                          height: '30px'; 
//                          width: 'rand'">