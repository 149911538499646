import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";

import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { navigation } from "app/navigation/navigation";
import { ContatoFeedbackDialogComponent } from "./main/components/contato-feedback/contato-feedback.component";
import { AuthService } from "./services/auth.service";
import { GtagService } from "./services/gtag.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { WebsocketService } from "./services/websocket.service";


@Component({
    selector: "app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    itIsInMaintenance = false

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        public _fuseSplashScreenService: FuseSplashScreenService,
        private _platform: Platform,
        private modalService: MatDialog,
        private _authService: AuthService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private _gtag: GtagService,
        private webSocketService:WebsocketService,
    ) {

        this.matIconRegistry.addSvgIcon(
            `whatsapp`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `../assets/icons/svg/whatsapp.svg`
            )
        );

        this.matIconRegistry.addSvgIcon(
            `dolly`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/dolly.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `dropbox`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/dropbox.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `package-variant`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/package-variant.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `package-variant-closed`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/package-variant-closed.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-check`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-check.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-fast`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-fast.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-delivery`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-delivery.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `truck-error`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/truck-error.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `map-clock-outline`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/map-clock-outline.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `basket-fill`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/basket-fill.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `arrow-split-vertical`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/arrow-split-vertical.svg`
            )
        );
        this.matIconRegistry.addSvgIcon(
            `cart-arrow-down`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                `assets/icons/svg/cart-arrow-down.svg`
            )
        );

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register("main", this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._authService.autoLoginByToken();

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === "boxed") {
                    this.document.body.classList.add("boxed");
                } else {
                    this.document.body.classList.remove("boxed");
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith("theme-")) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this._fuseNavigationService.updateNavigationItem("ajuda-feedback", {
            function: () => {
                const moldaRef = this.modalService.open(
                    ContatoFeedbackDialogComponent,
                    { panelClass: ["form-dialog", "large-form-dialog"] }
                );
                moldaRef.afterClosed().subscribe((result) => {
                    
                });
            },
        });

       

        this._gtag.set({'user_id': `ACCOUNT_ID_${this._authService.currentUser?.account?.id}`}); // Defina o ID de usuário usando o user_id conectado.
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
