import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: "[appScrollOnFocus]",
})
export class ScrollOnFocusDirective {
    constructor(private elementHost: ElementRef) {}

    @HostListener("focus", ["$event.target"])
    onFocus(target): void {
        setTimeout(() => {
            target.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);
    }
}
