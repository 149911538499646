<div [formGroup]="form" *ngIf="form"
(keydown.tab)="$event.stopPropagation()"
(click)="$event.stopPropagation()">
    <div class="form-group">
        <div fxLayout="column">
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field class="min-width" appearance="outline" fxFlex>
                    <mat-label>Nome</mat-label>
                    <input type="text" #nomeInput placeholder="Nome do Produto ou Serviço" matInput
                    formControlName="title" required>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field class="min-width" appearance="outline" fxFlex>
                    <mat-label>Código</mat-label>
                    <input type="text" #nomeInput placeholder="Código do produto" matInput
                    formControlName="code">
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field class="min-width" appearance="outline" fxFlex>
                    <mat-label>
                        Categoria de Produto
                    </mat-label>
                    <mat-icon matSuffix class="secondary-text">
                        category
                    </mat-icon>
                    <mat-select #productCategorySelect formControlName="product_category_id"
                        placeholder="Categoria de Produto"
                        mdInput panelClass="panelResponsive" (opened)="false">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl"
                            [placeholderLabel]="'Buscar...'"
                            [noEntriesFoundLabel]="'Nenhuma categoria de produto encontrado'"
                            style="height: 80px;"
                            name="search"
                            [disableScrollToActiveOnOptionsChanged]="true">
                                <div class="mat-select-search-custom-header-content">
                                    <app-card-drop-down-product-category-create
                                    #productCategoryCreate
                                    class="btn-add-client-inline"
                                    fxLayoutAlign="center center" fxFlex="100"
                                    (onCreated)="productCategoryCreated($event, productCategorySelect)">
                                    <mat-icon>category</mat-icon>
                                    </app-card-drop-down-product-category-create>
                                </div>
                            </ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let productCategory of productCategoriesFiltered | async"
                        [value]="productCategory.id">
                            <span>{{productCategory.title}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <div fxFlex.gt-sm="50" fxFlex="100"
                fxLayout="row wrap" fxLayoutGap="10px">
                    <mat-form-field class="min-width" appearance="outline" fxFlex>
                        <mat-label>
                            Unidade
                        </mat-label>
                        <mat-icon matSuffix class="secondary-text">
                            perm_data_setting
                        </mat-icon>
                        <mat-select #unitSelect formControlName="unit_id"
                            placeholder="unidade"
                            required
                            mdInput panelClass="panelResponsive" (opened)="false">
                            <mat-option>
                                <ngx-mat-select-search [formControl]="unitFilterCtrl"
                                [placeholderLabel]="'Buscar...'"
                                [noEntriesFoundLabel]="'Nenhuma unidade encontrada'"
                                style="height: 80px;"
                                name="search"
                                [disableScrollToActiveOnOptionsChanged]="true">
                                    <div class="mat-select-search-custom-header-content">
                                        <app-card-drop-down-unit-create
                                        #unitCreate
                                        [unit]="product"
                                        class="btn-add-client-inline"
                                        fxLayoutAlign="center center" fxFlex="100"
                                        (onCreated)="unitCreated($event, unitSelect)">
                                        <mat-icon>perm_data_setting</mat-icon>
                                        </app-card-drop-down-unit-create>
                                    </div>
                                </ngx-mat-select-search>
                            </mat-option>

                            <mat-option *ngFor="let unit of unitsFiltered | async"
                            [value]="unit.id">
                            <span *ngIf="unit?.abbr">{{unit.name}} ({{unit.abbr}})</span>
                            <span *ngIf="!unit?.abbr">{{unit.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field class="min-width" appearance="outline" fxFlex>
                    <mat-label>
                        Valor
                    </mat-label>
                    <input 
                    
                    [options]="{
                        prefix: 'R$',
                        thousands:
                            '.',
                        decimal:
                            ',',
                        align: 'left'
                    }" inputmode="numeric" autocomplete="off"
                     currencyMask
                    formControlName="valor" required matInput>
                </mat-form-field>
            </div>
            <div fxLayout="row wrap" fxLayoutGap="10px">
                <mat-form-field class="min-width" appearance="outline" fxFlex>
                    <mat-label>Descrição</mat-label>
                    <textarea name="description" formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="10" matInput></textarea>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" style="margin: 10px 5px">
                <div *ngIf="showStatusInput" fxLayout="row" fxLayoutAlign="start center">
                    <orca-switch [isActive]="product?.status == 1"
                    (toggle)="toggleStatus(product, $event)"></orca-switch>
                </div>
            </div>
            <!-- <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="showImageInput">
                <div>
                    <app-image-field [editable]="true" [src]="product?.image"
                    [itemCreated]="itemCreated"
                    (imageSelected)="onImageChanged($event)"> </app-image-field>
                </div>
            </div> -->
        </div>
    </div>

    <div class="form-menu-footer" fxLayout="row" fxLayoutAlign="end">

        <button *ngIf="!product?.id" class="btn btn-gray" (click)="close()">Descartar</button>
        <button *ngIf="!product?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            (click)="store()">Criar Produto</button>
        <button *ngIf="product?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            fxLayout="row" fxLayoutAlign="start center" (click)="update()">
            <mat-spinner *ngIf="loading" class="spinner" diameter="20" color="accent"></mat-spinner>
            Salvar Alterações
        </button>

    </div>
</div>