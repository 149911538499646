import { createAction, props } from "@ngrx/store";
import { CreditCard } from "app/entities/creditcard";

export enum CreditCardActionTypes {
    loadCreditCard = '[Credit Card] Load Credit Card',
    loadCreditCardSuccess = '[Credit Card] Load Credit Card Success',
    loadCreditCardFailure = '[Credit Card] Load Credit Card Failure',

    addCreditCard = '[Credit Card] Add Credit Card',
    addCreditCardSuccess = '[Credit Card] Add Credit Card Success',
    addCreditCardFailure = '[Credit Card] Add Credit Card Failure',
}

export const loadCreditCard = createAction(CreditCardActionTypes.loadCreditCard);
export const loadCreditCardSuccess = createAction(
    CreditCardActionTypes.loadCreditCardSuccess,
    props<{ creditCard: CreditCard | null }>()
);
export const loadCreditCardFailure = createAction(
    CreditCardActionTypes.loadCreditCardFailure,
    props<{ error: any }>()
);

export const addCreditCard = createAction(
    CreditCardActionTypes.addCreditCard,
    props<{ creditCard: CreditCard }>()
);
export const addCreditCardSuccess = createAction(
    CreditCardActionTypes.addCreditCardSuccess,
    props<{ creditCard: CreditCard }>()
);
export const addCreditCardFailure = createAction(
    CreditCardActionTypes.loadCreditCardFailure,
    props<{ error: any }>()
);