import { createReducer, on } from "@ngrx/store";
import { User } from "app/entities";
import { Error, Status } from "../AppState";
import { loadCustomers, loadCustomersFailure, loadCustomersSuccess } from "./customer.actions";

export interface CustomerState {
    customers: User[] | null,
    error: Error | null;
    status: Status | null;
}


export const initialState: CustomerState = {
    customers: null,
    error: null,
    status: {
        isError: false,
        isFinished: false,
        statusName: 'pending'
    },
};


export const customerReducer = createReducer(
    initialState,
    on(loadCustomers, (state) => ({
        ...state,
        status: {
            isError: false,
            isFinished: false,
            statusName: 'loading'
        },
    })),
    on(loadCustomersSuccess, (state, {customers}) => ({
        ...state,
        customers,
        status: {
            isError: false,
            isFinished: true,
            statusName: 'success'
        },
    })),
    on(loadCustomersFailure, (state, {error}) => ({
        ...state,
        status: {
            isError: true,
            isFinished: true,
            statusName: 'error'
        },
        error
    })),
);