<ng-container *ngIf="steps">
<app-card-info-steps 
    infoTitle="Orçamentos"
    color="#6ad49b"
    infoDescription="Crie, envie e gerencie seus orçamentos e em um só lugar."
    [showChart]="steps.has_first_proposal"
    [steps]="steps"
    >

    <ng-container *ngIf="!steps.has_first_proposal;  else nextsteps">
        <button fxFlex fxLayoutAlign="start center" style="margin-right: 5px;" (click)="newProposal()" class="btn btn-green" fxLayout="row">
            <mat-icon>playlist_add_circle</mat-icon>
            <span class="pl-4" fxFlex fxLayoutAlign="center center">Crie seu 1º Orçamento</span>
        </button>
    </ng-container>
        <ng-template  #nextsteps>

            <app-config-step
                title="Crie seu primeiro orçamento" 
                color="#6ad49b"
                [isComplete]="steps.has_first_proposal">
            </app-config-step>

        </ng-template>

            <app-config-step 
                title="Envie o orçamento para seu cliente" 
                key="has_sent_proposal"
                (onAction)="doAction($event)"
                color="#6ad49b"
                [isComplete]="steps.has_sent_proposal">
            </app-config-step>

            <app-config-step 
                title="Aprove um orçamento" 
                key="has_approved_proposal"
                (onAction)="doAction($event)"
                color="#6ad49b"
                [isComplete]="steps.has_approved_proposal">
            </app-config-step>

            <app-config-step 
                title="Fature um orçamento" 
                key="has_billed_proposal"
                (onAction)="doAction($event)"
                color="#6ad49b"
                [isComplete]="steps.has_billed_proposal">
            </app-config-step>

            <app-config-step 
                title="Marque um orçamento como pago" 
                key="has_paid_proposal"
                (onAction)="doAction($event)"
                color="#6ad49b"
                [isComplete]="steps.has_paid_proposal">
            </app-config-step>


    
   

    
</app-card-info-steps>
</ng-container>
