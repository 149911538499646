<ng-container *ngIf="steps">
    <app-card-info-steps 
        infoTitle="Configure seu orçamento"
        infoDescription="Personalize seu orçamento com a sua logo, cores e dados da sua empresa."
        [steps]="steps"
        >

        <app-config-step 
            title="Adicione sua logo" 
            key="has_logo"
            [isComplete]="steps.has_logo" 
            skipTitle="Pular"
            (onSkip)="skipSteps($event)"
            (onAction)="onItemClick($event)">
        </app-config-step>


        <app-config-step 
            title="Ajuste as cores" 
            key="has_layout_colors"
            [isComplete]="steps.has_layout_colors" 
            skipTitle="Pular"
            (onSkip)="skipSteps($event)"
            (onAction)="onItemClick($event)">
        </app-config-step>


        <app-config-step 
            title="Complete os dados da sua empresa" 
            key="has_enterprise_data"
            [isComplete]="steps.has_enterprise_data" 
            skipTitle="Pular"
            (onSkip)="skipSteps($event)"
            (onAction)="onItemClick($event)">
        </app-config-step>

        <app-config-step 
            title="Adicione o endereço da sua empresa" 
            key="has_address"
            [isComplete]="steps.has_address" 
            skipTitle="Pular"
            (onSkip)="skipSteps($event)"
            (onAction)="onItemClick($event)">
        </app-config-step>


        <app-config-step 
            title="Adicione as Condições Gerais" 
            key="has_terms_and_conditions"
            [isComplete]="steps.has_terms_and_conditions" 
            skipTitle="Pular"
            (onSkip)="skipSteps($event)"
            (onAction)="onItemClick($event)">
        </app-config-step>

    </app-card-info-steps>
</ng-container>
