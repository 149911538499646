import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { DirectivesModule } from "app/directives/directives.module";
import { TableModule } from "app/layout/components/table/table.module";
import { LayoutModule } from "app/layout/layout.module";
import { PipesModule } from "app/pipes/pipes.module";
import { AvatarModule } from "ngx-avatar";
import { ColorSketchModule } from "ngx-color/sketch";
import { ColorCircleModule } from 'ngx-color/circle'; // <color-circle></color-circle>
import { ColorTwitterModule } from 'ngx-color/twitter'; // <color-twitter></color-twitter>
import { NgxCurrencyModule } from "ngx-currency";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxMaskModule } from "ngx-mask";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { ConfirmActionDialogComponent } from "./confirm-action-dialog/confirm-action-dialog.component";
import { ConfirmPasswordDialogComponent } from "./confirm-password-dialog/confirm-password-dialog.component";
import { ContatoFeedbackDialogComponent } from "./contato-feedback/contato-feedback.component";
import { CreateClientDialogComponent } from "./create-client-dialog/create-client-dialog.component";
import { CreditCardComponent } from "./credit-card/credit-card.component";
import { PreviewProposalDialogComponent } from "./proposal/preview-proposal-dialog/preview-proposal-dialog.component";
import { ProposalLogsDialogComponent } from "./proposal/proposal-logs-dialog/proposal-logs-dialog.component";
import { ProposalsListComponent } from "./proposal/proposals-list/proposals-list.component";
import { UploadFilesDialogComponent } from "./upload-files-dialog/upload-files-dialog.component";
import { ReferralDialogComponent } from "./referral-dialog/referral-dialog.component";
import { UserMetaDialogComponent } from "./user-meta-dialog/user-meta-dialog.component";
// import { ChartsModule } from "ng2-charts";
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { OrcaComponentsModule } from "../orca-components/orca-components.module";
import { CardDropDownClientCreateComponent } from './card-drop-down-client-create/card-drop-down-client-create.component';
import { CardDropDownUserCreateComponent } from './card-drop-down-user-create/card-drop-down-user-create.component';
import { GoalCompletionComponent } from "./charts/goal-completion/goal-completion.component";
import { MontlyProposalsChartComponent } from "./charts/monthy-proposals-chart/monthy-proposals-chart.component";
import { StatusProposalsDoughnutChartComponent } from "./charts/status-proposals-doughnut-chart/status-proposals-doughnut-chart.component";
import { ClientContactAddDialogComponent } from './client-contact-add-dialog/client-contact-add-dialog.component';
import { DismissableBlockComponent } from "./dismissable-block/dismissable-block.component";
import { FormClientCreateOrEditComponent } from './form-client-create-or-edit/form-client-create-or-edit.component';
import { LabelFeatureTablePlansComponent } from "./label-feature-table-plans/label-feature-table-plans.component";
import { PlanCardComponent } from "./plan-card/plan-card.component";
import { ProposalActionsComponent } from "./proposal/proposal-actions/proposal-actions.component";
import { ProposalAddItemComponent } from "./proposal/proposal-add-item/proposal-add-item.component";
import { ProposalSendDialogComponent } from "./proposal/proposal-send-dialog/proposal-send-dialog.component";
import { ProposalsFilterComponent } from './proposal/proposals-filter/proposals-filter.component';
import { SkeletonLoaderComponent } from "./skeleton-loader/skeleton-loader.component";
import { SubscriptionRestrictionDialogComponent } from "./subscription-restriction-dialog/subscription-restriction-dialog.component";
import { SystemFeedbackComponent } from './system-feedback/system-feedback.component';
import { CreateProductDialogComponent } from './create-product-dialog/create-product-dialog.component';
import { CardDropDownProductCreateComponent } from './card-drop-down-product-create/card-drop-down-product-create.component';
import { FormProductCreateOrUpdateComponent } from './form-product-create-or-update/form-product-create-or-update.component';
import { CardDropDownProductCategoryCreateComponent } from './card-drop-down-product-category-create/card-drop-down-product-category-create.component';
import { FormProductCategoryCreateOrEditComponent } from './form-product-category-create-or-edit/form-product-category-create-or-edit.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MultiStepComponentComponent } from './multi-step-component/multi-step-component.component';
import { FileInputComponentComponent } from './file-input-component/file-input-component.component';
import { CardDropDownUnitCreateComponent } from './card-drop-down-unit-create/card-drop-down-unit-create.component';
import { FormUnitCreateOrEditComponent } from "./form-unit-create-or-edit/form-unit-create-or-edit.component";
import { MatSortModule } from "@angular/material/sort";
import { UsummernoteComponent } from './usummernote/usummernote.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CustomSnackbarNotificationComponent } from './custom-snackbar-notification/custom-snackbar-notification.component';
import { NpsSurveyComponent } from './nps-survey/nps-survey.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { UsageCounterModule } from "./usage-counter/usage-counter.module";
import { CardAccountSettingsComponent } from "./card-account-settings/card-account-settings.component";
import { VideoCardComponent } from './video-card/video-card.component';
import { CardAccountFirstProposalsComponent } from "./card-account-first-proposals/card-account-first-proposals.component";
import { ProposalAppearenceTutorialModalComponent } from './proposal/proposal-appearence-tutorial-modal/proposal-appearence-tutorial-modal.component';
import { ConfigStepComponent } from './config-step/config-step.component';
import { CardInfoStepsComponent } from './card-info-steps/card-info-steps.component';
import { CardDropDownTagCreateComponent } from "./card-drop-down-tag-create/card-drop-down-tag-create.component";
import { FormTagCreateOrEditComponent } from "./form-tag-create-or-edit/form-tag-create-or-edit.component";
import { ProposalInformationComponent } from './proposal/proposal-information/proposal-information.component';

@NgModule({
    declarations: [
        PreviewProposalDialogComponent,
        ConfirmActionDialogComponent,
        ConfirmPasswordDialogComponent,
        ProposalsListComponent,
        CreateClientDialogComponent,
        ColorPickerComponent,
        ContatoFeedbackDialogComponent,
        CreditCardComponent,
        UserMetaDialogComponent,
        ProposalLogsDialogComponent,
        UploadFilesDialogComponent,
        ReferralDialogComponent,
        MontlyProposalsChartComponent,
        StatusProposalsDoughnutChartComponent,
        GoalCompletionComponent,
        ProposalActionsComponent,
        PlanCardComponent,
        LabelFeatureTablePlansComponent,
        SkeletonLoaderComponent,
        ProposalSendDialogComponent,
        ProposalAddItemComponent,
        DismissableBlockComponent,
        ProposalsFilterComponent,
        ClientContactAddDialogComponent,
        CardDropDownUserCreateComponent,
        CardDropDownClientCreateComponent,
        FormClientCreateOrEditComponent,
        FormTagCreateOrEditComponent,
        SystemFeedbackComponent,
        CreateProductDialogComponent,
        CardDropDownProductCreateComponent,
        CardDropDownTagCreateComponent,
        FormProductCreateOrUpdateComponent,
        CardDropDownProductCategoryCreateComponent,
        FormProductCategoryCreateOrEditComponent,
        MultiStepComponentComponent,
        FileInputComponentComponent,
        FormUnitCreateOrEditComponent,
        CardDropDownUnitCreateComponent,
        SubscriptionRestrictionDialogComponent,
        UsummernoteComponent,
        CustomSnackbarNotificationComponent,
        NpsSurveyComponent,
        CardAccountSettingsComponent,
        CardAccountFirstProposalsComponent,
        VideoCardComponent,
        ProposalAppearenceTutorialModalComponent,
        ConfigStepComponent,
        CardInfoStepsComponent,
        ProposalInformationComponent,
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatTabsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatStepperModule,
        MatChipsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatMenuModule,
        LayoutModule,
        MatCardModule,
        NgxMaskModule.forRoot(),
        AvatarModule,
        ColorSketchModule,
        ColorCircleModule,
        ColorTwitterModule,
        DirectivesModule,
        PipesModule,
        NgxCurrencyModule,
        ImageCropperModule,
        NgxMatSelectSearchModule,
        TableModule,
        // ChartsModule,
        RouterModule,
        NgxChartsModule,
        MatProgressBarModule,
        OrcaComponentsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
    ],
    exports: [
        ConfirmPasswordDialogComponent,
        ProposalsListComponent,
        CreateClientDialogComponent,
        ColorPickerComponent,
        CreditCardComponent,
        MontlyProposalsChartComponent,
        StatusProposalsDoughnutChartComponent,
        GoalCompletionComponent,
        ProposalActionsComponent,
        PlanCardComponent,
        LabelFeatureTablePlansComponent,
        DismissableBlockComponent,
        ProposalsFilterComponent,
        CardDropDownUserCreateComponent,
        CardDropDownClientCreateComponent,
        FormClientCreateOrEditComponent,
        FormTagCreateOrEditComponent,
        CardDropDownProductCreateComponent,
        CardDropDownTagCreateComponent,
        CardDropDownProductCategoryCreateComponent,
        CardDropDownUnitCreateComponent,
        FormProductCreateOrUpdateComponent,
        FileInputComponentComponent,
        SubscriptionRestrictionDialogComponent,
        UsummernoteComponent,
        NpsSurveyComponent,
        CardAccountSettingsComponent,
        CardAccountFirstProposalsComponent,
        VideoCardComponent,
        ProposalAppearenceTutorialModalComponent,
        ProposalInformationComponent,
    ],
    entryComponents: [
        PreviewProposalDialogComponent,
        ConfirmActionDialogComponent,
        ConfirmPasswordDialogComponent,
        CreateClientDialogComponent,
        CreateProductDialogComponent,
        ContatoFeedbackDialogComponent,
        UserMetaDialogComponent,
    ],
})
export class ComponentsModule {}
