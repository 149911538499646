<div class="steps" (click)="act($event)"  [ngClass]="{'has_action':key, 'active': !isComplete }">
    <div class="step-info-wrapper">
        <div class="verification" [ngClass]="{ 'verified': isComplete }" [ngStyle]="{'background-color':isComplete?color:incompleteColor, 'border-color':isComplete?color:incompleteColor}">
            <mat-icon class="check-icon">check</mat-icon>
        </div>
        <span class="step-label">
            {{title}}
        </span>
    </div>
    <div class="actions-wrap" *ngIf="!isComplete">
        <button *ngIf="skipTitle" class="skip-button" (click)="skip($event)">{{skipTitle}}</button>
    </div>
</div>