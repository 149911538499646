import { Decodable } from "./_decodable";

class AccountTag extends Decodable {
    
    id?: number;
    name?: String;
    hexadecimal_color?: String;
    
}

export { AccountTag };
