import { Injectable } from '@angular/core';
import { ApiResponse } from 'app/entities';
import { Card } from 'app/entities/card';
import { ApiService } from "app/services/api.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends ApiService {

  getLatestActivity(params: any) {
    params = this.paramsFromObject(params);
    return this.httpService.get(`/v1/admin/timeline`, params)
      
  }

  getCards(params) {
    return this.httpService.get(`/v1/clients/dashboard/cards`, params)
          .pipe(
            map(res => ApiResponse.parseWith(new Card, res) as ApiResponse<Card[]>)
          )
  }

  
}
