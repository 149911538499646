import { Decodable } from './_decodable';

enum SystemFeedbackActionType {
    CANCEL_SUBSCRIPTION = 1,
}

class FeedbackSettings extends Decodable {
    actionType!: number
    action!: string
    actionButton!: string
    description!: string
    formSettings!: FeedbackFormSettings
    reasons!: FeedbackReasons[]

    protected _map = {
        formSettings: e => FeedbackFormSettings.parse(e),
        reasons: e => FeedbackReasons.parse(e),
    }
}

class FeedbackFormSettings extends Decodable {
    hasRating!: boolean
    hasReasons!: boolean
    hasMessage!: boolean
    requiredRating!: boolean
    requiredReason!: boolean
    requiredMessage!: boolean
}

class FeedbackReasons extends Decodable {
    actionReason!: number
    label!: string
}

export {
    FeedbackSettings,
    FeedbackFormSettings,
    FeedbackReasons,
    SystemFeedbackActionType,
}