
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 

export function cpfOrCnpj(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control?.value?.replace(/[^0-9]/g, "");

    if(!value || value?.length == 0){
      return null;
    } else if(value?.length == 11 && cpf.isValid(value)){
        return null;
    } else if(value?.length == 14 && cnpj.isValid(value)) {
        return null;
    } else {
        return { 'cpfOrCnpj': true };
    }
  };
}