<div class="dialog-content-wrapper preview-dialog" fxLayout="column" fxFlex="1 1 100%">
    <mat-toolbar matDialogTitle class="m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Visualização
            </span>

            <button (click)="matDialogRef.close()" mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div fxLayout="column" fxFlex="1 1 100%" style="padding-top: 2px;">
        <!-- <app-dismissable-block uniqueKey="proposal-apearance-main" *canShow="'settings'">
            <mat-card-title>
                Personalizar aparência do orçamento
            </mat-card-title>
            <mat-card-content>
                Você pode personalizar sua logomarca, imagens, cores, e também dados de contato e informações gerais
                da sua empresa clicando no botão abaixo ou acessando o menu:
                <div class="explainer-wrap">
                    <span class="menu-explainer">
                        <mat-icon>color_lens</mat-icon>
                        Aparência da Orçamento
                    </span>
                </div>
            </mat-card-content>
            <mat-card-actions class="text-right">
                <button #dismiss class="btn btn-gray">
                    Fechar
                </button>
                <button #goto (click)="redirectTo(['/configuracao/proposta']);" class="btn btn-blue">
                    Aparência do Orçamento
                </button>
            </mat-card-actions>
        </app-dismissable-block> -->
        <div *ngIf="loading" fxLayoutAlign="center center" style="height: 100%;">
            <mat-spinner color="primary" diameter="150"></mat-spinner>
        </div>

        <iframe src="about:blank" #contentIframe id="proposal-frame" width="100%" style="transition: opacity 500ms;"
            [ngStyle]="{'height':loading?'0px':'100%','opacity':loading?'0':'1'}" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture background-color: #fff;"
            allowfullscreen></iframe>
    </div>

    <div mat-dialog-actions class="m-0 p-16 border-top" fxLayout="row" fxLayoutAlign="end center">

        <a *ngIf="data?.token" (click)="openPDF()"  class="btn btn-blue" fxLayoutAlign="center center">
            <mat-icon>picture_as_pdf</mat-icon>
            Gerar PDF
        </a>



        <button (click)="print()" class="btn btn-green" fxLayoutAlign="center center">
            <mat-icon>print</mat-icon>
            Imprimir
        </button>


        <button (click)="matDialogRef.close()" class="btn btn-red">
            Fechar
        </button>

    </div>
</div>