import { createAction, props } from '@ngrx/store';

export const incrementStep = createAction('[Products Import] Increment Step');
export const decrementStep = createAction('[Products Import] Decrement Step');
export const setStep = createAction(
  '[Products Import] Set Step',
  props<{ step: number }>()
);
export const setIsImportLoading = createAction(
    '[Products Import] Set Is Import Loading',
    props<{ isImportLoading: boolean }>()
    );
    