import { Inject, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'app/services/alert.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-upload-files-dialog',
    templateUrl: './upload-files-dialog.component.html',
    styleUrls: ['./upload-files-dialog.component.scss']
})
export class UploadFilesDialogComponent implements OnInit {

    imageChangedEvent: any = '';
    croppedImage: any = '';
    disbled = false;
    borderBlue = false;
    @Input() resizeToWidth
    @Input() resizeToHeight
    image?: File
    public onImageSaveCalled = new Subject<any>()
    public loading = false;

    title = 'Upload de imagem'
    description = 'Selecione uma imagem. Araste ou click aqui'
    constructor(
        private _alertService: AlertService,
        public dialogRef: MatDialogRef<UploadFilesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
         // apply no padding panel class
         this.dialogRef.addPanelClass('no-padding-dialog-container'); 
    }

    ngOnInit(): void {
        if(this.data?.title){
            this.title = this.data?.title
        }

        if(this.data?.description){
            this.description = this.data?.description
        }

        if(this.data?.resizeToWidth){
            this.resizeToWidth = this.data?.resizeToWidth
        }

        if(this.data?.resizeToHeight){
            this.resizeToHeight = this.data?.resizeToHeight
        }
    }


    allowDrop(e) {
        // console.log(e);
        this.borderBlue = true;
    }
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        this.disbled = true;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.image = this.base64ToFile(this.croppedImage, 'image')
    }

    imageLoaded(image: LoadedImage) {
        
    }
    
    cropperReady() {
        // cropper ready
    }
    loadImageFailed($event) {
        this.disbled = false;
        // show message
        // this.image = undefined;
        // this.imageChangedEvent = undefined
        this._alertService.error('Erro ao carregar imagem. Somente imagens JPEG, PNG, e JPG são permitidas.')
    }

    base64ToFile(data, filename) {

        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    close() {
        this.dialogRef.close();
    }

    save(){
        this.loading = true;
        this.onImageSaveCalled.next(this.image)
        // this.dialogRef.close({
        //     image: this.image
        // });
    }
}
