<div class="fuse-card auto-width p-32" style="width: 100%;">

    <div class="card-info-body">
        <div class="main-info-wrapper">
               
            <div class="info-wrapper">
                <div *ngIf="showChart" class="doughnut-chart">
                    <ngx-charts-pie-chart
                    [scheme]="colorScheme"
                    [results]="series"
                    [legend]="false"
                    [labels]="false"
                    [doughnut]="true"
                    [animations]="false"
                    [margins]="[0,0,0,0]"
                    >
                    </ngx-charts-pie-chart>
                    <div class="doughnut-chart-counter">
                        {{ counts.trueCount }}/{{ counts.falseCount + counts.trueCount }}
                    </div>
                </div>
                <div class="description">
                    <div class="description-header">
                        {{infoTitle}}
                    </div>
                    <div class="description-body">
                        {{infoDescription}}
                    </div>
                </div>
            </div>

            <div class="steps-wrapper">

                <ng-content [color]="color"></ng-content>

            </div>
        </div>
    </div>
</div>