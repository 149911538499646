<div class="dialog-container">
    <div class="close-btn" (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
    </div>
    <div class="dialog-title" [innerHTML]="getSanitizedHtml(dialogData.title)">
    </div>
    <div class="dialog-subtitle" [innerHTML]="getSanitizedHtml(dialogData.subtitle)">
    </div>
    <div class="dialog-description" [innerHTML]="getSanitizedHtml(dialogData.description)">
    </div>
    
    <div *ngIf="dialogData.showImage" class="dialog-content" fxLayoutAlign="center">
        <img style="height: 150px;" src="https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/meLeoxFrsDCMUactidHk7A1pBqYHr9lRlEoHHfTm.png" alt="">
    </div>
    <div *ngIf="dialogData.showHappyImage" class="dialog-content" fxLayoutAlign="center">
        <img style="height: 150px;" src="https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/83pp7BlAmttxol7xSHJHKNoopg5wmEa350lyJveF.png" alt="">
    </div>

    <div class="dialog-content" *ngIf="dialogData.showExpiration">
        <div class="dialog-content-item">

            <div class="main-item">
                <img class="img-main-item" src="https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/F86fIBAz5GL5Z9BMFTW9QMB9fg93o0czF58ZZynG.png" alt="">
            </div>

            <div>
                <div class="main-itens">
                    Sua assinatura venceu em
                </div>
                <div class="main-description">
                    {{dialogData.subscription?.endsAt | date: 'dd/MM/yyyy'}}
                </div>
            </div>

        </div>

        <div class="dialog-content-item">
            <div class="main-item-number">
                {{daysToFinish}}
            </div>

            <div class="itens">
                <div class="main-item-number-itens">
                    dias restantes para encerramento
                </div>
                <div class="main-item-number-description">
                    {{finalDate | date: 'dd/MM/yyyy'}}
                </div>
            </div>
        </div>
    </div>

    <div class="btns-container">
        <ng-container *canShow="'subscription'">
            <button *ngIf="dialogData.canRenew" class="btn-gradient-round" (click)="renew()">
                Renovar Assinatura
            </button>
        </ng-container>

        <ng-container *canShow="'subscription'">
            <button *ngIf="dialogData.canPurchase" class="btn-gradient-round" (click)="purchase()">
                Escolher outro plano
            </button>
        </ng-container>

        <button *ngIf="dialogData.canSolveLater" class="btn-without-background" (click)="solveLater()">
            Resolver depois
        </button>

        <button *ngIf="dialogData.showClose" class="btn-without-background" (click)="close()">
            Fechar
        </button>

        <a class="btn-without-background help-link" [href]="whatsappLink" target="_blank">
            <mat-icon svgIcon="whatsapp"></mat-icon>
            <span>
                Preciso de ajuda
            </span>
        </a>

    </div>
</div>