
import { createReducer, on } from "@ngrx/store";
import { FeatureUsage } from "app/entities";
import { PlanSubscription } from "app/entities/plan-subscription";
import { Error, Status } from "../AppState";
import { logoutUserSuccess } from "../user/user.actions";
import { loadSubscription, loadSubscriptionFailure, loadSubscriptionSuccess, loadSubscriptionUsagesSuccess } from "./subscription.actions";

export interface SubscriptionState {
    subscription: PlanSubscription | null,
    featureUsages: FeatureUsage[] | null,
    error: Error | null;
    status: Status | null;
}


export const initialState: SubscriptionState = {
    subscription: null,
    featureUsages: null,
    error: null,
    status: {
        isFinished: false,
        isError: false,
        statusName: 'pending'
    },
};

export const subscriptionReducer = createReducer(
    initialState, 
    on(loadSubscription, (state) => ({
        ...state,
        status: {
            isFinished: false,
            isError: false,
            statusName: 'loading'
        },
    })),
    
    on(logoutUserSuccess, (state) => ({
        ...state,
        subscription:null,
        featureUsages: null,
        status: {
            isFinished: true,
            isError: false,
            statusName: 'success'
        },
    })),
    on(loadSubscriptionSuccess, (state, {subscription}) => ({
        ...state,
        subscription,
        featureUsages: subscription!.featureUsages,
        status: {
            isFinished: true,
            isError: false,
            statusName: 'success'
        },
    })),
    on(loadSubscriptionUsagesSuccess, (state, {usages}) => ({
        ...state,
        featureUsages: usages,
    })),
    on(loadSubscriptionFailure, (state, {error}) => ({
        ...state,
        status: {
            isFinished: true,
            isError: true,
            statusName: 'error'
        },
        error
    })),
)