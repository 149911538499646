import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api.service';
import { ApiResponse, Client } from 'app/entities';
import { map } from 'rxjs/operators';
import { Meta } from 'app/entities/meta';

@Injectable({
    providedIn: 'root'
})
export class UserMetaService extends ApiService {

    findAllFirstMeta()
    {
        return this.httpService.get(`/v1/clients/metas/active/users`, {})
            .pipe(
                map(res => ApiResponse.parseWith(new Meta, res) as ApiResponse<Meta[]>)
            )
    }

    findAll(customerId, params: any = {}) {
        params.customer_id = customerId;
        params = this.paramsFromObject(params);
        return this.httpService.get(`/v1/clients/metas`, params)
            .pipe(
                map(res => Meta.parse(res.data) as Meta[])
            )
    }

    find(id: any) {
        return this.httpService.get(`/v1/clients/metas/${id}`)
            .pipe(
                map(res => Meta.parse(res.data) as Meta)
            )
    }

    update(id: number, data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/metas/${id}`, formData)
            .pipe(
                map(res => Meta.parse(res.data) as Meta)
            )
    }

    store(data: any) {
        let formData = this.formDataFromObject(data)
        return this.httpService.post(`/v1/clients/metas`, formData)
            .pipe(
                map(res => Meta.parse(res.data) as Meta)
            )
    }

    destroy(id: number, customerId) {
        return this.httpService.delete(`/v1/clients/metas/${id}`, {customer_id: customerId})
    }

}