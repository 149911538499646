import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ScrollbarService } from 'app/services/scrollbar.service';

@Component({
    selector     : 'horizontal-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HorizontalLayout1Component implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;

    @ViewChild(FusePerfectScrollbarDirective, { static: false }) fusePerfectScrollbar!: FusePerfectScrollbarDirective;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _scrollbarService: ScrollbarService
    )
    {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to scrollbar enable disabled
        this._scrollbarService.scrollEnabled$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((status) => {
                if(status){
                    this.fusePerfectScrollbar._init()
                    this.fusePerfectScrollbar.elementRef.nativeElement.style.overflowY = "auto"
                }else{
                    this.fusePerfectScrollbar.destroy()
                    this.fusePerfectScrollbar.elementRef.nativeElement.style.overflowY = "hidden"
                }
            });

            // Subscribe to scrollbar enable disabled
        this._scrollbarService.scrollTo$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((scrollOptions) => {
            if(scrollOptions){
                this.fusePerfectScrollbar.scrollToElement(scrollOptions.selector, scrollOptions.offset, scrollOptions.speed)
            }
        });

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
