import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "application",
        title: "Aplicação",
        type: "group",
        icon: "apps",
        children: [
            {
                id: "dashboard",
                title: "Dashboard",
                type: "item",
                icon: "dashboard",
                url: "/dashboard",
                exactMatch: true,
            },
            {
                id: "invoices",
                title: "Orçamentos",
                type: "item",
                icon: "assignment",
                url: "/propostas",
            },
            {
                id: "clientes",
                title: "Clientes",
                type: "item",
                icon: "group",
                url: "/clientes",
            },
            {
                id: "produtos-e-servicos",
                title: "Produtos e Serviços",
                type: "item",
                icon: "handyman",
                url: "/produtos-e-servicos",
                badge: {
                    title: "Beta",
                    bg: "#6AD49B",
                    fg: "#fff",
                }
            },
            {
                id: "relatorios",
                title: "Relatórios",
                type: "item",
                icon: "insights",
                url: "/relatorios",
                badge: {
                    title: "Beta",
                    bg: "#6AD49B",
                    fg: "#fff",
                }
            },
            {
                id: "aparencia-da-proposta",
                title: "Dados da empresa",
                type: "item",
                icon: "business",
                url: "/configuracao/proposta",
                canShow: "settings",
            },
        ],
    },

    {
        id: "help",
        title: "Ajuda",
        type: "group",
        icon: "help",
        children: [
            {
                id: "faqs",
                title: "Perguntas Frequentes",
                url: "/faqs",
                type: "item",
                icon: "help"
            },

            {
                id: "whatsapp-contact",
                title: "Suporte via Whatsapp",
                type: "item",
                openInNewTab: true,
                externalUrl: true,
                svg: "whatsapp"
            },

            {
                id: "suport-email",
                title: "Suporte via E-mail",
                type: "item",
                icon: "assignment"
            },
            {
                id: "agendar-apresentação",
                title: "Agende uma Apresentação",
                type: "item",
                openInNewTab: true,
                externalUrl: true,
                icon: "support_agent"
            },
        ],
    },
];
