import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';

@Injectable({
  providedIn: 'root'
})
export class PlanFeatureGuard implements CanActivate {
  constructor(
    private subscriptionRestrictionService: SubscriptionRestrictionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const featureSlug = route.data['featureSlug'];
    if (this.subscriptionRestrictionService.checkFeatureLimitAndShowDialog(featureSlug)) {
      return false;
    }

    return true;
  }
}
