import { Decodable } from './_decodable'

class Notification extends Decodable {
    id?: number;
    icon?: string;
    image?: string;
    title?: string;
    description?: string;
    time?: string;
    link?: string;
    useRouter?: boolean;
    external_url?:string
    external_url_label?:string
    readAt?: string;
    createdAt?:string;
    updatedAt?:string;
    criadoEm?: string;
    open?: boolean

    protected _map = {
        readAt: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Notification
}