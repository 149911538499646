import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'mask'})
export class MaskPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, args = null): string
    {
        if(args == 'bytes'){
            return this.formatBytes(value)
        } else if('credit_card'){
            return this.formatCreditCard(value)
        } else {
            return value;
        }
        
        
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    formatCreditCard(value){
        let creditCardMask = [/\d/, /\d/,/\d/,/\d/, ' ',/\d/, /\d/,/\d/,/\d/, ' ',/\d/, /\d/,/\d/,/\d/, ' ',/\d/, /\d/,/\d/,/\d/]
        //return value.match(creditCardMask)
        return value
    }
}
