import { createAction, props } from "@ngrx/store";
import { User } from "app/entities";
import { Error } from "../AppState";

export enum CustomerActionTypes {
    loadCustomers = '[Customer] Load Customers',
    loadCustomersSuccess = '[Customer] Load Customers Success',
    loadCustomersFailure = '[Customer] Load Customers Failure',
}


export const loadCustomers = createAction(CustomerActionTypes.loadCustomers);
export const loadCustomersSuccess = createAction(
    CustomerActionTypes.loadCustomersSuccess,
    props<{customers: User[]}>()
);
export const loadCustomersFailure = createAction(
    CustomerActionTypes.loadCustomersFailure,
    props<{ error: Error }>()
);