import { Plan } from "./plan";
import { PlanPaymentInstallment } from "./plan-payment-installment";
import { PlanSubscription } from "./plan-subscription";
import { Decodable } from "./_decodable";

class PlanPayment extends Decodable {
    id: number;
    planId: number;
    name: string;
    isActive: boolean;
    price: number;
    signupFee: number;
    slug: string
    description?: string
    currency: string;
    trialPeriod: number;
    trialInterval: string;
    invoicePeriod: string;
    invoiceInterval: number;
    gracePeriod: string;
    graceInterval: number;
    prorateDay: number;
    proratePeriod: number;
    prorateExtendDue: number;
    activeSubscribersLimit: number;
    sortOrder: number;
    createdAt: Date;
    updatedAt: Date;


    plan?: Plan;
    subscription?: PlanSubscription;
    installments?: PlanPaymentInstallment[];

    pricePerMonth?: number
    dateRenew?: Date
    periodFormatted?: string

    currentPlanPaymentId?: number

    protected _map = {
        plan: e => Plan.parse(e),
        subscription: e => PlanSubscription.parse(e),
        installments: e => PlanPaymentInstallment.parse(e),
        dateRenew: e => this.parseDate(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export { PlanPayment };
