<div class="dialog-content-wrapper" id="create-item">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                {{index<0?"Adicionar":"Editar"}} Item
            </span>
            <button (click)="matDialogRef.close()" mat-icon-button aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0">
        <form [formGroup]="form">
            <p>
                (
                <span class="mat-placeholder-required">*</span> ) Campos obrigatórios
            </p>
            <div fxLayout="column" fxLayoutGap="10px">
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Nome do item</mat-label>
                    <input #itemName autocomplete="off" formControlName="item_name" matInput required style="
                                font-weight: 500;
                            ">
                </mat-form-field> -->

                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nome do item {{form.get('should_save')?.value}}</mat-label>
                    <input autocomplete="off" 
                    [matAutocomplete]="auto" 
                    (focus)="updateFilteredProducts($event.target.value)"
                    formControlName="item_name"
                        matInput required style="
                            font-weight: 500;
                        ">
                     <!-- <mat-icon *ngIf="getFilteredLenght() === 0 && form.get('product_id')?.value == null"
                     matSuffix 
                     [ngClass]="{'checked-icon': form.get('should_save')?.value, 'unchecked-icon': !form.get('should_save')?.value}"
                     (click)="onIconClick($event)">
                        {{ form.get('should_save')?.value ? 'check' : 'check_box_outline_blank' }}
                    </mat-icon> -->
                    <mat-checkbox
                    matTooltip="Ao habilitar esta opção o item será salvo em Produtos e Serviços e poderá ser selecionado em orçamentos futuros"
                    matTooltipPosition="above"
                    matTooltipHideDelay="100"
                    *ngIf="form.get('should_save')?.value && getFilteredLenght() === 0 && form.get('product_id')?.value == null"                                                             
                    matSuffix
                    checked="true"
                    class="custom-mat-checkbox ml-8 border-1"
                    (click)="onIconClick($event)">
                    </mat-checkbox>
                    <mat-checkbox
                    matTooltip="Ao habilitar esta opção o item será salvo em Produtos e Serviços e poderá ser selecionado em orçamentos futuros"
                    matTooltipPosition="above"
                    matTooltipHideDelay="100"
                    *ngIf="!form.get('should_save')?.value && getFilteredLenght() === 0 && form.get('product_id')?.value == null"
                    matSuffix
                    class="custom-mat-checkbox ml-8 border-1"
                    (click)="onIconClick($event)">
                    </mat-checkbox>

                    <mat-autocomplete 
                    (optionSelected)="setProduct($event)"
                    #auto="matAutocomplete" >  
                        <!-- <mat-option *ngIf="(filteredProducts | async)?.length === 0" disabled>
                            <div style="cursor: pointer">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <span style="color: black">salvar item</span>
                                    <mat-checkbox formControlName="should_save"
                                    (change)="showFeedBackItemSaveMessage($event.checked)">
                                    </mat-checkbox>
                                </div>
                            </div>  
                        </mat-option> -->

                        <mat-option *ngIf="(filteredProducts | async)?.length === 0" disabled>
                            <div style="cursor: pointer">
                                <div style="display: flex; justify-content: start; gap: 8px; align-items: center;">
                                    <mat-icon style="color: #4E5663; margin-right: 0px;"
                                            matTooltip="Ao habilitar esta opção o item será salvo em Produtos e Serviços e poderá ser selecionado em orçamentos futuros"
                                            matTooltipPosition="above"
                                            matTooltipHideDelay="100">info</mat-icon>
                                    <span fxHide fxShow.gt-sm
                                    style="color: #4E5663;"><strong>Salvar este item em produtos e serviços</strong></span>
                                    <span fxHide.gt-sm
                                    style="color: #4E5663;"><strong>Salvar este item</strong></span>
                                    <mat-checkbox formControlName="should_save"
                                    class="custom-mat-checkbox ml-8"
                                    (change)="showFeedBackItemSaveMessage($event.checked)">
                                    </mat-checkbox>
                                </div>
                            </div>  
                        </mat-option>
      
                        <mat-option 
                        class="multiline-mat-option"
                        *ngFor="let product of filteredProducts | async" 
                        [value]="product">
                            <span>{{ product?.code }}</span>&nbsp;-&nbsp;
                            <span>{{ product?.title }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="item_unit_id" 
                    style="height: 100%;">
                        <mat-option *ngFor="let unit of units" 
                        [value]="unit.id">
                            <span>{{unit.abbr}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Valor</mat-label>
                    <input [options]="{
                                prefix: 'R$',
                                thousands:
                                    '.',
                                decimal:
                                    ',',
                                align: 'left'
                            }" inputmode="numeric" autocomplete="off" class="hide-number-spinner" currencyMask formControlName="item_per_unit" matInput>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Quantidade</mat-label>
                    <input autocomplete="off" class="hide-number-spinner" clearFieldIf formControlName="item_quantity" matInput type="number">
                    <span matSuffix class="input-suffix">
                        &nbsp;{{
                                getUnitSuffixById(
                                    form?.get(
                                        "item_unit_id"
                                    )
                                        .value
                                )
                            }}
                    </span>
                </mat-form-field>

                <mat-form-field appearance="outline" fxHide>
                    <mat-label>Total</mat-label>
                    <input  [disabled]="
                                true
                            " [value]="
                                getTotal()
                            " autocomplete="off" matInput type="number" type="text" readonly>
                           
                </mat-form-field>
                <div class="total" fxLayout="row" fxLayoutAlign="space-between">
                    <h4>Total</h4>
                    <h4>{{ getTotal()}}</h4>
                </div>
                
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="10px">
        <button (click)="cancel()" class="btn btn-red" matTooltip="Cancelar">
            Cancelar
        </button>
        <button [disabled]="form.invalid" (click)="saveItem()" class="btn btn-blue" aria-label="Criar Item">
            <span>
                {{index<0?"Adicionar Item":"Salvar Item"}}
            </span>
        </button>
    </div>
</div>