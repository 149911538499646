import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Store } from '@ngrx/store';
import { Log } from 'app/entities';
import { loginUserFailure, loginUserSuccess, logoutUserSuccess } from 'app/state/user/user.actions';
import { UserState } from 'app/state/user/user.reducer';
import { selectUser } from 'app/state/user/user.select';
import { map, tap } from "rxjs/operators";
import { User } from '../entities/user';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { Error } from 'app/state/AppState';
import { Account } from 'app/entities/account';
import { selectAccount } from 'app/state/account/account.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User | null = null;

  currentAccount: Account | null = null;

  constructor(
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private _matDialog: MatDialog,
    private _alertService: AlertService,
    private _router: Router,
    private _store: Store<UserState>
  ) { 
    
    this._store.select(selectUser).subscribe(user => {
      this.currentUser = user;
    })

    this._store.select(selectAccount).subscribe(account => {
        this.currentAccount = account;
      })
  }

  async autoLoginByToken() {
    try {
      let currentUserToken = this.localStorageService.getItem('currentUserToken');
      if (currentUserToken) {
        let currentUser = await this.httpService.get('/v1/clients/login/token')
          .pipe(map(response => User.parse(response.data)))
          .toPromise();
          if(currentUser){
            this._store.dispatch(loginUserSuccess({user:currentUser}));
          }
      } else {
        this._store.dispatch(loginUserFailure({error:{} as Error}));
      }
    } catch (e) {
        this._store.dispatch(loginUserFailure({error:e as Error}));
    }
  }

  login(credentials: any) {
    return this.httpService.post('/v1/clients/login', credentials)
      .pipe(
        map(response => User.parse(response.data)),
        tap(async (user: User) => {
          if (user && user.accessToken) {
            localStorage.setItem('currentUserToken', user.accessToken);
            this._store.dispatch(loginUserSuccess({user:user}));
          }
        }));
  }

  forgotPassword(credentials: any) {
    return this.httpService.post('/v1/clients/password/email', credentials)
  }

  resetPassword(credentials: any) {
    return this.httpService.post('/v1/clients/password/reset', credentials)
  }

  logMeOut() {

    let dialog = this._matDialog.open(FuseConfirmDialogComponent, {
        disableClose: false
    });

    dialog.componentInstance.confirmTitle = 'Sair do Sistema';
    dialog.componentInstance.confirmMessage = 'Deseja realmente sair da Orca?';

    dialog.afterClosed().subscribe(result => {
        if (result) {
            this.logout().subscribe(result => {
                this._alertService.success("Logout efetuado com sucesso.", true)
                this._store.dispatch(logoutUserSuccess());
                setTimeout(() => {
                    this._router.navigateByUrl('/auth/login')
                }, 200)
            }, err => {
                this._alertService.error(err)
            })
        }
    })
}

  private logout() {

    return this.httpService.post('/v1/clients/logout')
      .pipe(
        tap(async () => {
          localStorage.removeItem('currentUserToken');
        }));
  }

  validatePassword(password: string) {
    return this.httpService.post('/v1/clients/password/validate', { password })
  }

  findLogs() {
    return this.httpService.get('/v1/clients/logs', {}).pipe(
      map(res => Log.parse(res.data) as Log[])
    )
  }
}