import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DialogComponent} from "../layout/components/dialog/dialog.component";
import { CustomSnackbarNotificationComponent } from 'app/main/components/custom-snackbar-notification/custom-snackbar-notification.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private messageSubject$ = new Subject<any>();
  public message$ = this.messageSubject$.asObservable()
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.messageSubject$.next();
        }
      }
    });

    this.message$.subscribe(message => {
      if (message && message.text) {
        if(message.custom){
            this.snackBar.openFromComponent(CustomSnackbarNotificationComponent,  {
                data:{title:message.title, text: message.text},
                duration: message.duration,
                panelClass: [ message.type ],
                horizontalPosition: "right",
                verticalPosition: "top"
              })
        }else{
            this.snackBar.open(message.text, "OK", {
              duration: message.duration,
              panelClass: [ message.type ],
              horizontalPosition: "right",
              verticalPosition: "top"
            })
        }
      } else {
        this.snackBar.dismiss()
      }
    })
    
  }

  notification(title:any, message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.messageSubject$.next({ type: 'blue-500',title:title, text: message, duration:10000, custom:true });
  }

  success(message: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.messageSubject$.next({ type: 'teal-500', text: message, duration:5000 });
  }

  remove() {
    this.messageSubject$.next();
  }

  error(errors: any, keepAfterNavigationChange = false) {
    let message = this.parseError(errors)
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.messageSubject$.next({ type: 'warn-500', text: message, duration:5000 });
  }

  parseError(errors: any): String {
    if (Array.isArray(errors) && errors.length > 0) {
      errors = errors[0]
    }

    if (typeof errors.message == "string") {
      return errors.message
    } if (errors.error != undefined) {
      return errors.error.message
    } else if (typeof errors == "string") {
      return errors
    }else if (typeof errors == "object") {
        if(errors.hasOwnProperty('errors')){
            return this.parseError(errors.errors)
        }else{
            // get all property values as array and join them as string
            return Object.values(errors).join(', ')
        }
    }
    return JSON.stringify(errors)
  }

  openDialog(data: any) {
    if (!data.buttons) {
      data.buttons = ["Fechar", "OK"]
    }
    
    return this.dialog.open(DialogComponent, {
      autoFocus: false,
      disableClose: true,
      minWidth: 280,
      data: data
    })
  }
}
