import {
    Component,
    Inject,
    OnInit,
} from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Proposal } from "app/entities/proposal";
import { ProposalService } from "app/main/pages/proposals/proposals.service";
import { AlertService } from "app/services/alert.service";
import { GtagService } from "app/services/gtag.service";
import { ProposalEventService } from "app/services/proposal.service";
import { environment } from "environments/environment";
import { PreviewProposalDialogComponent } from "../preview-proposal-dialog/preview-proposal-dialog.component";
import { SubscriptionRestrictionService } from "app/services/subscription-restriction.service";

@Component({
    selector: "app-proposal-send-dialog",
    templateUrl: "./proposal-send-dialog.component.html",
    styleUrls: ["./proposal-send-dialog.component.scss"],
})
export class ProposalSendDialogComponent implements OnInit {
    proposal!: Proposal;

    env = environment

    constructor(
        public matDialogRef: MatDialogRef<ProposalSendDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public _matDialog: MatDialog,
        private _proposalsService: ProposalService,
        private _alertService: AlertService,
        private _router: Router,
        private _proposalEventService: ProposalEventService, 
        private _gtag: GtagService,
        private _subscriptionRestrictionService: SubscriptionRestrictionService
    ) {}

    ngOnInit(): void {
        this.proposal = this.data.proposal;
    }

    goHome() {
        this._gtag.event('dialog_proposal_go_home', {
            event_category: 'engagemnt',
            event_label: 'Voltar para a dashboard'
        })
        this.matDialogRef.close();
        this._router.navigateByUrl("/dashboard");
    }

    copy(source) {
        this._proposalsService.copyLink(this.proposal.id, source).subscribe(
            (res) => {
                this._gtag.event('dialog_proposal_link_copied', {
                    event_category: 'engagemnt',
                    event_label: 'Link da orçamento copiado'
                })

                let link = res.data.link_token;
                this.copyToClipboard(this.getMessageWithLinkProposal(link));
                if (this.proposal.status! <= 2) {
                    this.proposal.status = 2;
                    this.proposal.statusClass = "blue-800";
                    this.proposal.statusName = "Enviado";
                }
                this._alertService.success("Link copiado");
            },
            (error) => {
                this._alertService.error(error);
            }
        );
    }

    openPDF() {
        var featureLimited = this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("pdf-propostas")
        if (!featureLimited) {
            var url = this.env.baseUrlHotsite + '/proposta/' + this.proposal.tokenLink + '.pdf'
            window.open(url, '_blank')
        }
    }


    sendWhatsapp(source) {
        this._proposalsService.copyLink(this.proposal.id, source).subscribe(
            (res) => {

                this._gtag.event('dialog_proposal_sent_whatsapp', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento enviado para o Whatsapp'
                })

                let link = res.data.link_token;

                if (this.proposal.status! <= 2) {
                    this.proposal.status = 2;
                    this.proposal.statusClass = "blue-800";
                    this.proposal.statusName = "Enviado";
                }
                const urlWhatsapp = `https://api.whatsapp.com/send?phone=+55${this.proposal!.client?.phone.replace(
                    /\D/gim,
                    ""
                )}&text=${encodeURI(this.getMessageWithLinkProposal(link))}`;
                window.open(urlWhatsapp, "_blank");
            },
            (error) => {
                this._alertService.error(error);
            }
        );
    }

    sendEmail(contact: any = null, contactEmail = null) {
        if (this.proposal?.client?.email == null) {
            return;
        }

        if (contact && !contactEmail) {
            contact = {
                contact_id: contact.id,
            };
        }
        if (contactEmail) {
            contact = {
                contact_email: contactEmail,
            };
        }

        this._proposalsService.sendEmail(this.proposal.id!, contact).subscribe(
            (data) => {
                this._gtag.event('dialog_proposal_sent_email', {
                    event_category: 'engagemnt',
                    event_label: 'Orçamento enviado para o e-mail'
                })

                this._alertService.success("Email enviado com sucesso.");
                this._proposalEventService.initOnNext();
            },
            (err) => {
                this._alertService.error(err);
            }
        );
    }

    preview() {
        this._gtag.event('dialog_proposal_preview', {
            event_category: 'engagemnt',
            event_label: 'Orçamento visualizado'
        })

        let dialog = this._matDialog.open(PreviewProposalDialogComponent, {
            panelClass: ["form-dialog", "large-form-dialog", "proposal-preview-dialog"],
            data: {
                id: this.proposal?.id,
                token: this.proposal?.tokenLink,
            },
        });
        dialog.afterClosed().subscribe((result) => {});
    }

    getMessageWithLinkProposal(link) {
        // let proposalUrl = environment.apiHost + "/email/proposals/" + this.proposal.token
        var data = new Date(this.proposal.dateProposal!);

        data.setDate(data.getDate() + this.proposal.expiresIn);
        var dia = data.getDate().toString().padStart(2, "0");
        var mes = (data.getMonth() + 1).toString().padStart(2, "0"); //+1 pois no getMonth Janeiro começa com zero.
        var ano = data.getFullYear();

        return `${this.proposal.proposalNumber} - ${
            this.proposal.shortDescription
        }
Orçamento válido até: ${dia + "/" + mes + "/" + ano}
Acesse o orçamento: ${link}`;
    }

    /*
     * Copia o texto passado por paramêtro para o clipboard.
     * @param {type} text
     * @returns {undefined|Boolean}
     */
    copyToClipboard(text) {
        if (
            document.queryCommandSupported &&
            document.queryCommandSupported("copy")
        ) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }
}
