import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { Meta, User } from 'app/entities';
import { DashboardService } from 'app/main/pages/dashboard/dashboard.service';
import { UsersService } from 'app/main/pages/users/users.service';
import { AlertService } from 'app/services/alert.service';
import { GtagService } from 'app/services/gtag.service';
import { UserMetaService } from './user-meta.service';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';

@Component({
    selector: 'app-user-meta-dialog',
    templateUrl: './user-meta-dialog.component.html',
    styleUrls: ['./user-meta-dialog.component.scss'],
    animations: fuseAnimations,
})
export class UserMetaDialogComponent implements OnInit {
    
    idUserSelected
    meta?: Meta
    customerId: number | undefined
    users?: User[]
    form?: FormGroup;
    loading = false
    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public matDialogRef: MatDialogRef<UserMetaDialogComponent>,
        private _formBuilder: FormBuilder,
        private _alertService: AlertService,
        private _metaService: UserMetaService,
        private _usersService: UsersService,
        private _dashboardService: DashboardService,
        private _subscriptionRestrictionService: SubscriptionRestrictionService,
        private _gtag: GtagService
    ) { }

    ngOnInit(): void {

        this.meta = this._data?.meta;
        this.customerId = this._data?.customerId

        if(!this.customerId){
            this._usersService.findAll({active:true}).subscribe(users => {
                this.users = users;

                if(!this.meta?.customerId && this.users?.length == 1){
                    this.customerId = this.users[0]?.id
                }
            })
        }

        if (!this.meta?.id) {
            this.meta = new Meta();
        }

        this.form = this.createForm();
    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.meta?.id, []],
            customer_id: [this.customerId || this.meta?.customerId, [Validators.required]],
            value_meta: [this.meta?.valueMeta ?? 0.00, [Validators.required]],
            start_at: [this.meta?.startAt, [Validators.required]],
            end_at: [this.meta?.endAt, [Validators.required]],
        });
    }

    insertMeta() {
        this.loading = true;

        if (this.form?.value.start_at > this.form?.value.end_at) {
            this.form?.controls.start_at.setErrors({
                incorrect: true
            })
            this.form?.controls.end_at.setErrors({
                incorrect: true
            })
        }

        if (!this.form?.valid) {
            this.loading = false;
        }

        this.store();
    }

    store() {
        this._metaService.store(this.form?.value).subscribe((meta) => {
            this._gtag.event('meta_create', {
                event_category: 'engagemnt',
                event_label: 'Dispara ao clicar no botão Criar Meta dentro do formulário Nova Meta'
            })
            this.meta = meta;
            this.loading = false;
            this._alertService.success("Meta criada com sucesso");
            this._dashboardService.getCards({})
            this.matDialogRef.close(true);
            this._subscriptionRestrictionService.reloadSubscriptionUsages()
        }, (error) => {
            this.loading = false;
            this._alertService.error(error);
        })
    }

    updateMeta() {
        this.loading = true;

        if (this.form?.value.start_at > this.form?.value.end_at) {
            this.form?.controls.start_at.setErrors({
                incorrect: true
            })
            this.form?.controls.end_at.setErrors({
                incorrect: true
            })
        }

        if (this.meta && this.form?.valid) {
            this._metaService.update(this.meta.id, this.form?.value).subscribe((meta) => {
                this._gtag.event('meta_update', {
                    event_category: 'engagemnt',
                    event_label: 'Dispara ao editar uma Meta'
                })

                this.meta = meta;
                this.loading = false;
                this._alertService.success("Meta atualizado com sucesso");
                this.matDialogRef.close(true);
            }, (error) => {
                this.loading = false;
                this._alertService.error(error);
            })
        } else {
            this.loading = false;
        }
    }
}
