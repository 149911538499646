<mat-menu>
    <span>
        <button id="enviar-por-whatsapp" 
            [matMenuTriggerFor]="phoneMenu" 
            mat-menu-item
            aria-label="Enviar por Whatsapp">
            <mat-icon>phone_android</mat-icon>
            <span>Enviar por Whatsapp</span>
    </button>
    </span>
    <span>
        <button id="enviar-por-email" *ngIf="proposal?.client?.email || proposal?.client?.contacts?.length"
            [matMenuTriggerFor]="contactsMenu"
            mat-menu-item
            aria-label="Enviar por email">
            <mat-icon>email</mat-icon>
            <span>Enviar por Email</span>
    </button>
    </span>
    <button id="copiar-link" (click)="copy('link')" mat-menu-item aria-label="Copiar">
        <mat-icon>share</mat-icon>
        <span>Copiar link</span>
    </button>
    <mat-divider></mat-divider>
    <button (click)="preview()" mat-menu-item aria-label="confirmar">
        <mat-icon>pageview</mat-icon>
        <span>Visualizar orçamento</span>
    </button>

    <a (click)="openPDF()" mat-menu-item aria-label="Gerar PDF">
        <mat-icon>picture_as_pdf</mat-icon>
        <span>Gerar PDF</span>
    </a>
    <button (click)="duplicateProposal()" mat-menu-item aria-label="Copiar">
        <mat-icon>file_copy</mat-icon>
        <span>Duplicar orçamento</span>
    </button>
    <span>
        <button (click)="showLogsProposals()"
            mat-menu-item>
            <mat-icon>inventory_2</mat-icon>
            <span>Histórico de ações</span>
    </button>
    </span>
    <span>
        <mat-divider></mat-divider>
        <span>
            <button *ngIf="proposal.canProcess"
                (click)="aprovar()"
                mat-menu-item
                aria-label="aprovar">
                <mat-icon>thumb_up</mat-icon>
                <span>Aprovar</span>
    </button>
    </span>

    <span>
            <!-- const APROVED = 3 -->
            <button *ngIf="proposal?.status == 3"
                (click)="faturar()"
                mat-menu-item
                aria-label="Faturar orçamento">
                <mat-icon>receipt_long</mat-icon>
                <span>Faturar</span>
    </button>
    </span>

    <span>
        <!-- const BILLED = 5 -->
        <button *ngIf="proposal?.status == 5"
            (click)="pagar()"
            mat-menu-item
            aria-label="Confirmar pagamento">
            <mat-icon>monetization_on</mat-icon>
            <span>Confirmar Pagamento</span>
</button>
</span>


    <span>
            <button *ngIf="proposal.canProcess"
                (click)="reprovar()"
                mat-menu-item
                aria-label="reprovar">
                <mat-icon>thumb_down</mat-icon>
                <span>Reprovar</span>
    </button>
    </span>
    <span *ngIf="editProposal">
            <button
                (click)="edit()"
                mat-menu-item
                aria-label="Editar">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
    </button>
    </span>
    <button (click)="confirmDeleteProposal()" mat-menu-item aria-label="negar">
            <mat-icon>delete_outline</mat-icon>
            <span>Excluir</span>
        </button>
    </span>

</mat-menu>

<mat-menu #contactsMenu="matMenu">
    <button (click)="sendEmail(null)" mat-menu-item aria-label="enviar email">
        <mat-icon>email</mat-icon>
        <span>Todos os Contatos</span>
    </button>

    <button *ngIf="proposal?.client?.email" (click)="sendEmail(null, proposal?.client?.email)" mat-menu-item aria-label="enviar email">
        <mat-icon>star</mat-icon>
        <span>
            {{proposal?.client?.responsible ? proposal?.client.responsible : proposal?.client?.name}}
        </span>
    </button>

    <button *ngFor="let contact of proposal?.client?.contacts" (click)="sendEmail(contact)" mat-menu-item aria-label="aprovar">
        <mat-icon>alternate_email</mat-icon>
        <span>{{contact.name}}</span>
    </button>
</mat-menu>

<mat-menu #phoneMenu="matMenu" >

    <button *ngIf="proposal?.client?.phone" mat-menu-item 
    id="enviar-por-whatsapp" 
            (click)="sendWhatsapp('whatsapp', proposal.client!.phone)">
        <mat-icon>star</mat-icon>
        <span>
            {{proposal?.client?.responsible ? proposal?.client.responsible : proposal?.client?.name}}
        </span>
    </button>
   
    <button  mat-menu-item *ngIf="!proposal.client?.phone"
    id="enviar-por-whatsapp"
            >
        <mat-icon>contact_support</mat-icon>
        <span>Cliente não tem Número Cadastrado</span>
    </button>
    
    
    <ng-container *ngFor=" let contact of proposal?.client?.contacts">
        <div>
            <button  mat-menu-item *ngIf="contact.phone"
            id="enviar-por-whatsapp"
                    (click)="sendWhatsapp('whatsapp', contact.phone)">
                <mat-icon>phone</mat-icon>
                <span>{{contact.name}}</span>
            </button>
        </div>
        
        
    </ng-container>
</mat-menu>