import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from 'app/main/pages/users/users.service';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadCustomers, loadCustomersFailure, loadCustomersSuccess } from './customer.actions';



@Injectable()
export class CustomerEffects {

    loadCustomers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCustomers),
            switchMap(() =>
                this.customerService.findAll({}).pipe(
                    map((customers) => loadCustomersSuccess({ customers })),
                    catchError((error) => of(loadCustomersFailure({ error }))))
            )
        )
    )

    constructor(
        private actions$: Actions,
        private customerService: UsersService
    ) { }


}