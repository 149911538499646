import { Injectable } from '@angular/core';
import { Unit } from 'app/entities/unit';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  

  constructor() { }

  private unitsKey = "units_key";

  /**
   * task
   */
  public tawkReady = new BehaviorSubject<Boolean>(false)


  getItem(key: string, defaultValue:any=null) {
    this.removeExpiredItems();
    let itemValue = localStorage.getItem(key);
    if (itemValue && /^{(.*?)}$/.test(itemValue)) {
      let current = JSON.parse(itemValue);
        return current.value;
    }
    return itemValue?itemValue:defaultValue
  }

  getUnits(): Unit[] {
    return Unit.parse(localStorage.getItem(this.unitsKey) || JSON.stringify([])) as Unit[]
  }

  saveUnits(units:Unit[]){
    localStorage.setItem(this.unitsKey, JSON.stringify(units))
  }

  setItem(key: string, value: any, minutes: number = -1) {
    let expires = minutes > 0 ?(new Date().getTime()) + (60000 * minutes): -1;

    localStorage.setItem(key, JSON.stringify({
        "value": value,
        "expires": expires
    }))
  }

  removeItem(...keys: string[]) {
    keys.map(key => localStorage.removeItem(key))
  }

  private removeExpiredItems() {
    let toRemove: string[] = [],
      currentDate = new Date().getTime();

    for (let i = 0, j = window.localStorage.length; i < j; i++) {
      let key = localStorage.key(i) as string,
        current: any = localStorage.getItem(key);

      if (current && /^\{(.*?)\}$/.test(current)) {
        current = JSON.parse(current);
        if (current.expires && current.expires > 0 && current.expires <= currentDate) {
          toRemove.push(key);
        }
      }
    }

    for (let i = toRemove.length - 1; i >= 0; i--) {
      localStorage.removeItem(toRemove[i]);
    }

  }
}
