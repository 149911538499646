import { Injectable } from '@angular/core';
import { PlanSubscription } from 'app/entities/plan-subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../http.service';
import { AccountTag } from 'app/entities/account-tag';

@Injectable({
    providedIn: 'root'
})
export class TagsService {

    constructor(
        private httpService: HttpService
    ) { }

    findAll(): Observable<AccountTag[]> {
        return this.httpService.get('/v1/clients/tags').pipe(
            map(res => AccountTag.parse(res.data) as AccountTag[])
        );
    }


    store(data: any): Observable<AccountTag> {
        return this.httpService.post('/v1/clients/tags', data).pipe(
            map(res => AccountTag.parse(res.data) as AccountTag)
        );
    }

    update(id: number, data: any): Observable<AccountTag> {
        return this.httpService.put(`/v1/clients/tags/${id}`, data).pipe(
            map(res => AccountTag.parse(res.data) as AccountTag)
        );
    }

}