import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Unit } from 'app/entities/unit';
import { UnitService } from 'app/main/pages/products/unit.service';
import { AlertService } from 'app/services/alert.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-form-unit-create-or-edit',
  templateUrl: './form-unit-create-or-edit.component.html',
  styleUrls: ['./form-unit-create-or-edit.component.scss']
})
export class FormUnitCreateOrEditComponent implements OnInit {

  @Input('manuTrigger') manuTrigger?: MatMenuTrigger
  @Input('unit') unit: Unit = new Unit();
  @ViewChild('nomeInput') nomeInput?: any;
  @Output() onCreatedUnit = new Subject<Unit>();
  @Output() onUpdatedUnit = new Subject<Unit>();

  public bankFilterCtrl: FormControl = new FormControl();
  loading = false;
  form?: FormGroup
  itemCreated: boolean = false

  constructor(
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _unitService: UnitService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    if (!this.unit) {
      this.unit = new Unit()
      this.unit.status = 1
    }
    
    this.form = this._formBuilder.group({
      id: [this.unit?.id, []],
      name: [this.unit?.name, [Validators.required]],
      abbr: [this.unit?.abbr, []],
      status: [this.unit?.status ?? 1, []],
    });

    setTimeout(() => {
      this.nomeInput.nativeElement.focus();
    }, 100);
  }

  store() {
    if (this.form?.valid) {
      this.loading = true
      this._unitService.store(this.form?.value).subscribe((res) => {
        this._alertService.success('Unidade criada');
        this.onCreatedUnit.next(res)
        this.itemCreated = true
        this.loading = false
        this.form?.reset()
      }, (error) => {
        this._alertService.error(error)
        this.loading = false
      })
    }
  }

  update() {
    if (this.form?.valid && this.unit && this.unit.id) {
      this.loading = true
      this._unitService.update(this.unit.id, this.form?.value).subscribe((res) => {
        this._alertService.success('Unidade atualizada');
        this.itemCreated = true
        this.form?.markAsPristine()
        this.onUpdatedUnit.next(res)
        this.loading = false
      }, (error) => {
        this.loading = false
        this._alertService.error(error)
      })
    }
  }

  close() {
    this.form?.reset()
    this.manuTrigger?.closeMenu()
  }

  toggleStatus(unit: Unit, active: boolean) {
    this.unit.status = active ? 1 : 2
    this.form?.controls.status.setValue(unit.status)
    this.form?.markAsDirty()
  }
}
