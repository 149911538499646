<div class="usage-container" *ngIf="usages?.length > 0">

    <div *ngFor="let usage of usages">
        <ng-container *ngIf="showUsage(usage)">
        <div class="usage-item">
            <div class="d-flex flex-column w-100-p">
                <div class=" w-100-p" style="display: flex; justify-content: space-between;">
                    <span class="usage-name">{{ usage.name }}</span>
                    <span class="usage-count" *ngIf="usage.type == 'feature'">{{ usage.used }}/{{ usage.limit }}</span>
                </div>
                <div class="usage-progress">
                    <div class="usage-bar" *ngIf="usage.type == 'feature'"
                    [style.width]="usage.used / usage.limit * 100 + '%'"></div>
                    <div class="usage-bar" *ngIf="usage.type == 'plan'"
                    [style.width]="usage.percentage+ '%'"></div>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
    <div class="w-100-p" style="display: flex; justify-content: space-between; align-items: flex-end; margin: 4px 0px;">
        <div class="plan-badge">
          Plano: {{ planName }}
        </div>

        <ng-container *canShow="'subscription'">
            <ng-container *ngIf="showUpgradeButton()">
                <a class="renew-button" 
                matTooltip="Clique aqui para renovar sua assinatura"
                *ngIf="(subscription$ | async)?.canRenew"
                (click)="navigateToRenew()">
                    Renovar Assinatura
                </a>
                <a class="upgrade-button" 
                matTooltip="Clique aqui para fazer upgrade de sua assinatura"
                *ngIf="!(subscription$ | async)?.canRenew"
                (click)="navigateToUpgrade()">
                    Fazer upgrade
                </a>
            </ng-container>
        </ng-container>
    </div>
</div>
  