import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Product, ProductCategory } from 'app/entities';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-drop-down-product-category-create',
  templateUrl: './card-drop-down-product-category-create.component.html',
  styleUrls: ['./card-drop-down-product-category-create.component.scss']
})
export class CardDropDownProductCategoryCreateComponent implements OnInit {
  @Input() appearance: string = 'standard';
  @Input() productCategory: ProductCategory | null = null;
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject<Product>();
  @Output() onUpdated = new Subject<Product>();
  @Input() responsiveBtn = false;
  menuOpen = false;
  loading = false

  constructor(
  ) { }

  ngOnInit(): void {

  }

  open() {
    this.menuTrigger.openMenu()
    console.log("dentro de open")
  }

  created(productCategory) {
    this.onCreated.next(productCategory)
    this.close();

  }

  updated(productCategory) {
    this.close();
    this.onUpdated.next(productCategory)
  }

  close() {
    this.menuTrigger.closeMenu()
  }

  getButtonClass() {
    // TODO: Construir diretiva para tratar as classes
    switch (this.appearance) {
      case 'mat-menu-item':
        return 'mat-menu-item mat-focus-indicator';
      default:
        return 'btn btn-green'; // default class
    }
  }
}
