import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SubscriptionService } from 'app/services/http/subscription.service';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadSubscription, loadSubscriptionFailure, loadSubscriptionSuccess, loadSubscriptionUsages, loadSubscriptionUsagesSuccess } from './subscription.actions';

@Injectable()
export class SubscriptionEffects {

    loadSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSubscription),
            switchMap(() =>
                this.subscriptionService.load().pipe(
                    map((subscription) => loadSubscriptionSuccess({ subscription })),
                    catchError((error) => of(loadSubscriptionFailure({ error }))))
            )
        )
    )

    loadSubscriptionUsages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSubscriptionUsages),
            switchMap(() =>
                this.subscriptionService.getFeatureUsages().pipe(
                    map((usages) => loadSubscriptionUsagesSuccess({ usages })),
                    catchError((error) => of(loadSubscriptionFailure({ error }))))
            )
        )
    )

    constructor(
        private actions$: Actions,
        private subscriptionService: SubscriptionService,
        private store: Store
    ) { }


}