import { Decodable } from './_decodable';

class Text extends Decodable {
    id: number
    title: string
    slug: string
    content: string
    createdAt: Date
    updatedAt: Date
    status: number
    statusFormatted: string
    statusClass: string

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    Text
}