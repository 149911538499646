import { createAction, props } from "@ngrx/store";
import { Notification } from "app/entities";
import { Account } from "app/entities/account";
import { AccountTag } from "app/entities/account-tag";


export enum AccountActionTypes {
    loadAccount = '[Account] Load Account',
    loadAccountSuccess = '[Account] Load Account Success',
    loadAccountFailure = '[Account] Load Account Failure',
    loadNotifications = '[Account] Load Notifications',
    loadNotificationsSuccess = '[Account] Load Notifications Success',
    loadTags = '[Account] Load Tags',
    loadTagsSuccess = '[Account] Load Tags Success',
    loadSettingsFirstSteps = '[Account] Load Settings First Steps',
    loadProposalFirstSteps = '[Account] Load Proposal First Steps',
    loadSettingsFirstStepsSuccess = '[Account] Load Settings First Steps Success',
    loadProposalFirstStepsSuccess = '[Account] Load Proposal First Steps Success',
}

export const loadAccount = createAction(AccountActionTypes.loadAccount);
export const loadAccountSuccess = createAction(
    AccountActionTypes.loadAccountSuccess,
    props<{ account: Account }>()
);
export const loadAccountFailure = createAction(
    AccountActionTypes.loadAccountFailure,
    props<{ error: any }>()
);

export const loadSettingsFirstSteps = createAction(AccountActionTypes.loadSettingsFirstSteps);
export const loadSettingsFirstStepsSuccess = createAction(
    AccountActionTypes.loadSettingsFirstStepsSuccess,
    props<{ steps: any }>()
);

export const loadProposalFirstSteps = createAction(AccountActionTypes.loadProposalFirstSteps);
export const loadProposalFirstStepsSuccess = createAction(
    AccountActionTypes.loadProposalFirstStepsSuccess,
    props<{ steps: any }>()
);

export const loadNotifications = createAction(AccountActionTypes.loadNotifications);
export const loadNotificationsSuccess = createAction(
    AccountActionTypes.loadNotificationsSuccess,
    props<{ notifications: Notification[] }>()
);

export const loadTags = createAction(AccountActionTypes.loadTags);
export const loadTagsSuccess = createAction(
    AccountActionTypes.loadTagsSuccess,
    props<{ tags: AccountTag[] }>()
);