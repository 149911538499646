<div class="dialog-content-wrapper" id="create-client">
    <mat-toolbar matDialogTitle class="m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Logs de ação do orçamento</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar> 

    <div mat-dialog-content class="p-24 pb-0 m-0">
        <app-list-logs [logs]="logs$"></app-list-logs>
    </div>

    <div mat-dialog-actions class="m-0 p-16 border-top" fxLayout="row" fxLayoutAlign="end center">
        <span>
            <button class="btn btn-create" (click)="matDialogRef.close()" aria-label="Fechar">
                <span>Fechar</span>
            </button>
        </span>
    </div>
</div>