<ng-container *ngIf="elegibleSurvey">
  <div id="nps-survey-wrapper">
    <div [formGroup]="npsForm" class="survey-content"  [ngClass]="{'expanded':expanded}">
      <div class="survey-header" (click)="toggleVisibility()">
        {{ elegibleSurvey.name }}
        <button   mat-icon-button aria-label="Fechar">
          <mat-icon *ngIf="expanded">expand_more</mat-icon>
          <mat-icon *ngIf="!expanded">expand_less</mat-icon>
        </button>
      </div>
      <div class="survey-body">
        <div class="score-container">
          <mat-button-toggle-group
          [ngClass]="{ neutral: npsForm.controls.score.value > 6 && npsForm.controls.score.value < 9, promoter: npsForm.controls.score.value > 8 }"
          [disabled]="loading" 
          appearance="legacy"
          class="nps-score-group"
          >
            <mat-button-toggle (click)="getAnswers(score)" [ngClass]="{ 'nps-button-in-range': score < npsForm.controls.score.value }" class="nps-score-button" *ngFor="let score of scores" [value]="score">
              {{ score }}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div class="likelyness-container">
            <p>Pouco provável</p>
            <p>Muito provável</p>
          </div>
        </div>
        <div class="answer-container" [ngClass]="{'shown':shown && expanded}">
            <h4>{{chipsQuestion}}</h4>
            <mat-chip-list class="nps-answer-group" [multiple]="true">
                <mat-chip class="nps-answer" *ngFor="let answer of answers"
                [selectable]="true"
                [removable]="true"
                [selected]="npsForm.controls.answers.value.includes(answer.id)"
                [disabled]="loading"
                (click)="toggleChip(answer.id)"
                >{{ answer.answer }}</mat-chip>
            </mat-chip-list>
            <mat-error *ngIf="npsForm.controls.answers.errors">
                Selecione pelo menos uma opção acima
            </mat-error>
            <h4>Alguma outra sugestão?</h4>
            <textarea [disabled]="loading" class="message-field" formControlName="message" rows="4" placeholder="Digite sua mensagem"></textarea>
            <mat-error *ngIf="npsForm.controls.message.errors">
                Nos dê mais detalhes a respeito do <b>"Outro"</b> motivo.
            </mat-error>
        </div>
      </div>
      <div class="survey-footer">
        <button class="btn btn-blue ml-auto"  [disabled]="loading || npsForm.invalid"  *ngIf="answers?.length > 0" (click)="enviar()"  fxLayout="row" fxLayoutAlign="start center">
            <mat-spinner *ngIf="loading" class="mr-16" diameter="20"></mat-spinner>
           Enviar
          </button>
      </div>
    </div>
  </div>
</ng-container>
