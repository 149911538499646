import { Injectable } from '@angular/core';
import { Payment } from 'app/entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private httpService: HttpService
    ) { }

    findAll(): Observable<Payment[]> {
        return this.httpService.get('/v1/clients/payments').pipe(
            map(res => Payment.parse(res.data) as Payment[])
        );
    }

    findById(id): Observable<Payment> {
        return this.httpService.get(`/v1/clients/payments/${id}`).pipe(
            map(res => Payment.parse(res.data) as Payment)
        );
    }
}