import { Account } from "./account";
import { PaymentPix } from "./payment-pix";
import { PlanSubscription } from "./plan-subscription";
import { Decodable } from "./_decodable";

class Payment extends Decodable {
    id: number;
    uuid: String;
    accountId: number;
    planSubscriptionId: number;
    planPaymentInstallmentId: number;
        
    total: number;
    totalReived?: number;
    paymentMethod?:number
    taxAmount?:number
    
    subtotal?:number
    discount?:number

    refunded: number;
    status: number;
    statusFormatted: string;
    statusClass: string;
    statusTransactionFormatted
    paidAt: Date;
    refundedAt: Date;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    payble: any
    paybleType: string;
    paybleId: number;

    account?: Account
    subscription?: PlanSubscription
    paymentPix?: PaymentPix

    protected _map = {
        paidAt: (e) => this.parseDate(e),
        subscription: (e) => PlanSubscription.parse(e),
        paymentPix: (e) => PaymentPix.parse(e),
        refundedAt: (e) => this.parseDate(e),
        createdAt: (e) => this.parseDate(e),
        updatedAt: (e) => this.parseDate(e),
        deletedAt: (e) => this.parseDate(e),
    };
}

export { Payment };
