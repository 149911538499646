<div class="proposal-actions-wrapper">
    <button (click)="matDialogRef.close()" class="dialog-close-button" mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
    </button>

    <h2 class="text-center">
        Orçamento criado com sucesso!
    </h2>
    <p class="text-center">
        Agora basta escolher abaixo, a forma que você quer enviá-la para o seu cliente.
    </p>
    <span>
        <button *ngIf="proposal?.client?.phone"
            (click)="sendWhatsapp('whatsapp')"
            mat-menu-item
            aria-label="Enviar por Whatsapp">
            <mat-icon>phone_android</mat-icon>
            <span>Enviar por Whatsapp</span>
    </button>
    </span>
    <span>
        <button *ngIf="proposal?.client?.email"
            [matMenuTriggerFor]="contactsMenu"
            mat-menu-item
            aria-label="Enviar por email">
            <mat-icon>email</mat-icon>
            <span>Enviar por Email</span>
    </button>
    </span>
    <button (click)="copy('link')" mat-menu-item aria-label="Copiar">
        <mat-icon>share</mat-icon>
        <span>Copiar link</span>
    </button>
    <a (click)="openPDF()" mat-menu-item aria-label="Gerar PDF">
        <mat-icon>picture_as_pdf</mat-icon>
        <span>Gerar PDF</span>
    </a>
    <mat-divider></mat-divider>
    <button (click)="preview()" mat-menu-item aria-label="confirmar">
        <mat-icon>pageview</mat-icon>
        <span>Visualizar orçamento</span>
    </button>
    <button (click)="goHome()" mat-menu-item aria-label="confirmar">
        <mat-icon>chevron_left</mat-icon>
        <span>Voltar para Home</span>
    </button>

    <mat-menu #contactsMenu="matMenu">
        <button (click)="sendEmail(null)" mat-menu-item aria-label="enviar email">
            <mat-icon>email</mat-icon>
            <span>Todos os Contatos</span>
        </button>

        <button *ngIf="proposal?.client?.contacts.length" (click)="sendEmail(null, proposal?.client?.email)" mat-menu-item aria-label="enviar email">
            <mat-icon>star</mat-icon>
            <span>
                {{proposal?.client?.responsible ? proposal?.client.responsible : proposal?.client?.name}}
            </span>
        </button>

        <button *ngFor="let contact of proposal?.client?.contacts" (click)="sendEmail(contact)" mat-menu-item aria-label="aprovar">
            <mat-icon>alternate_email</mat-icon>
            <span>{{contact.name}}</span>
        </button>
    </mat-menu>
</div>