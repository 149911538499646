import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { Client } from 'app/entities';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-drop-down-client-create',
  templateUrl: './card-drop-down-client-create.component.html',
  styleUrls: ['./card-drop-down-client-create.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'form-menu' }
    }
  ]
})
export class CardDropDownClientCreateComponent implements OnInit {

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @Output() onCreated = new Subject<Client>();

  menuOpen = false;
  loading = false

  constructor(
    private _subscriptionRestrictionService: SubscriptionRestrictionService,
  ) { }

  ngOnInit(): void {

  }

  open() {
    if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("clientes")){
        this.menuTrigger.openMenu(); // Open the menu
        this.menuOpen = true;
    }
  }

  created(client) {
    console.log('created',client);
    this.onCreated.next(client)
    this.close();
    
  }

  close() {
    this.menuTrigger.closeMenu()
  }

}
