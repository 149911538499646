import { Decodable } from "./_decodable"


class CreditCard extends Decodable
{
    id: number | null = null
    name: string | null = null
    accountId: number | null = null
    cardBrand : string | null = null
    cardCvv: string | null = null
    cardNumber: string | null = null
    cardToken: string | null = null
    cardHolder: string | null = null
    cardHolderCpfCnpj: string | null = null
    cardExpirationMonth: string | null = null
    cardExpirationYear: string | null = null
    createdAt: Date | null = null
    updatedAt: Date | null = null

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export{
    CreditCard
}