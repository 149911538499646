import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaymentService } from 'app/services/http/payment.service';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadPayments, loadPaymentsFailure, loadPaymentsSuccess } from './payment.actions';

@Injectable()
export class PaymentEffects {

    loadPayments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPayments),
            switchMap(() =>
                this.paymentService.findAll().pipe(
                    map((payments) => loadPaymentsSuccess({ payments })),
                    catchError((error) => of(loadPaymentsFailure({ error }))))
            )
        )
    )

    constructor(
        private actions$: Actions,
        private paymentService: PaymentService
    ) { }


}