import { NgModule } from "@angular/core";
import { UsageCounterComponent } from "./usage-counter.component";
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        UsageCounterComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        UsageCounterComponent
    ],
    entryComponents: [
    ],
})
export class UsageCounterModule {}
