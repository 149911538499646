import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Client } from 'app/entities';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { TourService } from 'app/services/tour.service';
import { loadProposalFirstSteps } from 'app/state/account/account.actions';
import { selectProposalFirstSteps } from 'app/state/account/account.selectors';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-card-account-first-proposals',
  templateUrl: './card-account-first-proposals.component.html',
})
export class CardAccountFirstProposalsComponent implements OnInit, OnDestroy {

  @Output() onCreated = new Subject<Client>();

  private _unsubscribeAll = new Subject();

  steps:any = null

  constructor(
    private _router: Router,
    private _subscriptionRestrictionService: SubscriptionRestrictionService,
    private store: Store,
    private _tourService: TourService
  ) { }
    
  
  ngOnDestroy(): void {
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete();
    }

  ngOnInit(): void {
    this.store.select(selectProposalFirstSteps).pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        takeUntil(this._unsubscribeAll)
    ).subscribe(steps=>{
        this.steps = steps
    })

    this.store.dispatch(loadProposalFirstSteps());
  }

  newProposal() {
    if (!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("propostas")) {
        this._router.navigate(['/propostas/new'])
    }
}
 
  skipSteps(key: string): void {

  }

  doAction(key){
    console.log("doAction: ",key);

    if(!this.steps.has_first_proposal){
        return
    }

    let steps =  [
        {
            element: '#proposal-item',
            title:'Acesse as opções para executar ações nos seus orçamentos',
            intro: 'Nas opções do orçamento, você pode executar diversas ações como aprovar, reprovar, faturar, copiar link, enviar por email ou whatsapp, gerar PDF, duplicar e muito mais',
            position: 'bottom',
            disableInteraction:true,
            scrollToElement:true,
        }
    ]

    let tour = this._tourService.init({
        steps: steps
    })

    tour.start()
    
  }

  redirectTo(navigation) {
    this._router.navigate(navigation);
  }
}
