import { User } from './user'
import { Decodable } from './_decodable'

class ProductCategory extends Decodable {

    id?: number
    title?: string
    status?: number

    protected _map = {
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
    }
}

export {
    ProductCategory
}