
<button #menuTrigger="matMenuTrigger"
[matMenuTriggerFor]="dropDown" 
[class.btn]="!responsiveBtn"
[class.btn-green]="!responsiveBtn"
[class.custom-rounded-icon-button]="responsiveBtn"
[class.custom-rounded-icon-button-green]="responsiveBtn"
[style.border]="responsiveBtn ? 'none' : null"
fxLayout="row" 
fxLayoutAlign="center center"
(click)="newProduct()"
> 
    <ng-content ></ng-content>
</button>
<mat-menu class="client-create-menu" #dropDown="matMenu">
    <div class="card-filter" id="card-filter" (click)="$event.stopPropagation()">

        <div class="card-client-create-header form-menu-header">
            <div class="card-client-create-header-title form-menu-header-title">
                Adicionar Produto

                <div class="form-menu-close-btn">
                    <button mat-icon-button (click)="menuTrigger.closeMenu()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="card-client-create-content form-menu-content">
            <app-form-product-create-or-update 
            [showStatusInput]="showStatusInput"
            [manuTrigger]="menuTrigger"
            (onCreatedProduct)="created($event)"></app-form-product-create-or-update>
        </div>

    </div>
</mat-menu>