import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { NotificationsModule } from '@fuse/components/notifications/notifications.module';
import { AvatarModule } from 'ngx-avatar';
import { DirectivesModule } from 'app/directives/directives.module';
import { UsageCounterModule } from 'app/main/components/usage-counter/usage-counter.module';
@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        AvatarModule,
        FuseSharedModule,
        NotificationsModule,
        DirectivesModule,
        UsageCounterModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}