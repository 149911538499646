import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialogRef
} from "@angular/material/dialog";
import { Client } from "app/entities";
import { ClientsService } from "app/main/pages/clients/clients.service";
import { AlertService } from "app/services/alert.service";
import { SubscriptionRestrictionService } from "app/services/subscription-restriction.service";

@Component({
    selector: "app-create-client-dialog",
    templateUrl: "./create-client-dialog.component.html",
    styleUrls: ["./create-client-dialog.component.scss"],
})
export class CreateClientDialogComponent implements OnInit, AfterViewInit {
    @ViewChild("clientName") clientName?: ElementRef;
    client: Client;
    mode: number; //0=full|1=compact
    form!: FormGroup;
    title!: string;
    loading = false;

    cpfMask = "000.000.000-009";
    cnpjMask = "00.000.000/0000-00";
    cpfCnpjMask = this.cpfMask;

    checkMask(event) {
        this.switchMask(event.target.value);
    }

    switchMask(value) {
        try {
            value = value.replace(/[^0-9]/g, "");
        } catch (e) {}

        if (value.length <= 11) {
            this.cpfCnpjMask = this.cpfMask;
        } else {
            this.cpfCnpjMask = this.cnpjMask;
        }
    }

    constructor(
        public matDialogRef: MatDialogRef<CreateClientDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _alertService: AlertService,
        private _clientsService: ClientsService,
        private _subscriptionRestrictionService: SubscriptionRestrictionService,
    ) {
        this.client = this._data.client;
        this.mode = this._data.mode ? this._data.mode : 0;
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.clientName?.nativeElement.focus();
        }, 300);
    }

    ngOnInit() {

        if (this.client.id) {
            this.title = "Editar Cliente";
        } else {
            this.title = "Adicionar Cliente";
        }

        this.form = this.createForm();

        if (this.client.contacts != null && this.client.contacts.length > 0) {
            for (var ctc of this.client.contacts) {
                this.addContact(ctc?.name!, ctc?.email!);
            }
        } else {
            this.addContact();
        }
    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.client.id, []],
            name: [this.client.name, [Validators.required]],
            email: [this.client.email, [Validators.email]],
            phone: [this.client.phone, []],
            cpf_cnpj: [this.client.cpfCnpj, []],
            address: [this.client.address, []],
            responsible: [this.client.responsible, []],
            contacts: this._formBuilder.array([]),
            image: [null, []],
        });
    }

    onAvatarChanged(event: File) {
        this.form.patchValue({
            image: event,
        });
    }

    updateUser() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.loading = true;
            this._clientsService
                .update(this.client.id, this.form.value)
                .subscribe(
                    (response) => {
                        this.loading = false;
                        this.matDialogRef.close(response);
                    },
                    (err) => {
                        this.loading = false;
                        this._alertService.error(err);
                    }
                );
        } else {
            this._alertService.error("Preencha corretamente o formulário.");
        }
    }

    addContact(name = "", email = "") {
        const creds = this.form.controls.contacts as FormArray;
        creds.push(
            this._formBuilder.group({
                name: [name],
                email: [email],
            })
        );
    }

    contacts() {
        return this.form.controls.contacts as FormArray;
    }

    insertUser() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
                if(!this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("clientes")){
                    this.store();
                } 
        } else {
            this._alertService.error("Preencha corretamente o formulário.");
        }
    }

    store() {
        this._clientsService.store(this.form.value).subscribe(
            (response) => {
                this.loading = false;
                this.matDialogRef.close(response);
                this._subscriptionRestrictionService.reloadSubscriptionUsages()
            },
            (err) => {
                this.loading = false;
                this._alertService.error(err);
            }
        );
    }

    deleteUser() {}
}
