import { Component, OnInit, Input, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { ChartsService } from '../charts.service';
import { ProposalEventService } from 'app/services/proposal.service';
import { MatDialog } from '@angular/material/dialog';
import { Chart, LinearScale, LineController, LineElement, PointElement, registerables, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LocalStorageService } from 'app/services/local-storage.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
interface Meses {
    value: string;
    viewValue: string;
}
@Component({
    selector: 'app-status-proposals-doughnut-chart',
    templateUrl: './status-proposals-doughnut-chart.component.html',
    styleUrls: ['./status-proposals-doughnut-chart.component.scss']
})

export class StatusProposalsDoughnutChartComponent implements OnInit, OnDestroy {

    @Input('hashId') hashId?: string
    @Input('customerId') customerId?: string

    meses: Meses[] = [
        { value: '7', viewValue: '7 dias' },
        { value: '30', viewValue: '30 dias' },
        { value: '90', viewValue: '3 meses' },
        { value: '180', viewValue: '6 meses' },
        { value: '365', viewValue: '12 meses' }
    ];
    modelDias = '180'
    chart?: Chart;
    total = 0

    // component destroy subject
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    ngOnDestroy(): void {
        if(this.chart){
            this.chart.destroy();
        }
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    constructor(
        private chartsService: ChartsService,
        private _proposalEventService: ProposalEventService,
        public _matDialog: MatDialog,
        private elementRef: ElementRef,
        private _localStorage: LocalStorageService,
        private ngZone: NgZone
    ) {

    }


    ngOnInit() {
        Chart.register(...registerables);
        Chart.register(ChartDataLabels, LineController, LineElement, PointElement, LinearScale, Title);
        
        let dafaultDays = this._localStorage.getItem("default-days-status-proposals-doughnut-chart.component")
        if(dafaultDays) this.modelDias = dafaultDays;
    }

    ngAfterViewInit(){
        this.loadData()

        this._proposalEventService.proposal$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.loadData()
        });
    }

    loadData() {
        var params = {}

        params['dias'] = this.modelDias;

        if (this.hashId) {
            params['hashId'] = this.hashId;
        }

        if (this.customerId) {
            params['customerId'] = this.customerId;
        }

        this.chartsService.getTotalProposalsChart(params).subscribe(res => {
            if(this.chart){
                this.chart.destroy();
            }

            var data = res.data.map(line => {
                return line.value;
            })

            this.total = data.reduce((a, b) => a + b, 0)

            var labels = res.data.map(line => {
                return line.name;
            })

            var colorScheme = res.data.map(line => {
                return line.hexadecimal;
            })

            let htmlRef = this.elementRef.nativeElement.querySelector(`#canvas`);
            this.ngZone.runOutsideAngular(() => {
                this.chart = new Chart(htmlRef, {
                    type: "doughnut",
                    
                    data: {
                        labels: labels,
                        datasets: [{
                            type: "doughnut",
                            data: data,
                            backgroundColor: colorScheme,
                            borderRadius: 15,
                            
                        }],
                    },
                    options: {
                        aspectRatio: 1.7,
                        maintainAspectRatio: true,
                        rotation: -90,
                        circumference: 180,
                        responsive: true,
                        locale: 'pt-BR',
                        // layout: {
                        //     padding: {
                        //         bottom: 6,
                        //     }
                        // },
                        plugins: {
                            legend: {
                                // position: 'chartArea',
                                display	: false,
                                position: 'bottom',
                                // align: 'start',
                            },
                            tooltip: {
                                intersect:false,
                                callbacks: {
                                    label: function(context) {
                                        let label = context.label || '';
                                                                            
                                        if (label) {
                                            label += ': ';
                                        }
                                        if (context.parsed !== null) {
                                            label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed);
                                        }
                                        return label;
                                    },
                                }
                            },
                            datalabels: {
                                anchor:(context)=> {
                                    if(this.getChartDatasetValueTooSmall(context)){
                                        if(context.dataIndex % 2){
                                            return 'end'
                                        }
                                        return 'start'
                                    }
                                    return 'center'
                                },
                                align:(context)=>{
                                    if(this.getChartDatasetValueTooSmall(context)){
                                        if(context.dataIndex % 2){
                                            return 'start'
                                        }
                                        return 'end'
                                    }
                                    return 'center'
                                },
                                offset:(context)=>{
                                    return -7
                                },
                                borderRadius:20,
                                backgroundColor: (context)=>{
                                    if(this.getChartDatasetValueTooSmall(context)){
                                        return context.dataset.backgroundColor![context.dataIndex]
                                    }
                                    return 'transparent'
                                },
                                borderColor: (context)=>{
                                    if(this.getChartDatasetValueTooSmall(context)){
                                        return "#22222233"
                                    }
                                    return 'transparent'
                                },
                                borderWidth:2,
                                display: true,
                                formatter: (value, context)=>{
                                    var labelValue = parseInt((value / this.total * 100).toFixed(0))
                                    // if(labelValue < 5){
                                    //     return ''
                                    // }
                                    return labelValue + '%' 
    
                                    
                                },
                                font: {
                                    weight: 'bold'
                                },
                                color: function(context) {
                                    switch (context.dataset.backgroundColor[context.dataIndex]) {
                                        case '#FFCF26':
                                            return '#FFFFFF';
                                            break;
                                        case '#5BA5FE':
                                            return '#FFFFFF';
                                            break;
                                        case '#6AD49B':
                                            return '#FFFFFF';
                                            break;
                                        case '#F35D82':
                                            return '#FFFFFF';
                                            break;
                                        case '#8757ED':
                                            return '#FFFFFF';
                                            break;
                                        case '#E4E6EF':
                                            return '#636363';
                                            break;
                                        default:
                                            return '#FFFFFF'
                                            break;
                                    }
    
                                    var index = context.dataIndex;
                                    var value = context.dataset.data[index];
                                    return value < 0 ? 'red' :  // draw negative values in red
                                      index % 2 ? 'blue' :      // else, alternate values in blue and green
                                      'green';
                                }
                            }
                        }
                    }
                });  
            })
            
        })
    }

    smallValueThreshold = 8

    getChartDatasetValueTooSmall(context){
        var dataset = context.dataset;
        var value = parseFloat(dataset.data[context.dataIndex]+"");
        var percent = parseInt((value / this.total * 100).toFixed(0))
        return percent<this.smallValueThreshold;
    }
    getDatasetValueTooSmall(value){
        var percent = parseInt((value / this.total * 100).toFixed(0))
        return percent<this.smallValueThreshold;
    }
    
    changeDays() {
        this._localStorage.setItem("default-days-status-proposals-doughnut-chart.component", this.modelDias)

        this.loadData()
    }

    valueFormatting(value: number): string {
        if (!value) value = 0
        return (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })).format(value).replace('R$', '')
    }

}