import { Decodable } from './_decodable'

class ContatoFeedback extends Decodable {
    email:string
    message:string
    about:string
}

export {
    ContatoFeedback
}