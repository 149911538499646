import { ElementRef, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, } from 'rxjs/operators';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { EnvService } from './env.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private matDialog: MatDialog,
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private envService: EnvService,
        private router: Router
    ) { }

    private getHeaders(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };

        let currentUserToken = this.localStorageService.getItem('currentUserToken')

        if (currentUserToken) {
            headersConfig['Authorization'] = `Bearer ${currentUserToken}`;
        }
        return new HttpHeaders(headersConfig);
    }

    get(path: string, params: HttpParams | string | {
        [key: string]: any | any[];
    } | null = new HttpParams()): Observable<any> {
        if (typeof params == "string") {
            params = new HttpParams({ fromString: decodeURIComponent(params as string) })
        }

        return this.http.get(
            this.parsedUrl(path),
            { headers: this.getHeaders(), params: params as any })
            .pipe(catchError((err) => this.formatErrors(err)));
    }

    put(path: string, body: Object = {}): Observable<any> {
        let headers = this.getHeaders()
        if (!(body instanceof FormData)) {
            body = JSON.stringify(body)
        } else {
            headers = headers.delete("content-type")
        }
        return this.http.put(
            this.parsedUrl(path),
            body,
            { headers: headers }
        )
            .pipe(catchError((err) => this.formatErrors(err)));
    }

    post(path: string, body: Object = {}): Observable<any> {
        let headers = this.getHeaders()
        if (!(body instanceof FormData)) {
            body = JSON.stringify(body)
        } else {
            headers = headers.delete("content-type")
        }

        return this.http.post(
            this.parsedUrl(path),
            body,
            { headers: headers }
        )
            .pipe(catchError((err) => this.formatErrors(err)));
    }

    delete(path: string, params: HttpParams | string | {
        [key: string]: any | any[];
    } | null = new HttpParams()): Observable<any> {
        if (typeof params == "string") {
            params = new HttpParams({ fromString: decodeURIComponent(params as string) })
        }

        return this.http.delete(
            this.parsedUrl(path),
            { headers: this.getHeaders(), params: params as any })
            .pipe(catchError((err) => this.formatErrors(err)));
    }

    private parsedUrl(path: string) {
        let baseUrl: string = this.envService.apiUrl;
        let url = baseUrl.replace(/\/$/, "") + "/" + path.replace(/^\//, "")
        return url.replace(/\/$/, "")
    }

    private formatErrors(err: any) {

        if (err.status == 503) {
            this.matDialog.closeAll();
            if (this.router.url != '/erro/sistema-em-manutencao') {
                this.router.navigate(['erro/sistema-em-manutencao']);
            }
        }

        return throwError(this.formatErrosMessage(err));
    }

    formatErrosMessage(err: any) {
        let unknownError = {
            "status": 500,
            "message": "Houve um erro com a requisição.",
            "errors": []
        }

        if (err instanceof HttpErrorResponse) {
            let errorJson = err.error;
            if (errorJson) {
                errorJson.status = err.status
            }
            if (typeof errorJson.error == "string") {
                errorJson.message = errorJson.error;
            }

            return errorJson || unknownError;
        }

        if (err.error && err.error?.message) {
            let errorJson = {
                message: err.error?.message,
                status: err.error?.code
            };
            return errorJson;
        }

        if (err.errors) {
            let errors: string[] = [];

            for (const property in err.errors) {

                if (err.errors.hasOwnProperty(property)) {

                    // 6 - Extract it's array of errors
                    const propertyErrors: Array<string> = err.errors[property];

                    // 7 - Push all errors in the array to the errors array
                    propertyErrors.forEach(error => errors.push(error));
                }

            }


            let errorJson = {
                errors: errors,
                status: err.status
            };
            return errorJson;
        }

        console.log("errorJson || unknownError");
        return unknownError;
    }

    checkIfOutOfMaintenance(url: string) {
        return this.http.get(
            this.parsedUrl(url),
            { headers: this.getHeaders() }
        ).pipe(
            catchError((error) => {
                if (error.status === 503) {
                    // Ainda em manutenção
                    return of(false);
                }
                // Não está mais em manutenção
                return of(true);
            }),
            map(() => true)
        );
    }
}