import { createReducer, on } from "@ngrx/store";
import { User } from "app/entities";
import { Error, Status } from "../AppState";
import { loginUser, loginUserFailure, loginUserSuccess, logoutUserSuccess, updateUserSuccess } from "./user.actions";

export interface UserState {
    user: User | null,
    error: Error | null;
    status: Status | null;
}

export const initialState: UserState = {
    user: null,
    error: null,
    status: {
        isError: false,
        isFinished: false,
        statusName: 'pending'
    },
};

export const UserReducer = createReducer(
    initialState, 
    on(loginUser, (state) => ({
        ...state,
        status: {
            isError: false,
            isFinished: false,
            statusName: 'loading'
        },
    })),
    on(loginUserSuccess, (state, {user}) => ({
        ...state,
        user,
        status: {
            isError: false,
            isFinished: true,
            statusName: 'success'
        },
    })),
    on(logoutUserSuccess, (state) => ({
        ...state,
        user:null,
        status: {
            isError: false,
            isFinished: true,
            statusName: 'success'
        },
    })),
    on(updateUserSuccess, (state, {user}) => ({
        ...state,
        user,
    })),
    on(loginUserFailure, (state, {error}) => ({
        ...state,
        status: {
            isError: true,
            isFinished: true,
            statusName: 'error'
        },
        error
    })),
)