<div class="meta-card-prefix mb-12" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="layout==2">
    <div>
        <h2 class="card-title">Metas</h2>
        <div class="card-description">Acompanhe e crie metas para o usuário</div>
    </div>
    <div>
        <button class="btn btn-blue ml-auto"  *ngIf="metas?.length > 0" (click)="openDialogCreateMeta()">
        <span>Criar Meta</span>
      </button>
    </div>
</div>

<div class="fuse-card auto-width" id="goal-completion">
    

    <div *ngIf="metas?.length > 0" fxFlex="grow" fxLayout="column" #containerRef>
        <div class="py-16" fxLayout="row" fxLayoutAlign="space-between center" class="p-16"  *ngIf="layout==0">
            <div class="dashboard-card-title">Metas</div>
            

            <button *ngIf="metas?.length > 0" (click)="openDialogCreateMeta()" class="btn-create-meta"  fxLayout="row" fxLayoutAlign="space-between center">
                <span>Criar Meta</span> 
            </button>
        </div>
        
        <div class="client-container {{layout==0 || layout==2 ? 'height-350px' : ''}}">
            <ng-container *ngFor="let meta of metas">
                <div class="client-row">
                    <div class="client-contant">
                        <div class="client-image">
                            <ngx-avatar [size]="40" [round]="true" initialsSize="2" [name]="meta.customer.name" [src]="meta.customer.image">
                            </ngx-avatar>
                        </div> 
                        <div fxLayout="column" fxFlex="100">
                            <div class="row-items">
                                <a class="client-name">
                                    {{meta.customer.name}}
                                </a>
        
                                <div class="total-payed" matTooltip="Valor da meta" matTooltipPosition="above">
                                    <span>R$</span> <strong>{{formatValue(meta.valueMeta)}}</strong>
                                </div>
                            </div>
        
                            <div class="row-items">
                                <div class="total-genereted-proposals">
                                    <ng-container *ngIf="meta?.isActiva">
                                        Finaliza {{meta?.endAt | date: 'dd/MM'}}
                                    </ng-container>

                                    <ng-container *ngIf="!meta?.isActiva">
                                        <div fxLayoutAlign="start center">
                                            <span class="meta-card-status"
                                            [ngClass]="meta?.statusClass">{{meta?.statusName}}</span>
                                            Finalizou {{meta?.endAt | date: 'dd/MM'}}
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="total-in-aproved-proposals {{meta?.classColor}}" matTooltip="Orçamentos aprovados" matTooltipPosition="below">
                                    {{toFixed(meta?.score)}}% - R${{formatValue(meta.totalApproved)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-progress-bar">
                        <mat-progress-bar [ngClass]="meta?.classColor" mode="determinate" [value]="meta?.score">
                        </mat-progress-bar>

                        <ng-container *ngIf="meta?.isActiva">
                            <button class="btn-more" *ngIf="true" mat-icon-button color="accent" type="button"
                                [matMenuTriggerFor]="optionsMetaMenu" (click)="$event.stopPropagation();">
                                <mat-icon class="secondary-text">
                                    more_vert</mat-icon>
                            </button>
                        </ng-container>
    
                        <mat-menu #optionsMetaMenu="matMenu">
                            <button color="warn" (click)="editMeta(meta)" mat-menu-item aria-label="confirmar">
                                <mat-icon>tune</mat-icon> Editar
                            </button>
                            <button color="warn" (click)="deleteMeta(meta)" mat-menu-item
                                aria-label="confirmar">
                                <mat-icon>delete</mat-icon> Excluir
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </ng-container>
        </div>
        
    </div>

    <div class="card-configure-proposal"
                *ngIf="metas?.length == 0"
            >
        <div>
            <div class="dashboard-card-title">
                Defina uma Meta {{customerId?'':'Para Você e Sua Equipe'}}
            </div>
        
            <div class="card-subtitle">
                Para atingir um objetivo é preciso estipular metas. Defina o valor e o prazo e acompanhe seu progresso!
            </div>
        
            <div class="container-btn">
                <button
                    (click)="openDialogCreateMeta()"
                >
                    Criar Meta
                </button>
            </div>
        </div>

        <div class="container-img">
            <img src="https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/spbdIDFQJu8NwRdyZhdUBlPJuqgYFO6J74gsuwq9.jpg">
        </div>

    </div>
</div>