<div class="usummernote-wrapper">
<textarea 
[(ngModel)]="value" 
[placeholder]="placeholder" 
(input)="onChange(value)" 
(blur)="onTouched()" 
(imageUpload)="onImageUpload($event)" 
[ungxSummernote]="smSettings" 
[ngxSummernoteDisabled]="disabled" 
fxFlex 
style="padding: 15px 0px 1px 0px"></textarea>
<div class="loading-wrapper" [ngClass]="{'loading':disabled}">
    <div class="loading-block"><mat-spinner [diameter]="40"></mat-spinner> <span>Fazendo upload...</span></div>
</div>
</div>