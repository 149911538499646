<div class="image-wrapper">
    <img (click)="fileInput.click()" [src]="_src" accept="image/*" />
    <button *ngIf="excludable" (click)="deleteImage($event)" color="primary" class="mat-accent mat-icon-button-excludable">
        <mat-icon>delete</mat-icon>
    </button>
    <!-- <button *ngIf="editable" (click)="fileInput.click()" color="primary" class="mat-accent mat-icon-button-editable">
        <mat-icon>edit</mat-icon>
    </button> -->
</div>
<div #fileInput (click)="fileChange($event)" hidden ></div>
<!-- <input type="file" name="image" [accept]="accept"> -->