import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EnvService } from 'app/services/env.service';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {

  @Input() videoUrl!: string;
  @ViewChild('videoPlayer') videoElementRef!: ElementRef<HTMLVideoElement>;
  isPlaying: boolean = false;
  showControls: boolean = false;
  showBackDrop: boolean = true;

  constructor(private envService: EnvService) { }

  ngOnInit(): void {
  }

  playVideo(): void {
    const videoPlayer = this.videoElementRef.nativeElement;
    videoPlayer.play();
    this.isPlaying = true;
    this.showControls = true;
    this.showBackDrop = false;
  }

}
