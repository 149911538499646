<th>{{title}}</th>
<ng-container *ngFor="let planPayment of planPayments">
    <ng-container *ngFor="let feature of planPayment?.plan?.features">
        <td *ngIf="feature.slug == slug" [ngSwitch]="feature.label">
            <img *ngSwitchCase="'true'" src="/assets/svg/icons/check-blue-small.svg" alt="">
            <img *ngSwitchCase="'false'" src="/assets/svg/icons/icon-no.svg" alt="">
            <ng-container *ngSwitchCase="'*'">Ilimitado</ng-container>
            <ng-container *ngSwitchDefault>{{feature.label}}</ng-container>
        </td>
    </ng-container>
</ng-container>
