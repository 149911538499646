import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Store } from '@ngrx/store';
import { User } from 'app/entities';
import { ContatoFeedbackDialogComponent } from 'app/main/components/contato-feedback/contato-feedback.component';
import { navigation } from 'app/navigation/navigation';
import { AlertService } from 'app/services/alert.service';
import { AuthService } from 'app/services/auth.service';
import { selectUser } from 'app/state/user/user.select';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';
import { ReferralDialogComponent } from 'app/main/components/referral-dialog/referral-dialog.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('settingsMenuButton') languageMenuTrigger;
    user?: User | null;
    userName: String = ""
    horizontalNavbar?: boolean;
    rightNavbar?: boolean;
    hiddenNavbar?: boolean;
    navigation: any;
    whatsappLink = ''
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _alertService: AlertService,
        private _router: Router,
        private _authService: AuthService,
        private store:Store,
        private _fuseNavigationService: FuseNavigationService,
        public subscriptionRestrictionService: SubscriptionRestrictionService
    ) {

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.store.select(selectUser)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                this.user = user
                let userNameSplit = this.user?.name?.split(" ", 3)
                if (userNameSplit) {
                    this.userName = `${userNameSplit![0] ?? ""} ${userNameSplit![1] ?? ""}`
                }

                this.whatsappLink = `https://api.whatsapp.com/send?phone=556238775962&text=${encodeURI(user?.account?.id + ' - ' + user?.email + ' Olá! Preciso de ajuda.')}`;


                this._fuseNavigationService.updateNavigationItem('whatsapp-contact', {
                    url: this.whatsappLink,
                });

                this._fuseNavigationService.updateNavigationItem('agendar-apresentação', {
                    url: "https://calendar.app.google/So8HtjH1FP4UAaeN6",
                });

                this._fuseNavigationService.updateNavigationItem('suport-email', {
                    function: () => {
                        this.openDialogFeedback()
                    }
                });
            });


        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

    }
    
    openMenu() {
        this.languageMenuTrigger.openMenu();
    }

    closeMenu() {
        this.languageMenuTrigger.closeMenu();
    }

    redirectTo(navigation) {
        this._router.navigate(navigation)
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    logout() {
        this._authService.logMeOut()
    }

    profile() {
        this._router.navigate(['/configuracao/perfil'])
    }

    openDialogFeedback() {
        let dialog = this._matDialog.open(
            ContatoFeedbackDialogComponent,
            { panelClass: ["feedback-container"] }
        );
        dialog.afterClosed().subscribe((result) => {

        });
    }

    notificationRedirect(url = null) {
        if (url) {
            this._router.navigate([url])
        }
    }

    openReferralDialog() {
        let dialog = this._matDialog.open(ReferralDialogComponent, {
            panelClass: ['form-dialog','big-radius-dialog'],
        });
    }
} 