import { Component, ElementRef, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-file-input-component',
  templateUrl: './file-input-component.component.html',
  styleUrls: ['./file-input-component.component.scss']
})
export class FileInputComponentComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() reset = false;
  @Input() loading = false;
  @Output() fileSelected = new EventEmitter<File>();
  fileDropped = false;
  @Input() state = 'idle'
  @Input() label = null
  showDetails = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('simpleChanges: ', changes);
    if (changes.reset) {
      this.resetFileInput();
    }
  }

  onDragOver(event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  onDragLeave(event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  onFileSelected(event): void {
    const file: File = event.target.files[0];
    console.log('Selected file: ', file.name);
    this.fileSelected.emit(file);
    this.fileDropped = true;
  }

  onFileDropped(event): void {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    console.log('Dropped file: ', files[0].name);
    this.fileSelected.emit(files[0]);
    this.fileDropped = true;
  }

  resetFileInput(): void {
    if (!this.fileInput) return
    this.fileInput.nativeElement.value = '';
    this.fileDropped = false;
    this.state = 'idle'
    this.label = null
  }

  toggleDetails(event: Event): void {
    event.stopPropagation();
    this.showDetails = !this.showDetails;
  }
  
}
