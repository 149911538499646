import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
  selector: 'app-proposal-appearence-tutorial-modal',
  templateUrl: './proposal-appearence-tutorial-modal.component.html',
  styleUrls: ['./proposal-appearence-tutorial-modal.component.scss']
})
export class ProposalAppearenceTutorialModalComponent implements OnInit {
  videoUrl = `https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/pernalizando-sua-proposta-desktop-processed.m4v`;
  desktopVideoUrl: string = `https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/pernalizando-sua-proposta-desktop-processed.m4v`;
  mobileVideoUrl: string = 'https://orca2-cdn.nyc3.digitaloceanspaces.com/files/backoffice/personalizando-sua-proposta-mobile-processed.m4v';

  constructor(
    public dialogRef: MatDialogRef<ProposalAppearenceTutorialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _localStorage: LocalStorageService,
  ) { 
    this.setVideoUrl(window.innerWidth);
  }
  
  ngOnInit(): void {
    this.dialogRef.afterClosed().toPromise().then(()=>{
        console.log("dialog clozedddd");
        this._localStorage.setItem('showProposalAppearenceTutorial', false);
    });
  }

  dismissModalOnStorage(): void {
    this.dialogRef.close()
  }

  private setVideoUrl(width: number): void {
    this.videoUrl = width > 768 ? this.desktopVideoUrl : this.mobileVideoUrl;
  }

}
