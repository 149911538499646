<div fxLayout="row" fxLayoutAlign="start start" fxFlex="100" [formGroup]="form">

    <mat-card fxFlex="100">
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px">
                <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon class="m-0 mr-16">
                        info
                    </mat-icon>
                    <div class="h2 secondary-text">Informações</div>
                </div>

            </div>


            <!-- <div class="label-lg">Status</div> -->
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="100" class="select-status {{selectedStatus?.class}}">
                    <mat-label>Status</mat-label>
                    <mat-select 
                        name="status"  
                        formControlName="status" 
                        [disabled]="proposal?.status == 7 || !canEditStatus" 
                        (selectionChange)="statusChanged($event)"
                        matTooltip="Somente administradores podem alterar o status da proposta."
                        [matTooltipDisabled]="canEditStatus"
                    >

                        <mat-option *ngFor="let status of proposalStatus" [value]="status.value" [class]="status.class" [disabled]="status.value == 7">
                            {{status.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="column" fxLayoutGap="10px" *ngIf="false">
                <mat-form-field appearance="outline" fxFlex="100"
                    matTooltip="Somente administradores podem atribuir um responsável."
                    [matTooltipDisabled]="canEditCustomer"
                >
                    <mat-label>Responsável</mat-label>
                    <mat-select formControlName="responsible_id" [disabled]="!canEditCustomer" (selectionChange)="responsibleChanged($event)">
                        <!-- Trigger personalizado que exibe a imagem e o nome do usuário selecionado -->
                        <mat-select-trigger>
                            <div class="user-trigger" *ngIf="selectResponsible">
                                <ngx-avatar 
                                    [src]="selectResponsible?.image" 
                                    [round]="true" 
                                    [initialsSize]="2" 
                                    size="30px"
                                    initialsSize="30px" 
                                    [name]="selectResponsible?.name"
                                    class="user-photo"
                                ></ngx-avatar>

                                <span>{{ selectResponsible.name }}</span>
                            </div>
                        </mat-select-trigger>

                        <mat-option [value]="null">
                            Não atribuído
                        </mat-option>
                        
                        <mat-option *ngFor="let user of users" [value]="user?.id">
                            <div class="user-option">
                                <ngx-avatar 
                                    [src]="user?.image" 
                                    [round]="true" 
                                    [initialsSize]="2" 
                                    size="30px"
                                    initialsSize="30px" 
                                    [name]="user?.name"
                                    class="user-photo"
                                ></ngx-avatar>
                                <span>{{ user.name }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <a class="assign-to-me" 
                    *ngIf="!selectResponsible || selectResponsible?.id != user?.id" 
                    (click)="assignToMeResponsible()"
                    matTooltip="Somente administradores podem atribuir um responsável."
                    [matTooltipDisabled]="canEditCustomer"
                    [routerLink]=""
                    
                >
                    Atribuir a mim
                </a>

            </div>

            <!-- <div class="label-lg">..</div> -->
            <div fxLayout="row" fxLayoutGap="10px" *ngIf="selectedCustomer">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Criado Por</mat-label>
                    <mat-select [value]="selectedCustomer?.id" formControlName="customer_id" [disabled]="!canEditCustomer" tooltip>
                        <!-- Trigger personalizado que exibe a imagem e o nome do usuário selecionado -->
                        <mat-select-trigger>
                            <div class="user-trigger" *ngIf="selectedCustomer">
                                <!-- <mat-icon  class="role-prefix-icon">admin_panel_settings</mat-icon> -->
                                <ngx-avatar 
                                    [src]="selectedCustomer?.image" 
                                    [round]="true" 
                                    [initialsSize]="2" 
                                    size="30px"
                                    initialsSize="30px" 
                                    [name]="selectedCustomer?.name"
                                    class="user-photo"
                                ></ngx-avatar>
                                <span>{{ selectedCustomer?.name }}</span>
                            </div>
                        </mat-select-trigger>

                        <!-- Opções de seleção com imagem e nome -->
                        <mat-option [value]="selectedCustomer?.id">
                            <div class="user-option">
                                <ngx-avatar 
                                    [src]="selectedCustomer?.image" 
                                    [round]="true" 
                                    [initialsSize]="2" 
                                    size="30px"
                                    initialsSize="30px" 
                                    [name]="selectedCustomer?.name"
                                    class="user-photo"
                                ></ngx-avatar>
                                <span>{{ selectedCustomer?.name }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <ng-container *ngIf="proposal?.status != 7">
                <div class="label-lg">Data de Alterações</div>
                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Data de Envio</mat-label>
                        <input matInput [matDatepicker]="pickerSented" formControlName="sented_at">
                        <mat-datepicker-toggle matSuffix [for]="pickerSented"></mat-datepicker-toggle>
                        <mat-datepicker #pickerSented></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Data de Reprovação</mat-label>
                        <input matInput [matDatepicker]="pickerRefused" formControlName="refused_at">
                        <mat-datepicker-toggle matSuffix [for]="pickerRefused"></mat-datepicker-toggle>
                        <mat-datepicker #pickerRefused></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Data de Aprovação</mat-label>
                        <input matInput [matDatepicker]="pickerApproved" formControlName="approved_at">
                        <mat-datepicker-toggle matSuffix [for]="pickerApproved"></mat-datepicker-toggle>
                        <mat-datepicker #pickerApproved></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Data de Faturamento</mat-label>
                        <input matInput [matDatepicker]="pickerBilled" formControlName="billed_at">
                        <mat-datepicker-toggle matSuffix [for]="pickerBilled"></mat-datepicker-toggle>
                        <mat-datepicker #pickerBilled></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Data de Pagamento</mat-label>
                        <input matInput [matDatepicker]="pickerPaid" formControlName="paid_at">
                        <mat-datepicker-toggle matSuffix [for]="pickerPaid"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPaid></mat-datepicker>
                    </mat-form-field>
                </div>
            </ng-container>

            <button *ngIf="proposal?.id && !loading" (click)="save()" class="w-100-p btn btn-create"
                ngClass.gt-xs="mt-8" type="button" color="primary"
                [disabled]="!this.form.valid || !this.form.dirty || !this.proposal.id || loading"
                >
                Salvar
            </button>

            <div class="loading-overlay" [ngClass]="{'isloading':loading}">
                <h3>Salvando Orçamento...</h3>
                <mat-spinner></mat-spinner>
            </div>
        </mat-card-content>
    </mat-card>
</div>