import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CopyClipboardDirective } from "./copy-clipboard.directive";
import { ClearFieldIfDirective } from "./clear-field-if.directive";
import { EnsureMinValueDirective } from "./ensure-min-value";
import { SkeletonDirective } from "./skeleton-loader.directive";
import { ScrollOnFocusDirective } from "app/directives/scroll-on-focus.directive";
import { CanShowDirective } from "./can-show.directive";
import { UNgxSummernoteDirective } from "./customsummernote/usummernote.directive";
import { UNgxSummernoteViewDirective } from "./customsummernote/usummernote-view.directive";

@NgModule({
    declarations: [
        CanShowDirective,
        CopyClipboardDirective,
        ClearFieldIfDirective,
        EnsureMinValueDirective,
        SkeletonDirective,
        ScrollOnFocusDirective,
        UNgxSummernoteDirective,
        UNgxSummernoteViewDirective
    ],
    exports: [
        CanShowDirective,
        CopyClipboardDirective,
        ClearFieldIfDirective,
        EnsureMinValueDirective,
        SkeletonDirective,
        ScrollOnFocusDirective,
        UNgxSummernoteDirective,
        UNgxSummernoteViewDirective
    ],
})
export class DirectivesModule {
    static forRoot() {
        return {
            ngModule: DirectivesModule,
            providers: [],
        };
    }
}
