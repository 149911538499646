import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import {
    MatMomentDateModule,
    MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";

import localePt from "@angular/common/locales/pt";

import {
    FuseConfirmDialogModule,
    FuseMaterialColorPickerModule,
    FuseProgressBarModule,
    FuseSidebarModule,
} from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig } from "app/config";

import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CurrencyPipe, registerLocaleData } from "@angular/common";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { APP_INITIALIZER } from '@angular/core';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GtagModule } from "angular-gtag";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { environment } from "environments/environment";
import { NgxMaskModule } from "ngx-mask";
import { SubscriptionGuard } from "./guards/subscription.guard";
import { ComponentsModule } from "./main/components/components.module";
import { ContatoFeedbackDialogComponent } from "./main/components/contato-feedback/contato-feedback.component";
import { AuthInterceptor } from "./services/auth.interceptor";
import { AuthService } from "./services/auth.service";
import { EnvService } from "./services/env.service";
import { EnvServiceProvider } from "./services/env.service.factory";
import { GtagService } from "./services/gtag.service";
import { AccountEffects } from "./state/account/account.effects";
import { accountReducer } from "./state/account/account.reducer";
import { CreditCardEffects } from "./state/credit-card/credit-card.effects";
import { creditCardReducer } from "./state/credit-card/credit-card.reducer";
import { CustomerEffects } from "./state/customer/customer.effects";
import { customerReducer } from "./state/customer/customer.reducer";
import { PaymentEffects } from "./state/payment/payment.effects";
import { paymentReducer } from "./state/payment/payment.reducer";
import { SubscriptionEffects } from "./state/subscription/subscription.effects";
import { subscriptionReducer } from "./state/subscription/subscription.reducer";
import { UserEffects } from "./state/user/user.effects";
import { UserReducer } from "./state/user/user.reducer";
import { StringFilterByPipe } from "./string-filter-by.pipe";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { getPortuguesePaginatorIntl } from "./pt-br-mat-paginator-intl";
import { productsImportReducer } from './state/products-import/products-import.reducer';
import { PlanFeatureGuard } from "./guards/planFeature.guard";



const appRoutes: Routes = [
    { 
        path: "", 
        redirectTo: "dashboard", 
        pathMatch: "full",
    },
    {
        path: "dashboard",
        canActivate: [SubscriptionGuard], 
        loadChildren: () =>
            import("./main/pages/dashboard/dashboard.module").then(
                (m) => m.DashboardModule
            ),
    },

    {
        path: "auth",
        loadChildren: () =>
            import("./main/pages/auth/auth.module").then((m) => m.AuthModule),
    },

    {
        path: "clientes",
        canActivate: [SubscriptionGuard], 
        loadChildren: () =>
            import("./main/pages/clients/clients.module").then(
                (m) => m.ClientsModule
            ),
    },
    
    {
        path: "produtos-e-servicos",
        canActivate: [SubscriptionGuard], 
        loadChildren: () =>
            import("./main/pages/products/products.module").then(
                (m) => m.ProductsModule
            ),
    },

    {
        path: "propostas",
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
            import("./main/pages/proposals/proposals.module").then(
                (m) => m.ProposalsModule
            ),
    },

    {
        path: "relatorios",
        canActivate: [SubscriptionGuard, PlanFeatureGuard],
        data: { featureSlug: 'relatorios' },
        loadChildren: () =>
            import("./main/pages/reports/reports.module").then(
                (m) => m.ReportsModule
            ),
    },


    
    {
        path: "users",
        canActivate: [SubscriptionGuard],
        loadChildren: () =>
            import("./main/pages/users/users.module").then(
                (m) => m.UsersModule
            ),
    },
    {
        path: "checkout",
        loadChildren: () =>
            import("./main/pages/checkout/checkout.module").then(
                (m) => m.CheckoutModule
            ),
    },

    {
        path: "planos-de-assinatura",
        loadChildren: () =>
            import("./main/pages/settings/subscription/subscription.module").then(
                (m) => m.SubscriptionModule
            ),
    },
    {
        path: "configuracao",
        loadChildren: () =>
            import("./main/pages/settings/settings.module").then(
                (m) => m.SettingsModule
            ),
    },
    {
        path: "faqs",
        loadChildren: () =>
            import("./main/pages/faqs/faqs.module").then((m) => m.FaqsModule),
    },
    {
        path: "erro",
        loadChildren: () =>
            import("./main/pages/errors/errors.module").then((m) => m.ErrorsModule),
    },
];

export const MY_FORMATS = {
    parse: {
        dateInput: "DD/MM/YYYY",
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MMM/YYYY",
        dateA11yLabel: "DD/MM/YYYY",
        monthYearA11yLabel: "MMM/YYYY",
    },
};

const reducers = [];
registerLocaleData(localePt);

export const checkForUpdates = (swUpdate: SwUpdate): (() => Promise<any>) => {
    return (): Promise<void> =>
      new Promise((resolve) => {
        swUpdate.checkForUpdate();
        
        swUpdate.available.subscribe(() => {
            window.location.reload();
        });
  
        resolve();
      });
  };

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        NgxMaskModule.forRoot(),
        StoreModule.forRoot({
            account: accountReducer,
            subscription: subscriptionReducer,
            creditCard: creditCardReducer,
            payment: paymentReducer,
            user: UserReducer,
            customer: customerReducer,
            productsImport: productsImportReducer
        }, {}),
        EffectsModule.forRoot([
            AccountEffects,
            SubscriptionEffects,
            CreditCardEffects,
            PaymentEffects,
            UserEffects,
            CustomerEffects,
        ]),

        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: !environment.production, // Restrict extension to log-only mode
            // autoPause: true, // Pauses recording actions and state changes when the extension window is not open
            // trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
            // traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
        }),


        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTooltipModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseConfirmDialogModule,
        FuseMaterialColorPickerModule,
        // App modules
        LayoutModule,
        ComponentsModule,
        GtagModule.forRoot({ trackingId: 'G-DV7KJG73LK', trackPageviews: true }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production || environment.staging,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerImmediately'
        })
    ],
    entryComponents: [ContatoFeedbackDialogComponent],
    providers: [
        { provide: APP_INITIALIZER, useFactory: checkForUpdates, multi: true, deps: [SwUpdate] },
        CurrencyPipe,
        StringFilterByPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        EnvServiceProvider,
        { provide: LOCALE_ID, useValue: "pt-BR" },
        { provide: MAT_DATE_LOCALE, useValue: "pt-BR" }, //you can change useValue
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
        { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() }
        // {
        //     // Use the 'fill' appearance on Angular Material form fields by default
        //     provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        //     useValue: {
        //         appearance: 'outline'
        //     }
        // }
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
    constructor(
        _authService: AuthService,
        envService: EnvService,
        private _gtag: GtagService,
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");
        if (token) {
            this._gtag.event('login', {
                method: 'Login via token',
                event_category: 'engagemnt',
                event_label: 'New user logged in via Token'
            });

            this._gtag.set({ 'user_id': `ACCOUNT_ID_${_authService.currentUser?.account?.id}` }); // Defina o ID de usuário usando o user_id conectado.
            localStorage.setItem("currentUserToken", token);
        }

        // _authService.autoLoginByToken();
    }
}
