    <!-- Notifications toggle -->
    <button class="user-button" mat-icon-button (click)="openPanel()" #notificationsOrigin>
        <mat-icon class="s-20" *ngIf="unreadCount > 0">notifications_active</mat-icon>
        <mat-icon class="s-20" *ngIf="unreadCount == 0">notifications</mat-icon>
        <span [ngClass]="{'has-notification': unreadCount > 0}"></span>
    </button>
    
    <!-- Notifications panel -->
    <ng-template #notificationsPanel>
        <div class="notification-box" @fadeInOut>
            <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
        
                <!-- Header -->
                <div class="header-notification" fxLayout="row" fxLayoutAlign="center center">
        
                    <div class="header-title-notification">Notificações</div>
                    <div class="ml-auto">
                        <span>
                            <button mat-icon-button *ngIf="unreadCount" [matTooltip]="'Marcar todas como lidas'" [disabled]="false"
                                (click)="markAllAsRead()">
                                <mat-icon class="icon-size-5 text-current" style="color: #4E5663;margin-right: 15px;">
                                    notifications_active
                                </mat-icon>
                            </button>
                        </span>
                        <span>
                            <button mat-icon-button *ngIf="!unreadCount" [matTooltip]="'Marcar todas como lidas'" [disabled]="true">
                                <mat-icon class="icon-size-5 text-current" style="color: #4E5663; margin-right: 15px;">notifications
                                </mat-icon>
                            </button>
                        </span>
                    </div>
                </div>
        
                <!-- Content -->
                <div class="content-notification">
                    <!-- Notifications -->
                    <ng-container *ngFor="let notification of notifications">
                        <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                            [ngClass]="{'unread': !notification.readAt}">
                            <!-- Notification without a link -->
                            <ng-container>
                                <div class="flex flex-auto py-5 pl-6">
                                    <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                                </div>
                            </ng-container>
        
                        </div>
        
                        <!-- Notification content template -->
                        <ng-template #notificationContent>
                            <div
                                [ngClass]="(!notification.readAt)?'background-noread-notification' : 'background-read-notification'"
                                fxLayoutAlign="center" fxFlex="100">
                                <div fxLayout="row" fxLayoutGap="5px" fxFlex="90" 
                                class="item-notification" 
                                [ngClass]="{'see-more-open': (notification.open||notifications?.length==1),'link-notification': notification.data.link}"
                                (click)="redirectTo(notification)">
                                    <div fxFlex="20" fxLayoutAlign="center">
                                        <!-- Icon -->
                                        <ng-container *ngIf="notification.data.icon && !notification.data.image">
                                            <div class="icon-container-notification {{notification?.data?.class_background}}">
                                                <mat-icon class="icon-notification  {{notification?.data?.class_text}}" >
                                                    {{notification?.data?.icon}}
                                                </mat-icon>
                                            </div>
                                        </ng-container>
                                        <!-- Image -->
                                        <ng-container *ngIf="!notification.data.icon"> 
                                            <ngx-avatar class="image-notification" [round]="true" size="40" [name]="notification.data.title" [src]="notification.data.image" ></ngx-avatar>
                                        </ng-container>
                                    </div>
                                    <!-- Title, description & time -->
                                    <div fxLayout="column" fxFlex="80" fxLayoutAlign="center">
                                        <ng-container *ngIf="notification.data.title">
                                            <div class="title-item-notification" [innerHTML]="notification.data.title"></div>
                                        </ng-container>
                                        <ng-container *ngIf="notification.data.description">
                                            <div style="position: relative !important;">
                                                <div class="container-description-item"
                                                [ngClass]="(notification.open||notifications?.length==1)? 'see-more-open' : ''"
                                                >
                                                    <div class="description-item-notification"
                                                        [innerHTML]="notification.data.description"
                                                        >
                                                    </div>
                                                    <div class="external-link" *ngIf="notification.data.external_url">
                                                        <a [href]="notification.data.external_url" target="_blank" class="btn btn-read">{{notification.data.external_url_label}}</a>
                                                    </div>
                                                </div>
    
                                                <span
                                                *ngIf="notification.data.description.length > 57 && notifications?.length>1"
                                                (click)="seeMore($event, notification)"
                                                class="see-more"
                                                
                                                [ngClass]="{
                                                    'see-more-open-span': notification.open||notifications?.length==1,
                                                    'background-noread-notification' : !notification.readAt,
                                                    'background-read-notification' : notification.readAt
                                                }"
                                                >
                                                    {{notification.open ? "Ler menos" : "Ler mais"}}
                                                </span>
                                                
                                            </div>
                                        </ng-container>
                                        <!-- <div class="mt-2 text-sm leading-none text-black">
                                            {{notification.data.time | date:'dd/MM/YY, hh:mm'}}
                                        </div> -->
                                        <div class="mt-2 text-sm leading-none text-black">
                                            {{notification.criadoEm}}
                                        </div>
    
                                    </div>
        
                                </div>
                                <!-- Actions -->
                                <div fxFlex="10" fxLayout="column" fxLayoutAlign="center start">
                                    <!-- Indicator -->
                                    
                                    <span>
                                        <button class="w-6 h-6 min-h-6 " (click)="toggleRead(notification)" mat-icon-button [matTooltip]="!notification.readAt ? 'Marca como lida' : 'Desmarcar como lida'">
                                            <span class="rounded-notification" [ngClass]="(!notification.readAt)?'rounded-read-notification' : ''"></span>
                                        </button>
                                    </span>
        
                                    <!-- Remove -->
                                    <span>
                                        <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100" mat-icon-button fxLayout="center"
                                            (click)="delete(notification)" [matTooltip]="'Excluir'">
                                            <mat-icon
                                                style="font-size: 18px !important;line-height: 1rem !important;font-weight: 400 !important;color: #3d3d3d !important">
                                                close</mat-icon>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
        
                    <!-- No notifications -->
                    <ng-container *ngIf="!notifications || !notifications.length">
                        <div class="no-has-notification">
                            <div
                                class="logo-notification flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                                <img class="logo-notification-img" src="/assets/svg/orca-32.svg" alt="">
                            </div>
                            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Você não possui nenhuma
                                notificação no momento.</div>
                        </div>
                    </ng-container>
        
                </div>
        
            </div>
        </div>
    
    </ng-template>