<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex.lt-md="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" mat-icon-button
                class="navbar-toggle-button" fxHide.gt-md>
                <mat-icon class="secondary-text text-white">menu</mat-icon>
            </button>


            <div *ngIf="horizontalNavbar" fxLayout="row" fxLayoutAlign="start center">
                <div class="logo ml-16">
                    <a [routerLink]="['/dashboard']">
                        <img class="logo-icon" src="assets/svg/orca_compact.svg">
                    </a>
                </div>
            </div>

        </div>

        <ul class="menutop" fxFlex fxHide.lt-md fxLayoutAlign="start center" fxLayoutGap="0px" style="font-size: 1rem;">
            <li>
                <a [routerLink]="['/dashboard']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">
                    <img class="icon-menu-sm mat-icon notranslate material-icons mat-icon-no-color"
                        src="/assets/svg/icons/space_dashboard_black_24dp.svg" alt=""> Dashboard
                </a>
            </li>
            <li>
                <a [routerLink]="['/propostas']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">
                    <img class="icon-menu-sm mat-icon notranslate material-icons mat-icon-no-color"
                        src="/assets/svg/icons/article_black_24dp.svg" alt=""> Orçamentos
                </a>
            </li>
            <li>
                <a [routerLink]="['/clientes']" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{exact:true}">
                    <img class="icon-menu-sm mat-icon notranslate material-icons mat-icon-no-color"
                        src="/assets/svg/icons/people_black_24dp.svg" alt=""> Clientes
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/produtos-e-servicos']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="new-feature-container">
                        <span class="new-feature">Beta</span>
                    </div>
                    <img class="icon-menu-sm mat-icon notranslate material-icons mat-icon-no-color"
                        src="/assets/svg/icons/handyman_black_24dp.svg" alt=""> Produtos e Serviços
                </a>
            </li>

            <li>
                <a [routerLink]="['/relatorios']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:false}">
                    <div class="new-feature-container">
                        <span class="new-feature">Beta</span>
                    </div>
                    <img class="icon-menu-sm mat-icon notranslate material-icons mat-icon-no-color"
                        src="/assets/svg/icons/insights_black_24dp.svg" alt=""> Relatórios
                </a>
            </li>

            <!-- <li *canShow="'settings'">
                <a [routerLink]="['/configuracao/proposta']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">
                    <mat-icon>color_lens</mat-icon> Aparência do Orçamento
                </a>
            </li> -->
        </ul>


        <!-- <a [routerLink]="['/relatorios']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}">
            <span class="new-feature">Novo</span>
            <img class="mat-icon notranslate ng-tns-c214-1 material-icons mat-icon-no-color"
                src="/assets/svg/icons/insights_black_24dp.svg" alt=""> Relatórios
        </a> -->

        <a *ngIf="subscriptionRestrictionService.isFreePlan() || subscriptionRestrictionService.hasCourtesy()" 
        class="btn-plans" 
            [routerLink]="['/checkout/planos']" 
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:false}">
            <img src="/assets/svg/icons/flag-plan.svg" alt="" class="mat-icon material-icons" >
            Planos
        </a>

        <notifications class=""></notifications>

        <span fxHide.lt-md class="">
            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

                <button #helpMenuButton="matMenuTrigger" [matMenuTriggerFor]="helpMenu" mat-button class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="s-20">help</mat-icon>
                    </div>
                </button>

                <mat-menu #helpMenu="matMenu" [overlapTrigger]="false">
                    <div id="settings-menu-toolbar">

                        <span>
                            <a [routerLink]="'/faqs'" mat-menu-item>
                                <mat-icon>help</mat-icon>
                                <span>
                                    Perguntas Frequentes
                                </span>
                            </a>
                        </span>
                        <span>
                            <a [href]="whatsappLink" target="_blank" mat-menu-item>
                                <mat-icon svgIcon="whatsapp"></mat-icon>
                                <span>
                                    Suporte via Whatsapp
                                </span>
                            </a>
                        </span>
                        <span>
                            <button (click)="openDialogFeedback()" mat-menu-item>
                                <mat-icon>assignment</mat-icon>
                                <span>Suporte via E-mail</span>
                            </button>
                        </span>
                        <span>
                            <a href="https://calendar.app.google/So8HtjH1FP4UAaeN6" target="_blank" mat-menu-item>
                                <mat-icon>support_agent</mat-icon>
                                <span>Agende uma Apresentação</span>
                            </a>
                        </span>
                    </div>
                </mat-menu>

                <button *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" mat-icon-button
                    class="navbar-toggle-button" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

            </div>
        </span>
        
        <span class="">
            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

                <button mat-button class="user-button" (click)="redirectTo(['/configuracao/perfil']);">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="s-20">settings</mat-icon>
                    </div>
                </button>

                <button *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" mat-icon-button
                    class="navbar-toggle-button" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

            </div>
        </span>

        <div class="mr-4 pl-8" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button [matMenuTriggerFor]="userMenu" mat-button class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <ngx-avatar [round]="true" [name]="userName" [src]="user?.image" class="avatar mr-0 mr-sm-16"
                        size="40"></ngx-avatar>
                    <span class="username mr-12" fxHide fxShow.gt-sm>
                        {{ userName }}
                    </span>
                    <mat-icon class="s-16" fxHide.xs>
                        keyboard_arrow_down
                    </mat-icon>
                </div>
            </button>

            <mat-menu class="profile-menu" #userMenu="matMenu" [overlapTrigger]="false">
                <div style="width: 100%; padding-left: 16px; padding-right: 16px; ">
                    <app-usage-counter></app-usage-counter>
                </div>
                <button [routerLink]="['/configuracao/perfil']" mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Meu Perfil</span>
                </button>

                <button *canShow="'settings'" [routerLink]="['/configuracao/proposta']" mat-menu-item>
                    <mat-icon>business</mat-icon>
                    <span>Dados da Empresa</span>
                </button>

                <button *canShow="'subscription'" [routerLink]="['/configuracao/assinatura']" mat-menu-item>
                    <mat-icon>credit_card</mat-icon>
                    <span>Plano e Cobrança</span>
                </button>

                <!-- <button [routerLink]="['/configuracao/conta']" mat-menu-item>
                    <mat-icon>admin_panel_settings</mat-icon>
                    <span>Dados Fiscais</span>
                </button> -->

                <button [routerLink]="['/configuracao/notificacao']" mat-menu-item>
                    <mat-icon>notifications</mat-icon>
                    <span>Notificações</span>
                </button>

                <button *canShow="'team'" [routerLink]="['/configuracao/time']" mat-menu-item>
                    <mat-icon>groups</mat-icon>
                    <span>Time</span>
                </button>
                
                <button 
                (click)="openReferralDialog()" mat-menu-item>
                    <mat-icon>share</mat-icon>
                    <span>Indique a Orca</span>
                </button>

                <button (click)="logout()" mat-menu-item>
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Sair</span>
                </button>

            </mat-menu>


            <button *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" mat-icon-button
                class="navbar-toggle-button" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>