import { createFeatureSelector, createSelector } from "@ngrx/store"
import { UserState } from "./user.reducer"

export const getUser = createFeatureSelector<UserState>('user')

export const selectUser = createSelector(
    getUser,
    (state) => state.user
)

export const selectUserError = createSelector(
    getUser,
    (state) => state.error
)