import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Product } from 'app/entities';
import { AlertService } from 'app/services/alert.service';
import { ProductsService } from 'app/main/pages/products/product.service';

@Component({
    selector: 'app-create-product-dialog',
    templateUrl: './create-product-dialog.component.html',
    styleUrls: ['./create-product-dialog.component.scss']
})
export class CreateProductDialogComponent implements OnInit {
    @ViewChild("clientName") clientName?: ElementRef;
    product: Product;
    mode: number;
    form?: FormGroup;
    title?: string;
    loading = false;

    cpfMask = "000.000.000-009";
    cnpjMask = "00.000.000/0000-00";
    cpfCnpjMask = this.cpfMask;

    checkMask(event) {
        this.switchMask(event.target.value);
    }

    switchMask(value) {
        try {
            value = value.replace(/[^0-9]/g, "");
        } catch (e) { }

        if (value.length <= 11) {
            this.cpfCnpjMask = this.cpfMask;
        } else {
            this.cpfCnpjMask = this.cnpjMask;
        }
    }

    constructor(
        public matDialogRef: MatDialogRef<CreateProductDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _formBuilder: FormBuilder,
        private _alertService: AlertService,
        private _productsService: ProductsService,
        private _matDialog: MatDialog
    ) {
        this.product = this._data.client;
        this.mode = this._data.mode ? this._data.mode : 0;
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.clientName?.nativeElement.focus();
        }, 300);
    }

    ngOnInit() {

        if (this.product.id) {
            this.title = "Editar Cliente";
        } else {
            this.title = "Adicionar Cliente";
        }

        this.form = this.createForm();
    }

    createForm(): FormGroup {
        return this._formBuilder.group({
            id: [this.product.id, []],
        });
    }

    onAvatarChanged(event: File) {
        if (!this.form) return;
        this.form.patchValue({
            image: event,
        });
    }

    updateUser() {
        if (!this.form) return;
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.loading = true;
            this._productsService
                .update(this.product.id, this.form.value)
                .subscribe(
                    (response) => {
                        this.loading = false;
                        this.matDialogRef.close(response);
                    },
                    (err) => {
                        this.loading = false;
                        this._alertService.error(err);
                    }
                );
        } else {
            this._alertService.error("Preencha corretamente o formulário.");
        }
    }

    addContact(name = "", email = "") {
        const creds = this.form.controls.contacts as FormArray;
        creds.push(
            this._formBuilder.group({
                name: [name],
                email: [email],
            })
        );
    }

    contacts() {
        return this.form.controls.contacts as FormArray;
    }

    insertUser() {
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.store();
            this.loading = true;
        } else {
            this._alertService.error("Preencha corretamente o formulário.");
        }
    }

    store() {
        this._productsService.store(this.form.value).subscribe(
            (response) => {
                this.loading = false;
                this.matDialogRef.close(response);
            },
            (err) => {
                this.loading = false;
                this._alertService.error(err);
            }
        );
    }

    deleteUser() { }
}
