import {
    Component,
    OnInit,
    Inject,
    ElementRef,
    ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ProposalService } from "app/main/pages/proposals/proposals.service";
import { HttpService } from "app/services/http.service";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { GtagService } from "app/services/gtag.service";
import { SubscriptionRestrictionService } from "app/services/subscription-restriction.service";

@Component({
    selector: "app-preview-proposal-dialog",
    templateUrl: "./preview-proposal-dialog.component.html",
    styleUrls: ["./preview-proposal-dialog.component.scss"],
})
export class PreviewProposalDialogComponent implements OnInit {
    @ViewChild("contentIframe") contentIframe?: ElementRef;

    content;
    data;
    loading = false;

    show_customize_instructions = true;

    env = environment

    constructor(
        protected httpService: HttpService,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _proposalsService: ProposalService,
        public matDialogRef: MatDialogRef<PreviewProposalDialogComponent>,
        private _gtag: GtagService,
        private _subscriptionRestrictionService: SubscriptionRestrictionService
    ) {
        this.data = _data;
        if (this.data.hideTip) {
            this.show_customize_instructions = false;
        }
    }

    redirectTo(navigation) {
        this.matDialogRef.close();
        this._router.navigate(navigation);
    }

    isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
        input !== null && input.tagName === "IFRAME";

    print() {
        let frame = document.getElementById("proposal-frame");
        if (this.isIFrame(frame) && frame.contentWindow) {
            frame.contentWindow.print();
        }
    }

    openPDF() {
        var featureLimited = this._subscriptionRestrictionService.checkFeatureLimitAndShowDialog("pdf-propostas")
        if (!featureLimited) {
            var url = this.env.baseUrlHotsite + '/proposta/' + this.data.token + '.pdf'
            window.open(url, '_blank')
        }
    }

    ngOnInit() {
        this.loading = true;
        this.httpService
            .post("/v1/clients/preview-proposal", this.data)
            .subscribe((data) => {
                this.content = data.data.content;
                var doc =
                    this.contentIframe?.nativeElement?.contentWindow?.document;
                doc.open();
                doc.write(this.content);
                doc.close();
                this.loading = false;
            });
    }


}
