import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { DialogComponent } from "./dialog.component";
import { MatDialogModule } from "@angular/material/dialog";


@NgModule({
    declarations: [
        DialogComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatDialogModule,

        FuseSharedModule
    ],
    exports     : [
        DialogComponent
    ],
    entryComponents: [
        DialogComponent
    ],
})
export class DialogModule
{
}
