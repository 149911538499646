import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnvService {

    public appName;
    public appVersion;

    public apiHost;
    public baseUrl;
    public apiUrl;
    public storageUrl;

    public devMode = false;
  
    constructor() {
    }

    onInit() {
        if (this.devMode) {
            window.document.title = `${this.appName} - ${this.appVersion}`
            window.document.body.classList.add('theme-dev')
        }else{
            window.document.title = this.appName
        } 
    }
  }