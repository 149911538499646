<div class="dialog-content-wrapper" >
    <div class="dialog-header">
        <div (click)="dialogRef.close()" class="close-btn" aria-label="Fechar">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div mat-dialog-content class="pb-0 m-0" style="padding: 0px 16px 16px 16px">
        <span class="title dialog-title">Nos ajude a levar a Orca para mais negócios!</span>
        <span class="dialog-subtitle mt-16">Que tal indicar a Orca para alguém que precise?</span>
        <p class="dialog-paragraph m-0">
            Basta copiar o link abaixo e enviar para seus contatos. Ao se cadastrarem iremos registrar a sua indicação para recompensa-lo futuramente.
        </p>

        <div class="mt-16 link-container">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                    <ngx-avatar  [src]="imgSrc" [round]="true" [initialsSize]="2" size="40"
                    [name]="'teste Cliente'"></ngx-avatar>
                <div  fxLayout="column" fxLayoutAlign="center start" style="gap: 2px">
                    <span class="dialog-subtitle p-0">Indique a Orca!</span>
                    <div  (click)="copyToClipboard()">
                        <div style="cursor: pointer; 
                        padding: 4px 8px;
                        background: #fff;
                        border-radius: 5px;
                        color: #5BA5FE;"
                        fxLayout="row" fxLayoutAlign="space-between center"
                        matTooltip="Clique para copiar o link de indicação">
                            <div *ngIf="!copied">{{referralLink}}</div>
                            <div *ngIf="copied">Link Copiado!</div>
                            <mat-icon style="color: #5BA5FE; position: relative; top: 2px">content_copy</mat-icon>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <!-- <button class="btn btn-red" aria-label="Fechar" 
        (click)="dialogRef.close()">
            Fechar
        </button> -->
    </div>
</div>