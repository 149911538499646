<div [formGroup]="form" *ngIf="form"
class="form-responsive"
(keydown.tab)="$event.stopPropagation()"
(click)="$event.stopPropagation()">
    <div class="form-group">
        <div fxLayout="column">
            <mat-form-field appearance="outline" fxFlex class="min-width">
                <mat-label>titulo</mat-label>
                <input
                type="text" #nomeInput placeholder="titulo"
                matInput formControlName="title" required>
            </mat-form-field>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="start center" class="w-100">
            <orca-switch [isActive]="productCategory?.status == 1"
            (toggle)="toggleStatus($event)"></orca-switch>
        </div> -->
    </div>


    <div class="form-menu-footer mt-16" fxLayout="row" fxLayoutAlign="end">
        <button *ngIf="!productCategory?.id" class="btn btn-gray" (click)="close()">Descartar</button>
        <button *ngIf="!productCategory?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            (click)="store()">Criar Categoria</button>
        <button *ngIf="productCategory?.id" class="btn btn-blue" [disabled]="loading|| !form.dirty || !this.form.valid"
            fxLayout="row" fxLayoutAlign="start center" (click)="update()">
            <mat-spinner *ngIf="loading" class="spinner" diameter="20" color="accent"></mat-spinner>
            Salvar Alterações
        </button>
    </div>
</div>