import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export abstract class UnsubscribeComponent {

  protected _unsubscribeAll: Subject<any>;

  constructor() {
    this._unsubscribeAll = new Subject();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}