<div class="fuse-card auto-width" fxLayout="column" style="min-height: 330px;min-width: 330px; width:100%;height:100%;min-width: 100px; padding-bottom: 30px;">

    <div class="card-header px-12 pt-12" fxLayout="row" fxLayoutAlign="start center" >
        <div fxFlex="60">
            <div class="dashboard-card-title">Total de Orçamentos</div>
        </div>
        <mat-form-field appearance="outline" class="no-margin slim" fxFlex="40">
            <mat-select [(ngModel)]="modelDias" (selectionChange)="changeDays()">
                <mat-option *ngFor="let mes of meses" [value]="mes.value">
                    {{mes.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="chart-container">

        <div class="chart-header">

            <div class="chart-currency">
                <span>R$</span><strong>{{valueFormatting(total)}}</strong>
            </div>

            <div class="chart-description">
                Em orçamentos criados
            </div>

        </div>

        <div class="chart"> 
            <canvas id="canvas"></canvas>
        </div>
    </div>
</div>