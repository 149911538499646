import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsImportService } from 'app/main/pages/products/product-import.service';
import { ProductImportDataSource } from 'app/main/pages/products/product-imports.datasource';
import { ProductsService } from 'app/main/pages/products/product.service';
import { AlertService } from 'app/services/alert.service';
import { MatSort } from '@angular/material/sort';
import { LocalStorageService } from 'app/services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as ProductsImportActions from '../../../state/products-import/products-import.actions';
import * as fromProductsImport from '../../../state/products-import/products-import.selectors';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from 'app/main/pages/unsubscribe.component';

@Component({
  selector: 'app-multi-step-component',
  templateUrl: './multi-step-component.component.html',
  styleUrls: ['./multi-step-component.component.scss']
})
export class MultiStepComponentComponent extends UnsubscribeComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  currentStep$: Observable<number>;
  steps = [
    'Selecione o Arquivo', 
    'Seleção de Colunas', 
    'Processando Importanção', 
    'Resultado da Importação'
  ];

  fileColumns: string[] = [];
  fileData: any[] = [];
  fileKey!: string
  codigo: any[] = [];
  nome: any[] = [];
  valor: any[] = [];
  descricao: any[] = [];
  categoria: any[] = [];
  unidade: any[] = [];
  columnsMatchForm!: FormGroup;
  loading = false;
  disabled = true;
  showDetails = false;
  processedData: any[] = [];
  displayedColumns = ['status', 'row', 'codigo', 'produto', 'valor', 'mensagem'];
  createdCount = 0;
  updatedCount = 0;
  errorCount = 0;
  reset = false;
  errorMessages: any = []
  currentFileState = 'idle'
  currentFileLabel: string | null = null
  storageKeyLoading = true
  isStep2 = false
  dataSource?: ProductImportDataSource
  isImportLoading$: Observable<boolean>

  constructor(
    private _productsService: ProductsService,
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private localStorageService:LocalStorageService
  ) { 
    super()
    this.currentStep$ = this.store.select(fromProductsImport.selectCurrentStep);
    this.isImportLoading$ = this.store.select(fromProductsImport.selectIsImportLoading);
  }

  ngOnInit(): void {
    this.columnsMatchForm = this._formBuilder.group({
      codigo: [null, []],
      nome: [null, [Validators.required]],
      valor: [null, []],
      descricao: [null, []],
      categoria: [null, []],
      unidade: [null, []],
      key: [null, []],
    });

    this.isImportLoading$.pipe(takeUntil(this._unsubscribeAll))
    .subscribe(isImportLoading => {
      if (isImportLoading) {
        this.loading = true;
      }
    });
    
    this.currentStep$.pipe(takeUntil(this._unsubscribeAll))
    .subscribe(step => {
      if (step === 2) this.isStep2 = true
      else this.isStep2 = false
      
      if (step === 3 && !this.loading) {
        this.finishImport();
      }

      if (step === 4) {
        this.store.dispatch(ProductsImportActions.setIsImportLoading({ isImportLoading: false }));
        this.handleUpdateScreen();
      }
    });
  }

  handleUpdateScreen() {
    let key = this.route.snapshot.queryParamMap.get('products.import-key') || this.localStorageService.getItem('products.import-key');
   
    if (key) {
        const state = this.localStorageService.getItem(key);
        if (state) {
            this.createdCount = state.createdCount || 0;
            this.updatedCount = state.updatedCount || 0;
            this.errorCount = state.errorCount || 0;
            this.errorMessages = state.errorMessages || [];
            this.currentFileLabel = state.message || null;

            this.storageKeyLoading = false;
            this.localStorageService.removeItem('products.import-key');
            this.localStorageService.removeItem(key);
        }
    } else {
      this.store.dispatch(ProductsImportActions.setStep({ step: 1 }));
    }

  }

  verifyStorage() {
    let interval = setInterval(() => {
      this.handleUpdateScreen()
      if (!this.storageKeyLoading) {
        clearInterval(interval)
      }
    })
  }

  next() {
    if (this.isStep2 && this.columnsMatchForm.invalid) {
        // this.store.dispatch(ProductsImportActions.decrementStep());
        this._alertService.error('O nome do produto é obrigatório!');
        this.loading = false;
        return;
    }

    this.store.dispatch(ProductsImportActions.incrementStep());
  }
  
  prev() {
    this.store.dispatch(ProductsImportActions.decrementStep());
  }

  finishImport() {
    this.loading = true;
    this.store.dispatch(ProductsImportActions.setIsImportLoading({ isImportLoading: true }));
    this.localStorageService.setItem(this.fileKey, "carregando")
    const storageKey = this.columnsMatchForm.get('products.import-key')?.value;
    this.localStorageService.setItem(storageKey, 'loading');
    this._productsService.import(this.columnsMatchForm.value)
    .subscribe(
      res => {
      }, error => {
        this.loading = false;
        this.store.dispatch(ProductsImportActions.setIsImportLoading({ isImportLoading: false }));
        this._alertService.error('Erro ao importar produtos!');
      }
    );
  }

  onFileSelected(file: File): void {
    this.uploadFile(file);
  }

  uploadFile(file: File): void {
    const formData = new FormData();
    formData.append('file', file);
    if (file.size > 10000000) {
      this.currentFileLabel = 'Arquivo muito grande! O tamanho máximo permitido é de 10Mb.'
      this.currentFileState = 'error'
      return;
    }
    this.loading = true;
    this.currentFileState = 'loading'
    this.currentFileLabel = null
    this._productsService.processImporData(formData).subscribe(
      res => {
        this.fileColumns = res.data.columns;
        this.fileData = res.data.rows;
        this.fileKey = res.meta.key;
        this.columnsMatchForm.patchValue({
          key: this.fileKey
        });
        this.disabled = false;
        this.loading = false;
        this.currentFileState = 'success'
        this.currentFileLabel = null
        this.localStorageService.setItem('products.import-key', this.fileKey);
      },
      error => {
        console.error(error);
        this.reset = !this.reset;
        let message = 'Erro ao processar arquivo!';
        if (error?.details) {
          message = `${message} ${error.details}`;
        } else if(error?.error) {
          message = `${message} ${error?.error}`;
        }
        this.loading = false;
        this.currentFileState = 'error'
        this.currentFileLabel = message
      }
    );
  }

  updateColumnData(column: string, columnName: string): void {
    const columnIndex = this.fileColumns.indexOf(columnName);
    const columnData = this.fileData.map(row => row[columnIndex]);
    this[column] = columnData;
  }

  reloadProductImports(params: any) {
    params.filter = {key: this.fileKey}
  }

  navigateToProducts() {
    this._router.navigate(['/produtos-e-servicos/produtos']);
  }
}
