import { createAction, props } from "@ngrx/store";
import { PlanSubscription } from "app/entities/plan-subscription";
import { Error } from "../AppState";
import { FeatureUsage } from "app/entities";

export enum SubscriptionActionTypes {
    loadSubscription = '[Subscription] Load Subscription',
    loadSubscriptionSuccess = '[Subscription] Load Subscription Success',
    loadSubscriptionFailure = '[Subscription] Load Subscription Failure',
    cancelSubscriptionSuccess = '[Subscription] Cancel Subscription Success',
    cancelSubscriptionFailure = '[Subscription] Cancel Subscription Failure',
    loadSubscriptionUsages = '[Subscription] Load Subscription Usages',
    loadSubscriptionUsagesSuccess = '[Subscription] Load Subscription Usages Success',
}

export const loadSubscription = createAction(SubscriptionActionTypes.loadSubscription);

export const loadSubscriptionSuccess = createAction(
    SubscriptionActionTypes.loadSubscriptionSuccess,
    props<{subscription: PlanSubscription | null}>()
);

export const loadSubscriptionUsages = createAction(SubscriptionActionTypes.loadSubscriptionUsages);
export const loadSubscriptionUsagesSuccess = createAction(
    SubscriptionActionTypes.loadSubscriptionUsagesSuccess,
    props<{usages: FeatureUsage[]}>()
);
export const loadSubscriptionFailure = createAction(
    SubscriptionActionTypes.loadSubscriptionFailure,
    props<{ error: Error }>()
);

export const cancelSubscriptionSuccess = createAction(
    SubscriptionActionTypes.cancelSubscriptionSuccess,
    props<{subscription: PlanSubscription | null}>()
);
export const cancelSubscriptionFailure = createAction(
    SubscriptionActionTypes.loadSubscriptionFailure,
    props<{ error: Error }>()
);