import { Decodable } from "./_decodable";

class LayoutProposal extends Decodable {
    id!: number;
    name!: string;
    file!: string;
    isPro!: boolean;
    hasLogo!: boolean;
    hasHeaderImage!: boolean;
    hasHeaderLogo!: boolean;
    hasFooterImage!: boolean;
    hasFooterLogo!: boolean;
    hasFundoDestaque!: boolean;
    hasTextoDestaque!: boolean;
    hasFundoHeader!: boolean;
    hasTextoHeader!: boolean;
    colorDefaultFundoDestaque!: string|null;
    colorDefaultTextoDestaque!: string|null;
    colorDefaultFundoHeader!: string|null;
    colorDefaultTextoHeader!: string|null;
    status!: number;
   
}

class AccountLayoutProposal extends LayoutProposal {
    accountId?: number;
    layoutProposalId?: number;
    accountLayoutProposalId?: number;
    headerLogo?: string;
    headerImage?: string;
    footerImage?: string;
    fundoDestaque?: string;
    textoDestaque?: string;
    fundoHeader?: string;
    textoHeader?: string;
    generalConditions?: string;
  
}

export { LayoutProposal, AccountLayoutProposal };
