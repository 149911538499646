import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { Notification } from 'app/entities';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { loadNotifications } from 'app/state/account/account.actions';
import { selectNotifications } from 'app/state/account/account.selectors';
import { takeUntil } from 'rxjs/operators';



@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    animations   : fuseAnimations
})
export class NotificationsComponent implements OnInit {
    @Input() notifications: Notification[] = [];
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private store:Store,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _router: Router,
    ) {
    }

    /**
     * On init
     */

    notificationFetchInterval = 5

    ngOnInit(): void {
        this.store.dispatch(loadNotifications());

        this.store.select(selectNotifications)
        .pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((notifications)=>{
            this.processNotifications(notifications)
        })
    }

    seeMore(event, notification: Notification){
        event.stopPropagation();

        notification.open = !notification.open

        // this.notifications = this.notifications.map((notif)=> {
        //     if(notif.id == notification.id){
        //         notification.open = !notification.open
        //         return notification;
        //     }

        //     return notif;
        // })


    }

    processNotifications(notifications:Notification[]) {
        this.notifications = notifications.map(n=>{
            return {...n} as Notification
        });

        // Calculate the unread count
        this._calculateUnreadCount();

        // Mark for check
        this._changeDetectorRef.markForCheck();
    }

    refreshNotifications(){
        this.store.dispatch(loadNotifications())
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay

        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));


    }

    /**
     * Close the messages panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.readAll(true).subscribe(() => {
            this.refreshNotifications()
        });
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification, read = true): void {
        let readAt = false
        if (read) {
            readAt = !notification.readAt;
        } else {
            readAt = notification.readAt;
        }

        // Update the notification
        this._notificationsService.read(notification.id, readAt).subscribe(() => {
            this.refreshNotifications()
        });
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe(() => {
            this.refreshNotifications()
        });
    }

    redirectTo(notification) {
        this.toggleRead(notification, false)
        if (notification.data.useRouter) {
            this._router.navigate([`${notification.data.useRouter}`]);
            return;
        }

        if (notification.data.link) {
            window.location.href = notification.data.link
            return;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(notification => !notification.readAt).length;
        }

        this.unreadCount = count;
    }
}
