<div class="orca-dialog">
    <div class="dialog-header">
        <h1 class="dialog-title">{{confirmTitle||'Confirmar'}}</h1>
    </div>

    <div class="dialog-content">
        <h4>{{confirmMessage}}</h4>
    </div>

    <div class="dialog-footer dialog-actions">
        <button class="btn btn-gray" (click)="dialogRef.close(false)" aria-label="Cancelar">
            Cancelar
        </button>

        <button class="btn btn-blue" (click)="dialogRef.close(true)" aria-label="Confirmar" matTooltip="Confirmar">
            Confirmar
        </button>
        
        
    </div>
</div>