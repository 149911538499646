import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Survey } from 'app/entities/nps-survey';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NpsService {

  constructor(private httpService: HttpService) { }

    getElegibleSurvey(): Observable<Survey> {
        return this.httpService.get('/v1/clients/nps/elegible-survey').pipe(
            map(res => Survey.parse(res?.data) as Survey)
        );
    }

    submitSurvey(surveyID, surveyData):Observable<any>{
        return this.httpService.post('/v1/clients/nps/'+surveyID, surveyData);
    }
}
