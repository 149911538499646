import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AccountState } from "./account.reducer";


export const selectAccountState = createFeatureSelector<AccountState>('account')

export const selectAccount = createSelector(
    selectAccountState,
    (state) => state.account
)

export const selectTags = createSelector(
    selectAccountState,
    (state) => state.tags
)

export const selectNotifications = createSelector(
    selectAccountState,
    (state) => state.notifications
)

export const selectSettingsFirstSteps = createSelector(
    selectAccountState,
    (state) => state.settingsFirstSteps
)

export const selectProposalFirstSteps = createSelector(
    selectAccountState,
    (state) => state.proposalFirstSteps
)

export const selectAccountError = createSelector(
    selectAccountState,
    (state) => state.error
)