import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit {

  public confirmMessage: string = "Para confirmar a realização dessa ação, digite sua senha de administrador.";

  title
  message
  yesText = "SIM"
  noText = "NÃO"
  showYes = true
  showNo = true

  
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<ConfirmActionDialogComponent>
  ) {
    // apply no padding panel class
    this.matDialogRef.addPanelClass('no-padding-dialog-container'); 
    this.title = _data.title
    this.message = _data.message
    this.yesText = _data.yesText || this.yesText
    this.noText = _data.noText || this.noText
    this.showYes = !(_data.showYes === false)
    this.showNo = !(_data.showNo === false)
  }

  ngOnInit() {
  }

  confirm() {
      this.matDialogRef.close(true)
  }

  cancel(){
    this.matDialogRef.close(false)
  }

}
