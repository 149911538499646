import { Injectable } from '@angular/core';
import { ApiResponse } from 'app/entities';
import { CreditCard } from 'app/entities/creditcard';
import { ApiService } from 'app/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService extends ApiService {

  save(params: any = {}) {
    return this.httpService.post(`/v1/clients/credit-card`, params)
    .pipe(
        map(res => ApiResponse.parseWith(new CreditCard, res) as ApiResponse<CreditCard>)
    )
  }
}
