import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'app/services/auth.service';
import { Store } from '@ngrx/store';
import { selectSubscription } from 'app/state/subscription/subscription.selectors';
import { SingletonsService } from 'app/services/singletons.service';
import { SubscriptionRestrictionService } from 'app/services/subscription-restriction.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store,
    private SubscriptionRestrictionService: SubscriptionRestrictionService,
  ) { }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
        if (this.SubscriptionRestrictionService.checkSubscriptionHasBlockingRestriction(true)){ 
            this.router.navigate(['/configuracao/assinatura'])
            return false
        }
        return true
       
  }
}
