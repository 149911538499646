import { Injectable } from '@angular/core';
import { Notification } from 'app/entities';
import { ApiService } from 'app/services/api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends ApiService {

    findAll(){
        return this.httpService.get(`/v1/clients/notifications`, {}).pipe(
            map(notification => Notification.parse(notification.data))
        )
    }
   
    read(id: number, read:boolean){
        return this.httpService.post(`/v1/clients/notifications/${id}/read`, {read})
    }

    readAll(read:boolean){
        return this.httpService.post(`/v1/clients/notifications/read/all`, read)
    }

    delete(id){
        return this.httpService.post(`/v1/clients/notifications/${id}/delete`, {})
    }
    
}
