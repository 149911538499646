import { NgModule } from '@angular/core';
import { MaskPipe } from './mask.pipe';
import { ReplacePipe } from './replace.pipe';
​
​
@NgModule({
    declarations: [
        MaskPipe,
        ReplacePipe
    ],
    imports     : [],
    exports     : [
        MaskPipe,
        ReplacePipe
    ]
})
export class PipesModule
{
}