import { Injectable } from '@angular/core';
import { NotificationsService } from '@fuse/components/notifications/notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from 'app/services/auth.service';
import { AccountService } from 'app/services/http/account.service';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { loadCreditCardSuccess } from '../credit-card/credit-card.actions';
import { loadSubscriptionSuccess } from '../subscription/subscription.actions';
import { loadAccount, loadAccountFailure, loadAccountSuccess, loadNotifications, loadNotificationsSuccess, loadProposalFirstSteps, loadProposalFirstStepsSuccess, loadSettingsFirstSteps, loadSettingsFirstStepsSuccess, loadTags, loadTagsSuccess } from './account.actions';
import { TagsService } from 'app/services/http/tags.service';

@Injectable()
export class AccountEffects {

    loadAccount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAccount),
            switchMap(() =>
                this.accountService.load().pipe(
                    concatMap((account) => [
                        loadAccountSuccess({ account })
                    ]),
                    catchError((error) => of(loadAccountFailure({ error }))))
            )
        )
    )

    loadSettingsFirstSteps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSettingsFirstSteps),
            exhaustMap(() =>
                this.accountService.getStepsForGroup('settings').pipe(
                    map((steps) => loadSettingsFirstStepsSuccess({ steps }))
                )
            )
        )
    )

    loadProposalFirstSteps$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadProposalFirstSteps),
            exhaustMap(() =>
                this.accountService.getStepsForGroup('proposals').pipe(
                    map((steps) => loadProposalFirstStepsSuccess({ steps }))
                )
            )
        )
    )

    loadAccountSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAccountSuccess),
            concatMap(({ account }) => [
                loadCreditCardSuccess({ creditCard: account.creditCard }),
                loadSubscriptionSuccess({ subscription: account.subscription }),
                loadTagsSuccess({ tags: account.tags ?? [] }),
            ])
        )
    )

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNotifications),
            exhaustMap(() => this.notificationsService.findAll().pipe(
                map((notifications) => loadNotificationsSuccess({ notifications: notifications }))
            )
            )
        )
    )

    loadTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTags),
            exhaustMap(() => this.tagsService.findAll().pipe(
                map((tags) => loadTagsSuccess({ tags: tags }))
            )
            )
        )
    )



    constructor(
        private actions$: Actions,
        private accountService: AccountService,
        private notificationsService: NotificationsService,
        private tagsService: TagsService,
        private authService: AuthService
    ) { }


}