import { PlanFeature } from "./plan-feature";
import { PlanPayment } from "./plan-payment";
import { Decodable } from "./_decodable";

class Plan extends Decodable {
    id: number;
    name: string;
    slug: string;
    description: string;
    isActive: boolean;
    differential: any
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    features: PlanFeature[]
    planPayments: PlanPayment[]

    minimumInstallmentPricePerMonth?: number
    mostExpensiveOldPrice?: number
    protected _map = {
        features: e => PlanFeature.parse(e),
        planPayments: e => PlanPayment.parse(e),
        createdAt: e => this.parseDate(e),
        updatedAt: e => this.parseDate(e),
        deletedAt: e => this.parseDate(e),
    }
}

export { Plan };
